import React from 'react';

import noTopicsImg from '../../images/no-data-error.svg';

import './style.scss';

const NoTopicsError = () => {
    return (
        <div className='no-data-error' data-testid='notopics-error'>
            <img src={noTopicsImg} alt='notopics_error' />
            <div className='no-data-error-line1'>
                Currently, Topic Analysis is not supported
                for the selected course.
            </div>
            <div className='no-data-error-line2'>
                Please select another course
            </div>
        </div>
    );
};

export default NoTopicsError;
