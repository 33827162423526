import React from 'react';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash/cloneDeep';
import Handle from './handle';
import Track from './track';
import useTouches from './use-touches';

function step(min, max, x) {
    return Math.max(0, Math.min((x - min) / (max - min), 1));
}
export default class MultiSlider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            down: null,
        };
    }

    calculateValues = (event, down) => {
        const x = this.xForEvent(event);
        const valuePos = this.reverseX(x);
        let { values } = this.props;
        const leftIndex = down.controlled - 1;
        const rightIndex = down.controlled;
        const leftValue = values[leftIndex];
        const rightValue = values[rightIndex];
        const w = leftValue + rightValue;
        let offsetLeft = 0;
        for (let i = 0; i < leftIndex; ++i) {
            offsetLeft += values[i];
        }
        const left = Math.round(w * step(offsetLeft, offsetLeft + w, valuePos));
        const right = w - left;
        if (left !== leftValue && right !== rightValue) {
            values = [].concat(values);
            values[leftIndex] = left;
            values[rightIndex] = right;
        }
        return values;
    };

    concernedEvent = (e) => {
        const { down } = this.state;
        // istanbul ignore else
        if (!useTouches()) {
            return e;
        }
        if (!down) {
            return e.targetTouches[0];
        }
        const { touchId } = down;
        const touches = e.changedTouches || [];
        for (const value of touches) {
            if (value.identifier === touchId) {
                return value;
            }
        }

        return null;
    };

    onHandleStart = (i, e) => {
        const event = this.concernedEvent(e);
        if (!event) {
            return;
        }
        e.preventDefault();
        this.setState({
            down: {
                touchId: event.identifier,
                x: this.xForEvent(event),
                controlled: i,
            },
        });
    };

    onHandleMove = (e) => {
        const event = this.concernedEvent(e);
        if (!event) {
            return;
        }
        e.preventDefault();
        const x = this.xForEvent(event);
        const valuePos = this.reverseX(x);
        const { down } = this.state;
        let { values } = this.props;
        const leftIndex = down.controlled - 1;
        const rightIndex = down.controlled;
        const leftValue = values[leftIndex];
        const rightValue = values[rightIndex];
        const w = leftValue + rightValue;
        let offsetLeft = 0;
        for (let i = 0; i < leftIndex; ++i) {
            offsetLeft += values[i];
        }
        const left = Math.round(w * step(offsetLeft, offsetLeft + w, valuePos));
        const right = w - left;

        if (left !== leftValue && right !== rightValue) {
            values = [].concat(values);
            values[leftIndex] = left;
            values[rightIndex] = right;
            this.props.onChange && this.props.onChange(values);
        }
    };

    defaultMinValue = (e, key, values, sliderValues) => {
        const _values = _cloneDeep(values);
        // istanbul ignore else
        if (e.key === 'ArrowLeft' && _values[key - 1] > 0) {
            _values[key - 1] = sliderValues[key - 1] - 1;
        } else if (e.key === 'ArrowRight') {
            _values[key - 1] = sliderValues[key - 1] + 1;
        }
        _values[key] = 100 - sliderValues[key - 1];
        return _values;
    }

    defaultMaxValue = (e, key, values, sliderValues) => {
        const _values = _cloneDeep(values);
        // istanbul ignore else
        if (e.key === 'ArrowLeft') {
            _values[key - 1] = sliderValues[key - 1] - 1;
        } else if (e.key === 'ArrowRight' && _values[key - 1] < 100) {
            _values[key - 1] = sliderValues[key - 1] + 1;
        }
        return _values;
    }

    defaultMinMaxValue = (e, key, values, sliderValues) => {
        const _values = _cloneDeep(values);
        // istanbul ignore else
        if (key === 1) {
            // istanbul ignore else
            if (e.key === 'ArrowLeft' && _values[key - 1] > 0) {
                _values[key - 1] = sliderValues[key - 1] - 1;
            } else if (e.key === 'ArrowRight'
                && (_values[key - 1] < (_values[key - 1] + _values[key]))) {
                _values[key - 1] = sliderValues[key - 1] + 1;
            }
        } else if (key === 2) {
            // istanbul ignore else
            if (e.key === 'ArrowLeft' && _values[key] < (_values[key - 1] + _values[key])) {
                _values[key] = sliderValues[key] + 1;
            } else if (e.key === 'ArrowRight'
                && (_values[key - 1] < 100)) {
                _values[key] = sliderValues[key] - 1;
            }
        }
        return _values;
    }

    handleKeyDown = (e, key) => {
        // istanbul ignore else
        if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
            let values = this.props;
            const { setDefaultMinValue, setDefaultMaxValue } = this.props;
            const sliderValues = values;
            // istanbul ignore else
            if (setDefaultMinValue) {
                // istanbul ignore else
                values = this.defaultMinValue(e, key, values, sliderValues);
            } else if (setDefaultMaxValue) {
                // istanbul ignore else
                values = this.defaultMaxValue(e, key, values, sliderValues);
            } else if (!setDefaultMaxValue && !setDefaultMinValue) {
                // istanbul ignore else
                values = this.defaultMinMaxValue(e, key, values, sliderValues);
            }
            this.props.onChange(values);
        }
    }

    onHandleAbort = (e) => {
        const event = this.concernedEvent(e);
        if (!event) {
            return;
        }
        this.setState({ down: null });
    };

    onTrackClick = (i, e) => {
        const event = this.concernedEvent(e);
        if (!event) {
            return;
        }
        e.preventDefault();
        let values = null;
        let down = {
            touchId: event.identifier,
            x: this.xForEvent(event),
            controlled: i,
        };
        if (i === 1) {
            const valueSet1 = this.calculateValues(event, down);
            const down1 = {
                touchId: event.identifier,
                x: this.xForEvent(event),
                controlled: i + 1,
            };
            const valueSet2 = this.calculateValues(event, down1);
            const x = this.props.values[0];
            const x1 = valueSet1[0];
            const y = 100 - this.props.values[2];
            const y2 = 100 - valueSet2[2];
            const dif1 = x1 - x;
            const dif2 = y - y2;
            if (dif1 <= dif2) {
                values = valueSet1;
            } else if (this.props.setDefaultMaxValue) {
                values = valueSet1;
            } else {
                values = valueSet2;
                down = down1;
            }
        } else {
            values = this.calculateValues(event, down);
        }
        this.props.onChange && this.props.onChange(values);
        this.setState({ down });
    };

    setIndexHandleEvents = (i, down, index, touchEvents, handleEvents) => {
        let _handleEvents = _cloneDeep(handleEvents);
        let _index = _cloneDeep(index);
        if (this.props.setDefaultMaxValue) {
            _index = 1;
        }
        if (this.props.setDefaultMinValue) {
            _index = 2;
        }
        if (touchEvents) {
            const returnedHandleEvents = this.setIndexEvents(i, down, _handleEvents, _index);
            _handleEvents = returnedHandleEvents;
        } else {
            _handleEvents.onMouseDown = this.onTrackClick.bind(
                null,
                _index === 0 ? 1 : _index
            );
        }
        return [_index, _handleEvents];
    }

    setIndexEvents = (i, down, handleEvents, index) => {
        const _handleEvents = _cloneDeep(handleEvents);
        // istanbul ignore else
        if (!down) {
            _handleEvents.onTouchStart = this.onTrackClick.bind(
                null,
                index === 0 ? 1 : index
            );
        } else if (down.controlled === i) {
            _handleEvents.onTouchMove = this.onTrackMove;
            _handleEvents.onTouchEnd = this.onHandleAbort;
            _handleEvents.onTouchCancel = this.onHandleAbort;
        }
        return _handleEvents;
    }

    setHandleEvents = (touchEvents, down, i, handleEvents) => {
        const _handleEvents = _cloneDeep(handleEvents);
        if (touchEvents) {
            if (!down) {
                _handleEvents.onTouchStart = this.onHandleStart.bind(null, i);
            } else if (down.controlled === i) {
                _handleEvents.onTouchMove = this.onHandleMove;
                _handleEvents.onTouchEnd = this.onHandleAbort;
                _handleEvents.onTouchCancel = this.onHandleAbort;
            }
        } else {
            _handleEvents.onMouseDown = this.onHandleStart.bind(null, i);
        }
        return _handleEvents;
    }

    blockSlider = (len, values, handles) => {
        let _handles = handles;
        // istanbul ignore else
        if (len >= 3 && values[len - 2] === 0 && values[len - 1] === 0) {
            let reverseFromIndex;
            const h1 = _handles.slice(0, reverseFromIndex);
            const h2 = _handles.slice(reverseFromIndex);
            h2.reverse();
            _handles = h1.concat(h2);
        }
        return _handles;
    }

    reverseX(x) {
        const { props } = this;
        const { width } = props;
        const { padX } = props;
        const sum = this.sum();
        return sum * ((x - padX) / (width - 2 * padX));
    }

    // map a value to an x position
    x(value) {
        const { props } = this;
        const { width } = props;
        const { padX } = props;
        const sum = this.sum();
        return Math.round(padX + (value * (width - 2 * padX)) / sum);
    }

    sum() {
        // (might optimize this computation on values change if costy)
        return this.props.values.reduce((a, b) => {
            return a + b;
        });
    }

    xForEvent(e) {
        let node = this.root;
        if (typeof (node.getScreenCTM) === 'function') {
            const m = node.getScreenCTM();
            let p = node.createSVGPoint();
            if (useTouches()) {
                // There is a bug in touch events and we need to compute the real clientX
                // http://stackoverflow.com/questions/5885808/includes-touch-events-clientx-y-scrolling-or-not
                const stopAt = document.body.parentElement;
                while (node && node !== stopAt) {
                    node = node.parentElement;
                }
            }
            p.x = e.clientX;
            p = p.matrixTransform(m.inverse());
            return p.x;
        }
        return null;
    }

    render() {
        const { state } = this;
        const { down } = state;
        const { props } = this;
        const { width } = props;
        const { height } = props;
        const { values } = props;
        const len = values.length;
        const { colors } = props;
        const { trackSize } = props;
        const { handleSize } = props;
        const { handleStrokeSize } = props;
        const { handleInnerDotSize } = props;
        const { bg } = props;
        const centerY = height / 2;
        const touchEvents = useTouches();
        const tracks = [];
        let handles = [];
        let prev = 0;
        let prevColor = bg;
        for (let i = 0; i < len; ++i) {
            const value = values[i];
            const next = prev + value;
            const fromX = this.x(prev);
            const toX = this.x(next);
            const color = i === 2 && this.props.setDefaultMaxValue
                ? colors[i - (1 % colors.length)]
                : colors[i % colors.length];
            let handleEvents = {};
            let index = i;
            [index, handleEvents] = this.setIndexHandleEvents(
                i,
                down,
                index,
                touchEvents,
                handleEvents
            );

            tracks.push(
                <Track
                    key={i}
                    color={color}
                    y={centerY}
                    lineWidth={trackSize}
                    fromX={fromX}
                    toX={toX}
                    events={handleEvents}
                />
            );

            if (i !== 0) {
                if (
                    (i === 1 && !this.props.setDefaultMinValue)
                    || (i === 2 && !this.props.setDefaultMaxValue)
                ) {
                    handleEvents = {};
                    handleEvents = this.setHandleEvents(touchEvents, down, i, handleEvents);
                    handles.push(
                        <Handle
                            key={i}
                            active={down && down.controlled === i}
                            x={fromX}
                            y={centerY}
                            bg={bg}
                            color={prevColor}
                            strokeWidth={handleStrokeSize}
                            innerRadius={handleInnerDotSize}
                            size={handleSize}
                            events={handleEvents}
                            handleKeyDown={this.handleKeyDown}
                            sliderKey={i}
                        />
                    );
                }
            }
            prev = next;
            prevColor = color;
        }
        // Specific case to avoid blocking the slider.
        handles = this.blockSlider(len, values, handles);
        const events = {};
        if (!touchEvents && down) {
            events.onMouseMove = this.onHandleMove;
            events.onMouseUp = this.onHandleAbort;
            events.onMouseLeave = this.onHandleAbort;
        }
        return (
            <svg
                ref={(node) => {
                    this.root = node;
                }}
                {...events}
                width='100%'
                height='100%'
                viewBox={`0 0 ${width} ${height}`}
                data-testid='multi-slider'
            >
                {tracks}
                {handles}
            </svg>
        );
    }
}

MultiSlider.propTypes = {
    colors: PropTypes.arrayOf(PropTypes.string),
    values: PropTypes.arrayOf(PropTypes.number),
    onChange: PropTypes.func,
    width: PropTypes.number,
    height: PropTypes.number,
    padX: PropTypes.number,
    trackSize: PropTypes.number,
    handleSize: PropTypes.number,
    handleStrokeSize: PropTypes.number,
    handleInnerDotSize: PropTypes.number,
    bg: PropTypes.string,
    setDefaultMinValue: PropTypes.bool,
    setDefaultMaxValue: PropTypes.bool,
    sliderKey: PropTypes.string
};

MultiSlider.defaultProps = {
    colors: ['#000'], // define your own colors instead.
    handleSize: 6,
    padX: 40, // MUST be > handleSize to avoid clip issues
    width: 300,
    height: 20,
    trackSize: 7,
    handleStrokeSize: 1,
    handleInnerDotSize: 0,
    bg: '#fff',
    setDefaultMinValue: false,
    setDefaultMaxValue: false,
    onChange: null,
    values: [],
    sliderKey: ''
};
