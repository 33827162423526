import _round from 'lodash/round';
import _isNumber from 'lodash/isNumber';
import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';

import scoreHistogramData from '../MockData/studentDistribution.json';
import { ERRORS } from '../common/constants';
import { setSelectedFilter, makeAPICall, GAprops, sendGTM } from '../common/utils';
import { studentListSchema, studentListExportSchema } from '../common/schemaHelper';
import integrationHelper from '../common/integrationHelper';

const STORE_RESET = 'studentList/STORE_RESET';
const INITIATE_STUDENTLIST_DATA = 'studentList/INITIATE_STUDENTLIST_DATA';
const FILTER_STUDENTLIST_DATA = 'studentList/FILTER_STUDENTLIST_DATA';
const UPDATE_SEARCH_DROPDOWN = 'studentList/UPDATE_SEARCH_DROPDOWN';
const UPDATE_SEARCH_TEXT = 'studentList/UPDATE_SEARCH_TEXT';
const SELECTED_FILTER_VALUES = 'studentList/SELECTED_FILTER_VALUES';
const UPDATE_PAGE_INDEX = 'studentList/UPDATE_PAGE_INDEX';
const FILTER_HISTOGRAM_DATA_TO_STUDENT_LIST = 'studentList/FILTER_HISTOGRAM_DATA_TO_STUDENT_LIST';
const SORT_STUDENTLIST_DATA = 'studentList/SORT_STUDENTLIST_DATA';
const STUDENTLIST_LOADING_STATE = 'studentList/STUDENTLIST_LOADING_STATE';
const EXPORT_ERROR_MESSAGE = 'common/EXPORT_ERROR_MESSAGE';
const ERROR_STUDENTLIST_DATA = 'studentList/ERROR_STUDENTLIST_DATA';
const ERROR_SEARCH_STUDENTLIST_DATA = 'studentList/ERROR_SEARCH_STUDENTLIST_DATA';
const SHOULD_GRID_LOAD = 'studentList/SHOULD_GRID_LOAD';

const initialState = {
    studentList: [],
    studentSearchText: '',
    searchDropDownList: [],
    selectedFilters: [],
    scoreHistogramData: {},
    pagination: {
        offset: 1,
        limit: 10,
        total: 0
    },
    piiErrorCount: 0,
    errors: [],
    isPageLoading: 'true',
    hasSearchAPIError: false,
    hasAPIError: false,
    sortBy: [{ fieldName: 'familyName', sortOrder: 'ASC' }, { fieldName: 'givenName', sortOrder: 'ASC' }],
    exportError: false,
    isGridLoading: false
};

const processStudentList = ({ studentCourseKnowledgeInfo },
    type = INITIATE_STUDENTLIST_DATA, sort, extraParams, errors) => {
    const {
        studentCourseKnowledgeInfoList, studentDistribution, pagination,
        totalNoPiiRecordCount = 0
    } = studentCourseKnowledgeInfo;
    const studentList = studentCourseKnowledgeInfoList.map(({ student, knowledgeInfo }) => {
        return {
            id: student.id,
            studentId: student.id,
            firstName: student.firstName,
            lastName: student.lastName,
            primaryEmailAddress: student.emails[0],
            correctFirstTry: knowledgeInfo.correctOnFirstAttemptTry,
            knowledgeScore: knowledgeInfo.knowledgeLevel,
            timeOnTask: knowledgeInfo.timeOnTask,
            tags: student.tags
        };
    });

    const gradeDistributionData = studentDistribution.map((item) => {
        let pctVal = -1;
        if (_isNumber(item.lowerLimit.value)) {
            pctVal = _round(item.lowerLimit.value, 1);
        }
        return {
            gradeStudentCount: item.count,
            gradeStudentCountPct: pctVal
        };
    });

    const payload = {
        studentList,
        scoreHistogramData: {
            ...scoreHistogramData,
            studentDistribution,
            gradeDistributionData: [...gradeDistributionData]
        },
        pagination,
        sortBy: sort,
        piiErrorCount: totalNoPiiRecordCount,
        errors
    };

    if (extraParams.GAtype === GAprops.initialLoad) {
        const COURSE_IDS = (extraParams.courseId || []).map((itm) => `${itm.courseId}`).toString();
        sendGTM({
            eventName: 'studentsPageLoaded',
            eventDesc: 'Students page loaded',
            eventLabel: `courseId: ${COURSE_IDS}, numberOfStudents: ${payload.pagination.total}`,
            page: 'Student list'
        });
    } else if (extraParams.GAtype === GAprops.onStudentBarChartFilter) {
        sendGTM({
            eventName: 'performanceBarClickedToFilter',
            eventDesc: 'Bar chart - bar clicked to filter',
            eventLabel: `knowledgeScore: ${extraParams.knowledgeLevel}, students: ${payload.pagination.total}`,
            page: GAprops.studentPage
        });
    }

    return ({ type, payload });
};

const getProcessedPayload = (payload, studentListData) => {
    const processedPayload = {};
    processedPayload.filter = {};

    const getKnowledgeFilterItem = (filterItemKey) => {
        return {
            upperLimit: {
                value: payload.thresholdSettingsData[filterItemKey].maxValue,
                inclusive: true
            },
            lowerLimit: {
                value: payload.thresholdSettingsData[filterItemKey].minValue,
                inclusive: true
            },
            name: filterItemKey
        };
    };

    if (payload?.lrSearchKeyword || payload.lrSearchKeyword !== '') {
        processedPayload.filter.lrSearchKeyword = payload.lrSearchKeyword;
    }

    const isBarFiltered = _isNumber(payload.histogramIndex);
    if (payload && payload?.selectedFilters) {
        payload.selectedFilters.forEach((item) => {
            if (item.type === 'knowledgeScore' && !isBarFiltered) {
                if (!processedPayload.filter?.knowledgeLevel) {
                    processedPayload.filter.knowledgeLevel = [];
                }

                let filterItem = null;
                if (item.id === 'belowAverage') {
                    filterItem = getKnowledgeFilterItem('BelowAverage');
                } else if (item.id === 'average') {
                    filterItem = getKnowledgeFilterItem('Average');
                } else if (item.id === 'aboveAverage') {
                    filterItem = getKnowledgeFilterItem('AboveAverage');
                } else if (item.id === 'noAttempts') {
                    filterItem = {
                        upperLimit: { value: null, inclusive: true },
                        lowerLimit: { value: null, inclusive: true },
                        name: 'No Attempts'
                    };
                }
                filterItem && processedPayload.filter.knowledgeLevel.push(filterItem);
            } else if (item.type === 'studentTags') {
                if (!processedPayload.filter?.studentTags) {
                    processedPayload.filter.studentTags = [];
                }
                processedPayload.filter.studentTags.push(item.id);
            } else if (item.type === 'CHAPTER' || item.type === 'TOPIC' || item.type === 'SECTION') {
                if (!processedPayload.filter?.learningResources) {
                    processedPayload.filter.learningResources = [];
                }
                const filterItem = { id: item.id, type: item.type };
                processedPayload.filter.learningResources.push(filterItem);
            }
        });
    }

    if (isBarFiltered) {
        const idx = payload.histogramIndex;
        const gradeDistribution = studentListData.scoreHistogramData.studentDistribution;
        const filterItem = {
            upperLimit: {
                value: idx > 0 ? gradeDistribution[idx].upperLimit.value : null,
                inclusive: gradeDistribution[idx].upperLimit.inclusive
            },
            lowerLimit: {
                value: idx > 0 ? gradeDistribution[idx].lowerLimit.value : null,
                inclusive: gradeDistribution[idx].lowerLimit.inclusive
            },
            name: idx > 0 ? 'AboveAverage' : 'No Attempts'
        };
        if (!processedPayload?.filter?.knowledgeLevel) {
            processedPayload.filter.knowledgeLevel = [];
        }
        processedPayload.filter.knowledgeLevel.push(filterItem);
    }

    if (payload?.sort) {
        processedPayload.sort = payload.sort;
    } else {
        processedPayload.sort = [{ fieldName: 'familyName', sortOrder: 'ASC' }, { fieldName: 'givenName', sortOrder: 'ASC' }];
    }

    if (payload?.pagination) {
        processedPayload.pagination = payload.pagination;
    }
    return processedPayload;
};

const callStudentApi = (studentPayload = {}, type, GAtype, bGridAction = false) => {
    return async (dispatch, getState) => {
        const studentListData = getState().studentListReducer;
        const processedPayload = getProcessedPayload(studentPayload, studentListData);
        !bGridAction && dispatch(setStudentLoadingState('true'));
        bGridAction && dispatch(shouldGridLoad(true));
        const { configs } = getState().commonReducer;
        try {
            const query = studentListSchema(configs, processedPayload);
            const resp = await makeAPICall(configs.API_URL, query, 'POST');
            if (resp.status === 200) {
                if (_isEmpty(resp.data.data.studentCourseKnowledgeInfo)) {
                    integrationHelper.triggerCallback(ERRORS.NOCOURSE_ERROR, 'updateRoute');
                    return dispatch({ type: STORE_RESET });
                }

                const extraParams = { GAtype, courseId: configs.COURSE_IDS };
                if (GAtype === GAprops.onStudentBarChartFilter) {
                    const { lowerLimit, upperLimit } = query.variables.filter.knowledgeLevel[0];
                    extraParams.knowledgeLevel = `${lowerLimit.value} - ${upperLimit.value}`;
                }
                if (studentPayload?.GAtype === 'searchStudentsInStudentDetailview') {
                    sendGTM({
                        eventName: 'searchStudentsInStudentDetailview',
                        eventDesc: 'Search students in student detail view',
                        eventLabel: `searchStrLength: ${_size(studentPayload.lrSearchKeyword)},
                            searchResultCnt: ${resp.data.data.studentCourseKnowledgeInfo.pagination.total}`,
                        page: GAprops.studentDetailsPage
                    });
                } else if (studentPayload.GAtype === 'searchAndEnterStudentDetailview') {
                    sendGTM({
                        eventName: 'searchAndEnterStudentDetailview',
                        eventDesc: 'Search and enter in student detail view',
                        eventLabel: `searchStrLength: ${_size(studentPayload.lrSearchKeyword)},
                            searchResultCnt: ${resp.data.data.studentCourseKnowledgeInfo.pagination.total}`,
                        page: GAprops.studentDetailsPage
                    });
                } else if (GAtype === GAprops.onSearch) {
                    sendGTM({
                        eventName: 'searchStudentByName',
                        eventDesc: 'Search student By Name',
                        eventLabel: `searchStrLength: ${_size(studentPayload.lrSearchKeyword)},
                            searchResultCnt: ${resp.data.data.studentCourseKnowledgeInfo.pagination.total}`,
                        page: GAprops.studentPage
                    });
                } else if (GAtype === GAprops.onSearchClear) {
                    sendGTM({
                        eventName: 'studentSearchClear',
                        eventDesc: 'student search text cleared',
                        eventLabel: 'student search text cleared',
                        page: GAprops.studentPage
                    });
                }
                let errors = [];
                if (GAtype !== 'onSearch' && resp.data.data.studentCourseKnowledgeInfo && resp.data.data.studentCourseKnowledgeInfo.errors && resp.data.data.studentCourseKnowledgeInfo.errors.length > 0) {
                    errors = resp.data.data.studentCourseKnowledgeInfo.errors;
                }
                return dispatch(processStudentList(resp.data.data,
                    type,
                    processedPayload.sort,
                    extraParams,
                    errors));
            }
            return dispatch({ type: STORE_RESET });
        } catch {
            if (type === UPDATE_SEARCH_DROPDOWN) {
                return dispatch({ type: ERROR_SEARCH_STUDENTLIST_DATA });
            }
            return dispatch({ type: ERROR_STUDENTLIST_DATA });
        }
    };
};

const setExportErrorMessage = () => ({
    type: EXPORT_ERROR_MESSAGE,
    payload: true
});

const exportStudentListData = (studentPayload = {}) => {
    return async (dispatch, getState) => {
        const studentListData = getState().studentListReducer;
        const processedPayload = getProcessedPayload(studentPayload, studentListData);
        const { configs } = getState().commonReducer;
        const { sortBy, pagination } = getState().studentListReducer;
        try {
            const options = {
                filter: processedPayload.filter,
                sortBy
            };
            const query = studentListExportSchema(configs, options);
            const resp = await makeAPICall(configs.API_URL, query, 'POST');
            const windowReference = window.open('', '_self');
            if (resp.status === 200 && resp.data) {
                sendGTM({
                    eventName: 'studentListExport',
                    eventDesc: 'Student list export',
                    eventLabel: `exported
                        ${pagination.total}
                        rows`,
                    page: GAprops.studentPage
                });
                windowReference.location = resp.data.data.exportStudentList.fileName;
            } else if (resp.data.data.exportTopicsList.fileName == null) {
                dispatch(setExportErrorMessage());
            }
        } catch (e) {
            dispatch(setExportErrorMessage());
        }
        return null;
    };
};

const initiateStudentListData = (studentPayload = {}, GAtype = null, fromChart = false) => {
    return callStudentApi(studentPayload, INITIATE_STUDENTLIST_DATA, GAtype, fromChart);
};

const sortStudentList = (studentPayload = {}) => {
    return callStudentApi(studentPayload, SORT_STUDENTLIST_DATA, null, true);
};

const resetStudentData = () => {
    return (dispatch, getState) => {
        const { commonReducer, studentListReducer } = getState();
        const COURSE_IDS = (commonReducer.configs.COURSE_IDS || []).map((itm) => `${itm.courseId}`).toString();
        sendGTM({
            page: GAprops.studentPage,
            eventName: 'studentsPageUnloaded',
            eventDesc: 'students page unloaded',
            eventLabel: `{courseId: ${COURSE_IDS}, numberOfStudents: ${studentListReducer.pagination.total}}`
        });
        dispatch({ type: STORE_RESET });
    };
};

const updateSearchDropDown = (studentPayload = {}, GAtype = null) => {
    return callStudentApi(studentPayload, UPDATE_SEARCH_DROPDOWN, GAtype);
};

const filterStudentListGrid = (studentPayload = {}, GAtype = null) => {
    return callStudentApi(studentPayload, FILTER_STUDENTLIST_DATA, GAtype, true);
};

const updateStuListSelectedFilters = (payload, isSticky = false) => {
    !isSticky && setSelectedFilter('studentListFilter', payload.map(({ id }) => id));
    return { type: SELECTED_FILTER_VALUES, payload };
};

const updateHistogramStudentList = (studentPayload = {}, GAtype = null) => {
    return callStudentApi(studentPayload, FILTER_HISTOGRAM_DATA_TO_STUDENT_LIST, GAtype, true);
};

const updateSearchText = payload => ({ type: UPDATE_SEARCH_TEXT, payload });

const updatePageIndex = (studentPayload = {}) => {
    return callStudentApi(studentPayload, UPDATE_PAGE_INDEX, null, true);
};

const setStudentLoadingState = (isLoading) => (
    { type: STUDENTLIST_LOADING_STATE, payload: isLoading }
);

const shouldGridLoad = (payload) => (
    { type: SHOULD_GRID_LOAD, payload }
);

const studentListReducer = (state = initialState, action) => {
    const { type, payload } = action;
    let nextState;
    switch (type) {
    case STUDENTLIST_LOADING_STATE:
        nextState = { ...state, isPageLoading: payload };
        return nextState;
    case INITIATE_STUDENTLIST_DATA:
        nextState = {
            ...state,
            ...payload,
            isPageLoading: 'false',
            hasAPIError: false,
            hasSearchAPIError: false,
            isGridLoading: false
        };
        return nextState;
    case SORT_STUDENTLIST_DATA:
        nextState = {
            ...state,
            studentList: payload.studentList,
            isPageLoading: 'false',
            pagination: payload.pagination,
            sortBy: payload.sortBy,
            hasAPIError: false,
            isGridLoading: false
        };
        return nextState;
    case UPDATE_SEARCH_DROPDOWN: {
        nextState = {
            ...state,
            searchDropDownList: payload.studentList,
            isPageLoading: 'false',
            hasAPIError: false,
            hasSearchAPIError: false,
            isGridLoading: false
        };
        return nextState;
    }
    case FILTER_STUDENTLIST_DATA: {
        nextState = {
            ...state,
            ...payload,
            searchDropDownList: payload.studentList,
            isPageLoading: 'false',
            hasAPIError: false,
            hasSearchAPIError: false,
            isGridLoading: false
        };
        return nextState;
    }
    case SELECTED_FILTER_VALUES:
        nextState = {
            ...state,
            selectedFilters: payload,
            isPageLoading: 'false',
            hasAPIError: false,
            isGridLoading: false
        };
        return nextState;
    case FILTER_HISTOGRAM_DATA_TO_STUDENT_LIST: {
        nextState = {
            ...state,
            studentList: payload.studentList,
            pagination: payload.pagination,
            isPageLoading: 'false',
            hasAPIError: false,
            isGridLoading: false
        };
        return nextState;
    }
    case UPDATE_PAGE_INDEX:
        nextState = {
            ...state,
            studentList: payload.studentList,
            pagination: payload.pagination,
            isPageLoading: 'false',
            hasAPIError: false,
            isGridLoading: false
        };
        return nextState;
    case UPDATE_SEARCH_TEXT:
        nextState = {
            ...state,
            studentSearchText: payload,
            isPageLoading: 'false',
            hasAPIError: false,
            hasSearchAPIError: false,
            isGridLoading: false
        };
        return nextState;
    case ERROR_STUDENTLIST_DATA:
        nextState = {
            ...state,
            scoreHistogramData: { ...scoreHistogramData },
            hasAPIError: true,
            hasSearchAPIError: true,
            isPageLoading: 'false',
            isGridLoading: false
        };
        return nextState;
    case ERROR_SEARCH_STUDENTLIST_DATA:
        nextState = {
            ...state,
            hasSearchAPIError: true,
            isPageLoading: 'false',
            isGridLoading: false
        };
        return nextState;
    case SHOULD_GRID_LOAD:
        nextState = {
            ...state,
            isGridLoading: payload
        };
        return nextState;
    case STORE_RESET:
        return initialState;
    default:
        return state;
    }
};

export const actions = {
    resetStudentData,
    initiateStudentListData,
    updateSearchDropDown,
    filterStudentListGrid,
    updateStuListSelectedFilters,
    updateSearchText,
    sortStudentList,
    updateHistogramStudentList,
    updatePageIndex,
    exportStudentListData,
    shouldGridLoad,
};

export default studentListReducer;
