const appObj = {
    configs: null,
    standalone: true
};

const callCallbacks = {};

const set = (key, value) => {
    appObj[key] = value;
};

const get = (key) => appObj[key] || '';

const initCallback = (callback, type) => {
    callCallbacks[type] = callback;
};

const triggerCallback = (data, type) => {
    return callCallbacks[type](data);
};

const messageActions = (args) => {
    if (args.appId === appObj.configs.APP_ID) {
        if (args.type === 'stateChange') {
            triggerCallback(args.data, 'updateRoute');
        } else if (args.type === 'LOAD_DATA') {
            triggerCallback(args.data, 'loadData');
        }
    }
};

const initIntegration = (clientConfig) => {
    if (!appObj.configs) {
        appObj.configs = clientConfig;
    }

    if (window.DashboardEventManager) {
        window.DashboardEventManager.subscribe('navigationManager', 'messageActions', messageActions);
    }
};

const sendMessageToPEP = (message) => {
    const latestMessage = { ...message };
    if (window.DashboardEventManager) {
        latestMessage.appId = 'PEP';
        window.DashboardEventManager.publish('messageManager', latestMessage);
    }
};

const changeParentTab = (tabId) => {
    sendMessageToPEP({ type: 'changeParentTab', tabId });
};

const updateParentHeight = (childHeight) => {
    sendMessageToPEP({ type: 'setIframeHeight', height: childHeight });
};

const integrationHelper = {
    set,
    get,
    initIntegration,
    sendMessageToPEP,
    changeParentTab,
    updateParentHeight,
    initCallback,
    triggerCallback
};

export default integrationHelper;
