import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap';
import './common/elements.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import App from './components/app';
import store from './store';
import integrationHelper from './common/integrationHelper';
import { saveDataToSession } from './common/sessionStorageHelper';
import { getUrlParameter } from './common/utils';
import configs from './common/config';
import history from './common/history';

import './index.scss';

const env = getUrlParameter('env') || 'qa';
const clientConfig = configs.getConfigByEnv(env);
const head = document.getElementsByTagName('head')[0];

const handleUserSessionTimeout = () => {
    window.piSession.on(window.piSession.LogoutEvent, () => {
        window.piSession.hideSessionTimingOutWarningModal(0);
        window.piSession.hideSessionTimedOutModal();
        window.piSession.login(window.location.href);
    });
};

const loadData = (parentAppData) => {
    renderApp(parentAppData);
};

// Pearson Icon Load
if (document.body.children[0].nodeName !== 'svg') {
    const peAjax = new XMLHttpRequest();
    peAjax.open('GET', clientConfig.PEARSON_ICON, true);
    peAjax.responseType = 'document';
    peAjax.onload = () => {
        document.body.insertBefore(peAjax.responseXML.documentElement, document.body.childNodes[0]);
    };
    peAjax.send();
}

const isFrameCall = () => {
    try {
        return window.self !== window.top;
    } catch {
        return true;
    }
};

const callFunctionFromIntegrationScript = () => {
    window.piSession.login(window.location.href, 2, () => {
        integrationHelper.initIntegration(clientConfig);
        if (!isFrameCall()) {
            document.querySelector('body').style.overflow = 'visible';
            integrationHelper.set('standalone', true);
            loadData({});
        } else {
            integrationHelper.set('standalone', false);
            integrationHelper.initCallback(loadData, 'loadData');
            integrationHelper.sendMessageToPEP({ type: 'loadStarted', data: 'started' });
            integrationHelper.sendMessageToPEP({ type: 'CHILD_COMPONENT_READY' });
        }
    });
};

const callFunctionFromPIScript = () => {
    const idScript = document.createElement('script');
    idScript.type = 'text/javascript';
    idScript.onload = () => {
        callFunctionFromIntegrationScript();
    };
    saveDataToSession('ca_custom_is_demo', JSON.parse(getUrlParameter('isDemo')));
    if (process.env.NODE_ENV === 'development') {
        idScript.setAttribute('src', 'http://localhost.pearsoncmg.com:7212/analytics/src/client/dashboardIntegration/plaDashboardIntegration.js');
    } else {
        idScript.setAttribute('src', clientConfig.ID_INTEGRATION_JS);
    }
    head.appendChild(idScript);
};

const piScript = document.createElement('script');
piScript.type = 'text/javascript';
piScript.onload = () => {
    const jwtSetting = {
        useJwt: true,
        checkSessionMilliseconds: '3000',
        sessionIdleTimeoutSeconds: '1800'
    };
    if (piSession) {
        piSession.initialize(clientConfig.PI_CLIENT_ID, jwtSetting);
    }
    callFunctionFromPIScript();
};
piScript.setAttribute('src', clientConfig.PI_URL);
head.appendChild(piScript);

const gtmScript = document.createElement('script');
gtmScript.type = 'text/javascript';
const srcContent = ((w, d, s, l, i) => {
    const g = w;
    if (w[l]) {
        g[l] = w[l];
    } else {
        g[l] = [];
    }
    g[l].push({
        'gtm.start':
        new Date().getTime(),
        event: 'gtm.js'
    });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s);
    const dl = l !== 'dataLayer' ? `&l=${l}` : '';
    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}&gtm_auth=${clientConfig.GTM_CONFIG.AUTH}&gtm_preview=${clientConfig.GTM_CONFIG.PREVIEW}&gtm_cookies_win=x`;
    f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', clientConfig.GTM_CONFIG.ID);
gtmScript.setAttribute('src', srcContent);
head.appendChild(gtmScript);

const renderApp = (parentAppData = {}) => {
    if (integrationHelper.get('standalone')) {
        handleUserSessionTimeout();
    }

    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <App clientConfig={clientConfig} parentAppData={parentAppData} />
                </ConnectedRouter>
            </Provider>
        </React.StrictMode>,
        document.getElementById('root')
    );
};
