import _result from 'lodash/result';
import _pick from 'lodash/pick';
import _isEmpty from 'lodash/isEmpty';
import _round from 'lodash/round';

import { setSelectedFilter, makeAPICall, GAprops, sendGTM, getDistributionThresholds, getFilterKnowledgeLevel } from '../common/utils';
import { PERF_GROUP, ERRORS } from '../common/constants';
import { topicListSchema, exportTopicsSchema } from '../common/schemaHelper';
import commonHelper from '../common/commonHelper';
import integrationHelper from '../common/integrationHelper';

const STORE_RESET = 'topics/STORE_RESET';
const SELECTED_FILTER_VALUES = 'topics/SELECTED_FILTER_VALUES';
const INITIATE_TOPICS_DATA = 'topics/INITIATE_TOPICS_DATA';
const TOPICS_LOADING_STATE = 'topics/TOPICS_LOADING_STATE';
const TOPICS_GRID_LOADING = 'topics/TOPICS_GRID_LOADING';
const TOPICS_LIST_SORTBY = 'topics/TOPICS_LIST_SORTBY';
const TOPICS_LIST_SEARCH = 'topics/TOPICS_LIST_SEARCH';
const EXPORT_ERROR_MESSAGE = 'common/EXPORT_ERROR_MESSAGE';

const initialState = {
    topicsList: [],
    selectedFilters: [],
    topicDistribution: [],
    isPageLoading: 'true',
    searchText: '',
    sortBy: [{ id: 'id', desc: false }],
    pagination: {
        offset: 1,
        limit: 10,
        total: 0
    },
    isGridLoading: false
};

const setTopicsLoadingState = (isLoading) => ({ type: TOPICS_LOADING_STATE, payload: isLoading });

const setTopicsGridLoading = (isLoading) => ({ type: TOPICS_GRID_LOADING, payload: isLoading });

const setTopicsSortBy = (sortArr) => ({ type: TOPICS_LIST_SORTBY, payload: sortArr });

const setTopicsSearch = (value) => ({ type: TOPICS_LIST_SEARCH, payload: value });

const getStudentDistribution = (stuDistr) => {
    if (_isEmpty(stuDistr)) {
        return null;
    }

    const { belowAverage, average, aboveAverage } = PERF_GROUP;
    return {
        belowAverage: _round(stuDistr.find(s => s.name === belowAverage.title).percentage),
        average: _round(stuDistr.find(s => s.name === average.title).percentage),
        aboveAverage: _round(stuDistr.find(s => s.name === aboveAverage.title).percentage)
    };
};

const setTopicsList = (data, hasError, GAobj = {}, commonReducerValues = {}) => {
    const infoList = _result(data, 'learningResourceKnowledgeInfoList.learningResourceKnowledgeInfoList', []);
    const totalStudents = _result(data, 'learningResourceKnowledgeInfoList.totalStudents', null);
    const topicsList = infoList.map(topic => {
        const stuDistr = topic.studentDistribution;
        return {
            id: topic.learningResource.id,
            topic: topic.learningResource.label,
            knowledgeScore: _result(topic, 'knowledgeInfo.knowledgeLevel', null),
            correctFirstTry: _result(topic, 'knowledgeInfo.correctOnFirstAttemptTry', null),
            avgDuration: _result(topic, 'knowledgeInfo.timeOnTask', null),
            studentAttempted: {
                totalStudents,
                totalAttempts: _result(topic, 'knowledgeInfo.countSubmissions', null)
            },
            studentKnowledge: getStudentDistribution(stuDistr)
        };
    });

    const distro = _result(data, 'learningResourceKnowledgeInfoList.learningResourceDistribution', []);
    const distroList = distro.map(d => {
        let rangeKey = 'noAttempts';
        if (d.name === 'Below Average') {
            rangeKey = 'belowAverage';
        } else if (d.name === 'Average') {
            rangeKey = 'average';
        } else if (d.name === 'Above Average') {
            rangeKey = 'aboveAverage';
        }

        return {
            id: PERF_GROUP[rangeKey].id,
            name: PERF_GROUP[rangeKey].title,
            value: d.count,
            type: PERF_GROUP[rangeKey].variant
        };
    });
    const pagination = _result(data, 'learningResourceKnowledgeInfoList.pagination', initialState.pagination);
    const COURSE_IDS = _result(data, 'learningResourceKnowledgeInfoList.courses', []).map((itm) => `${itm.id}`).toString();
    const obj = {
        page: GAprops.topicPage
    };
    if (GAobj.GAtype === GAprops.initialLoad) {
        const topicsClsAvg = commonReducerValues.topicsClsAvg
            ? _round(commonReducerValues.topicsClsAvg * 100) : null;
        obj.eventName = 'topicsPageLoaded';
        obj.eventDesc = 'Topics page loaded';
        obj.eventLabel = `courseId: ${COURSE_IDS}, knowldegeScorePerformance: ${topicsClsAvg}, numberOfTopics: ${pagination.total}`;
        sendGTM(obj);
    } else if (GAobj.GAtype === GAprops.onSearch) {
        obj.eventName = 'searchTopicByName';
        obj.eventDesc = 'Search topic By Name';
        obj.eventLabel = `searchStrLength: ${GAobj.options.filter.lrSearchKeyword.length}, searchResultCnt: ${pagination.total}`;
        sendGTM(obj);
    } else if (GAobj.GAtype === GAprops.onSearchClear) {
        obj.eventName = 'topicsSearchClear';
        obj.eventDesc = 'Topics search text cleared';
        obj.eventLabel = 'Topics search text cleared';
        sendGTM(obj);
    }
    return {
        type: INITIATE_TOPICS_DATA,
        payload: {
            topicsList,
            topicDistribution: distroList,
            hasAPIError: hasError,
            pagination
        }
    };
};

const getSortObject = (sortArr) => {
    if (!_isEmpty(sortArr)) {
        const [item] = sortArr;
        const sortOrder = item.desc ? 'DESC' : 'ASC';
        return { fieldName: item.id, sortOrder };
    }

    return null;
};

const getFilterObject = (commonData, { selectedFilters, searchText }) => {
    const respObj = {
        filter: { lrSearchKeyword: searchText },
        withSearchOrThreshold: !_isEmpty(searchText)
    };
    if (!_isEmpty(selectedFilters)) {
        // handle chapter filters
        const topicItems = selectedFilters.filter(sf => sf.type !== 'knowledgeScore');
        if (!_isEmpty(topicItems)) {
            respObj.filter.learningResources = topicItems.map(ti => _pick(ti, ['id', 'type']));
        }

        // handle knowledge score filters
        const knwItems = selectedFilters.filter(sf => sf.type === 'knowledgeScore');
        if (!_isEmpty(knwItems)) {
            respObj.withSearchOrThreshold = true;
            respObj.filter.knowledgeLevel = getFilterKnowledgeLevel(
                knwItems, commonData.thresholdSettingsData
            );
        }
    }

    return respObj;
};

const getTopicsData = (clearToken = false, pagination = null, GAtype, bGridAction = false) => {
    return async (dispatch, getState) => {
        !bGridAction && dispatch(setTopicsLoadingState('true'));
        bGridAction && dispatch(setTopicsGridLoading(true));
        clearToken && commonHelper.cancelTokens();
        try {
            const { commonReducer, topicsReducer, filterReducer } = getState();
            const { filter, withSearchOrThreshold } = getFilterObject(commonReducer, topicsReducer);
            const pageObj = pagination || _pick(topicsReducer.pagination, ['offset', 'limit']);
            const options = {
                filter,
                withSearchOrThreshold,
                sortBy: getSortObject(topicsReducer.sortBy),
                pagination: pageObj
            };
            const distributionThresholds = getDistributionThresholds(commonReducer);
            const query = topicListSchema(commonReducer, options, distributionThresholds);
            const resp = await makeAPICall(commonReducer.configs.API_URL, query, 'POST');
            const GAobj = { GAtype, options };
            if (resp.status === 200) {
                if (filterReducer.hasNoTopics) {
                    integrationHelper.triggerCallback(ERRORS.NOTOPICS_ERROR, 'updateRoute');
                    dispatch(setTopicsList({}));
                } else if (_isEmpty(resp.data.data.learningResourceKnowledgeInfoList)) {
                    integrationHelper.triggerCallback(ERRORS.NOCOURSE_ERROR, 'updateRoute');
                    dispatch(setTopicsList({}));
                } else {
                    dispatch(setTopicsList(resp.data.data, false, GAobj, getState().commonReducer));
                }
            } else {
                dispatch(setTopicsList({}, true));
            }
        } catch {
            dispatch(setTopicsList({}, true));
        }

        return null;
    };
};

const resetTopicsData = () => {
    return (dispatch, getState) => {
        const { commonReducer, topicsReducer } = getState();
        const knowldegeScore = commonReducer.topicsClsAvg
            ? _round(commonReducer.topicsClsAvg * 100) : null;
        const COURSE_IDS = (commonReducer.configs.COURSE_IDS || []).map((itm) => `${itm.courseId}`).toString();
        sendGTM({
            page: GAprops.topicPage,
            eventName: 'topicsPageUnloaded',
            eventDesc: 'Topics page unloaded',
            eventLabel: `{courseId: ${COURSE_IDS}, knowldegeScore: ${knowldegeScore}, numberOfTopics: ${topicsReducer.pagination.total}}`
        });
        dispatch({ type: STORE_RESET });
    };
};

const updateSelectedFilters = (payload, isSticky = false) => {
    !isSticky && setSelectedFilter('topicsFilter', payload.map(({ id }) => id));
    return { type: SELECTED_FILTER_VALUES, payload };
};

const setTopicExportError = (exportError) => ({
    type: EXPORT_ERROR_MESSAGE,
    payload: exportError
});

const getExportData = () => {
    const windowReference = window.open('', '_self');
    return async (dispatch, getState) => {
        try {
            const { commonReducer, topicsReducer } = getState();
            const { filter, withSearchOrThreshold } = getFilterObject(commonReducer, topicsReducer);
            const options = {
                filter,
                withSearchOrThreshold,
                sortBy: getSortObject(topicsReducer.sortBy)
            };
            const distributionThresholds = getDistributionThresholds(commonReducer);
            const query = exportTopicsSchema(commonReducer, options, distributionThresholds);
            const resp = await makeAPICall(commonReducer.configs.API_URL, query, 'POST');
            if (resp.status === 200 && resp.data) {
                if (resp.data.data.exportTopicsList.fileName) {
                    windowReference.location = resp.data.data.exportTopicsList.fileName;
                } else {
                    dispatch(setTopicExportError(true));
                }
            } else {
                dispatch(setTopicExportError(true));
            }
        } catch (e) {
            dispatch(setTopicExportError(true));
        }

        return null;
    };
};

const topicsReducer = (state = initialState, action) => {
    const { type, payload } = action;
    let nextState;
    switch (type) {
    case SELECTED_FILTER_VALUES:
        nextState = { ...state, selectedFilters: payload };
        return nextState;
    case INITIATE_TOPICS_DATA:
        nextState = { ...state, ...payload, isPageLoading: 'false', isGridLoading: false };
        return nextState;
    case TOPICS_LOADING_STATE:
        nextState = { ...state, isPageLoading: payload };
        return nextState;
    case TOPICS_GRID_LOADING:
        nextState = { ...state, isGridLoading: payload };
        return nextState;
    case TOPICS_LIST_SORTBY:
        nextState = { ...state, sortBy: payload };
        return nextState;
    case TOPICS_LIST_SEARCH:
        nextState = { ...state, searchText: payload };
        return nextState;
    case STORE_RESET:
        return initialState;
    default:
        return state;
    }
};

export const actions = {
    resetTopicsData,
    updateSelectedFilters,
    getTopicsData,
    setTopicsSortBy,
    setTopicsSearch,
    getExportData,
    setTopicExportError,
    setTopicsGridLoading
};

export default topicsReducer;
