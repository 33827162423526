const saveDataToSession = (key, data) => {
    sessionStorage.setItem(key, JSON.stringify(data));
};

const getDataFromSession = (key) => JSON.parse(sessionStorage.getItem(key));

const deleteDataInSession = (key) => sessionStorage.removeItem(key);

const clearSessionSession = () => sessionStorage.clear();

export {
    saveDataToSession,
    getDataFromSession,
    deleteDataInSession,
    clearSessionSession
};
