import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const FailedMessage = (props) => {
    const [circleDimensions, setCircleDimensions] = useState({ x: 0, y: 0, r: 20 });
    const [iconDimensions, setIconDimensions] = useState({ x: 0, y: 0 });
    const [textTransform, setTextTransform] = useState({ x: 0, y: 0 });

    useEffect(() => {
        setCircleDimensions({
            ...circleDimensions,
            x: props.chartWidth / 2,
            y: (props.chartHeight - props.chartMargins.top) / 2 - 25,
        });

        setIconDimensions({
            x: props.chartWidth / 2,
            y: (props.chartHeight - props.chartMargins.top) / 2 - 15
        });

        setTextTransform({
            x: props.chartWidth / 2,
            y: (props.chartHeight - props.chartMargins.top) / 2 + 20
        });
    }, [props]);

    return (
        <g className={`${props.className}-wrapper`} data-testid={`${props.className}-wrapper`}>
            <circle className='exclamation-circle' cx={circleDimensions.x} cy={circleDimensions.y} r={circleDimensions.r} />
            <text className='exclamation-icon' transform={`translate(${iconDimensions.x}, ${iconDimensions.y})`}>!</text>
            <text className={props.className} transform={`translate(${textTransform.x}, ${textTransform.y})`}>{props.text}</text>
        </g>
    );
};

FailedMessage.propTypes = {
    chartWidth: PropTypes.number,
    chartHeight: PropTypes.number,
    chartMargins: PropTypes.object,
    className: PropTypes.string,
    text: PropTypes.string
};

FailedMessage.defaultProps = {
    chartWidth: 0,
    chartHeight: 0,
    chartMargins: {},
    className: '',
    text: ''
};
export default FailedMessage;
