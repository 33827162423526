import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';
import _isEmpty from 'lodash/isEmpty';
import _isNumber from 'lodash/isNumber';
import _size from 'lodash/size';
import _round from 'lodash/round';
import _isEqual from 'lodash/isEqual';
import TagIcon from '../../images/label-18.svg';
import Shadow, { ShadowTableGrid } from '../shadow';

import {
    secondsToHms, getEmailProps, renderEmailComponent,
    getStyledKnowledgeScore, getPageCount, GAprops, sendGTM, renderKnowledgeScoreInfoContent,
    renderErrorComp
} from '../../common/utils';
import { mapDispatchToProps } from '../../reducers/utils';
import integrationHelper from '../../common/integrationHelper';
import TopicPerformance from '../topic-performance';
import CourseKnowledgeLevel from '../course-knowledge-level';
import CustomTable from '../custom-table';
import CustomSearch from '../custom-search';
import NoCourseError from '../nocourse-error';

import BackIcon from '../../images/back-24.svg';
import './styles.scss';
import { RETRY_LIMIT } from '../../common/constants';

let initalGAAdded = false;

const StudentDetails = (props) => {
    const history = useHistory();
    const { actions, match, commonData, searchDropDownList, hasSearchAPIErrorStuList,
        studentData: {
            isPageLoading, isGridLoading, topicInfo, studentInfo, selectedFilters, hasAPIError,
            noCourseError, pagination, sortBy, hasSearchAPIError }, hasSearchLoader } = props;
    const { thresholdSettingsData, configs, userMessage, parentAppData } = commonData;

    const [searchText, setSearchText] = useState('');
    const [currentpagination, setCurrentpagination] = useState({ offset: 1, limit: 10 });
    const [topicSearchedText, setTopicSearchedText] = useState('');
    const [studentSearchText, setStudentSearchText] = useState('');
    const [emailObj, setEmailObj] = useState({});
    const [retryClickCount, setRetryClickCount] = useState(0);
    useEffect(() => {
        setSearchText('');
        triggerAPICall();
        setRetryClickCount(0);
        return () => {
            sendGTM({
                eventName: 'studentDetailsUnloaded',
                eventDesc: 'student detail unloaded',
                eventLabel: `studentId: ${match.params.studentId}, knowledgeScore: ${studentInfo?.knowledgeScore}%, knoledgeScoreDistribution: ${thresholdSettingsData}, numberOfTopics: ${pagination.total}}`,
                page: GAprops.studentDetailsPage
            });
            actions.resetStudentDetailsData();
        };
    }, [match.params.studentId]);

    useEffect(() => {
        if (studentInfo) {
            setStudentEmailObj(studentInfo);
        }
    }, [studentInfo, thresholdSettingsData]);

    useEffect(() => {
        if (!initalGAAdded && studentInfo && topicInfo) {
            initalGAAdded = true;
            sendGTM({
                eventName: 'studentDetailsLoaded',
                eventDesc: 'student detail loaded',
                eventLabel: `{
                    studentId: ${match.params.studentId},
                    knowledgeScore: ${_round(studentInfo.knowledgeScore)}%,
                    knowledgeScoreDistribution: ${JSON.stringify(topicInfo.topicDistribution)},
                    numberOfTopics: ${pagination.total}
                }`,
                page: GAprops.studentDetailsPage
            });
        }
    }, [topicInfo, studentInfo]);

    const triggerAPICall = () => {
        actions.getStudentCourseSummaryDetails(match.params.studentId);
        actions.getStudentDetails(
            match.params.studentId, null, commonData, null, GAprops.initialLoad
        );
        actions.initStuDetailFilterOptions();
        integrationHelper.sendMessageToPEP({ type: 'scrollToTop' });
        initalGAAdded = false;
    };

    const onSearchTopics = (value) => {
        setSearchText(value);
        if (_size(value) > 2) {
            actions.getStudentDetails(match.params.studentId, value,
                commonData, { ...currentpagination, offset: 1 }, 'searchTopicByNameStudentDetailView', true);
        } else if ((_size(value) === 2 && _size(value) < _size(topicSearchedText))) {
            actions.getStudentDetails(match.params.studentId, '', commonData, { ...currentpagination, offset: 1 }, null, true);
        } else if (_size(value) === 0) {
            actions.getStudentDetails(match.params.studentId, '', commonData, { ...currentpagination, offset: 1 }, null, true);
        }
        setTopicSearchedText(value);
    };

    const fetchData = useCallback(({ pageSize, pageIndex }) => {
        setCurrentpagination({
            offset: pageIndex + 1, limit: pageSize
        });
        actions.getStudentDetails && actions.getStudentDetails(
            match.params.studentId,
            searchText, commonData, {
                offset: pageIndex + 1, limit: pageSize
            }, null, true
        );
    }, [match.params.studentId, searchText]);

    const onStudentSearch = (value, isClear = false) => {
        if (_size(value) >= 3) {
            setStudentSearchText(value);
            const payLoad = { lrSearchKeyword: value, GAtype: 'searchStudentsInStudentDetailview' };
            actions.updateSearchDropDown && actions.updateSearchDropDown(payLoad);
        } else {
            setStudentSearchText(value);
        }

        if (isClear) {
            sendGTM({
                eventName: 'searchTextCleared',
                eventDesc: 'search button clicked in student detailview',
                eventLabel: 'search button clicked in student detailview',
                page: GAprops.studentDetailsPage
            });
        }
    };

    const onSearchResultClick = (studentItem) => {
        history.push(`/student-details/${studentItem.id}?env=${configs.ENV}`);
    };

    const onSearchSubmit = (value) => {
        const payLoad = { lrSearchKeyword: value, GAtype: 'searchAndEnterStudentDetailview' };
        actions.updateSearchDropDown && actions.updateSearchDropDown(payLoad);
        actions.updateSearchText(value);
        redirectToStudentList(null);
    };

    const redirectToStudentList = (e, triggerNameClick = false) => {
        e && e.stopPropagation();
        if (triggerNameClick) {
            if (document.body.firstChild.className === 'notification message-open') {
                document.body.firstChild.classList.remove('message-open');
            }
        }
        history.push(`/student-list?env=${configs.ENV}`);
    };

    const renderTopic = item => {
        if (_size(item.searchText) < 3) {
            return <span className='topic-grid-title' title={item.value}>{item.value}</span>;
        }

        const repSearchText = item.searchText.replace(/[.*+\-?^${}()|[\]\\]/g, '');
        const parts = item.value.split(new RegExp(`(${repSearchText})`, 'gi'));
        let topicCounter = 1;
        return (
            <span className='topic-grid-title' title={item.value}>
                {parts.map((part, i) => {
                    topicCounter += i;
                    return (
                        <span key={`part-${topicCounter}`} className={part.toLowerCase() === item.searchText.toLowerCase() ? 'highlight-text' : ''}>
                            {part}
                        </span>
                    );
                })}
            </span>
        );
    };

    const renderKnowledgeScore = ({ knowledgeScoreTopic }) => {
        return getStyledKnowledgeScore(knowledgeScoreTopic, thresholdSettingsData);
    };

    const renderStudentAttempted = ({ studentAttempted }) => {
        let totalAttempts = '--';
        if (studentAttempted && _isNumber(studentAttempted.totalAttempts)) {
            totalAttempts = studentAttempted.totalAttempts;
        }
        return totalAttempts;
    };

    const renderCorrectFirstTry = ({ correctFirstTry }) => {
        let cft = '--';
        if (_isNumber(correctFirstTry)) {
            cft = `${_round(correctFirstTry)}%`;
        }
        return cft;
    };

    const actualData = topicInfo ? topicInfo.topics : [];

    const actualDataCount = pagination && pagination.total;

    const triggerExport = () => {
        actions.exportStudentTopicsData(match.params.studentId, searchText);
    };

    const columns = useMemo(() => [
        { Header: 'Topic', id: 'id', minWidth: 100, width: 150, accessor: 'topic', Cell: renderTopic },
        { Header: 'Knowledge Score', id: 'knowledgeLevel', width: 80, accessor: renderKnowledgeScore, isInfoIcon: true, infoContent: renderKnowledgeScoreInfoContent() },
        { Header: 'Attempted Questions', id: 'countSubmissions', width: 85, accessor: renderStudentAttempted },
        { Header: 'Duration (Time Spent)', id: 'timeOnTask', width: 100, accessor: ({ avgDuration }) => secondsToHms(avgDuration) },
        { Header: 'Correct on First Try', id: 'correctOnFirstAttemptTry', width: 80, accessor: renderCorrectFirstTry, isInfoIcon: false }
    ], []);

    const defaultTableOpts = {
        disableSortBy: false,
        disableMultiSort: true,
        manualSortBy: true,
        disableSortRemove: true,
        autoResetSortBy: false,
        manualPagination: true,
        autoResetPage: false,
        currentpageIndex: pagination.offset - 1,
        pageCount: getPageCount(pagination),
        initialState: {
            sortBy: [{ id: 'id', desc: false }]
        }
    };

    const cellRenderProps = {
        searchText
    };

    const onSortTopics = (value) => {
        actions.setStudentsTopicsSortBy && actions.setStudentsTopicsSortBy(value);
        if (!_isEqual(sortBy[0], value[0])) {
            actions?.getStudentDetails(match.params.studentId, searchText, commonData,
                { ...currentpagination, offset: 1 }, null, true);
            sendGTM({
                eventName: 'topicsGridSortStudentDetailView',
                eventDesc: 'Topics grid sorted',
                eventLabel: `field: ${value[0].id}, sortDirection: ${value[0].desc ? 'DESC' : 'ASC'} }`,
                page: GAprops.studentDetailsPage
            });
        }
    };

    const setStudentEmailObj = (studentDet) => {
        const maillers = [];
        maillers.push({
            fullName: `${studentDet.lastName}, ${studentDet.firstName}`,
            lastName: studentDet.lastName,
            firstName: studentDet.firstName,
            email: studentDet.email[0],
            studentId: match.params.studentId
        });
        setEmailObj(getEmailProps(maillers, commonData, 'Student detailview'));
    };

    const onUpdateFilter = (filterData, isFiltered) => {
        sendGTM({
            eventName: 'topicGroupingsStudentDetailView',
            eventDesc: 'Filter topics by groupings',
            eventLabel: `${filterData[0]?.title}`,
            page: GAprops.studentDetailsPage
        });

        actions.updateSelectedStuDetailFilters(isFiltered ? [] : filterData);
        actions.getStudentDetails && actions.getStudentDetails(
            match.params.studentId,
            searchText, commonData, { offset: 1, limit: currentpagination.limit }
        );
    };

    const noDataMessage = <div className='no-data-message'>There are no topics found for current search.</div>;

    const retryClick = (e) => {
        e.preventDefault();
        setRetryClickCount(retryClickCount + 1);
        triggerAPICall();
    };

    const showErrorMessage = () => {
        const retryMessage = retryClickCount < RETRY_LIMIT
            ? 'Failed to load student name.'
            : 'Please refresh the app as retry didn\'t work.';
        return (
            <div className='retry-container'>
                <div className='retry-inner-container'>
                    <div className='ca-error-info-icon'>!</div>
                    <p className='retry-text'>{retryMessage}</p>
                </div>
                {retryClickCount < RETRY_LIMIT && <button className='retry-button' type='button' onClick={(e) => retryClick(e)}>Retry</button>}
            </div>
        );
    };

    const renderStudentDetails = () => {
        const emailTooltip = <Tooltip id='email-button' className='common-tooltip'>Email Student</Tooltip>;
        const tooltipExport = <Tooltip id='topics-setting' className='common-tooltip'>Export Student Topics</Tooltip>;
        let knwScr = null;
        let classScr = null;
        if (studentInfo) {
            if (_isNumber(studentInfo.knowledgeScore)) {
                knwScr = _round(studentInfo.knowledgeScore);
            }
            if (_isNumber(studentInfo.classKnowledgeScore)) {
                classScr = _round(studentInfo.classKnowledgeScore * 100);
            }
        }
        const listGridCls = ['topics-list-grid'];
        if (isGridLoading) {
            listGridCls.push('visibility-hidden');
        }

        return (
            <>
                <div className='student-details-info'>
                    <Row>
                        <Col sm={8}>
                            {studentInfo && _size(studentInfo.errors) > 0
                            && ['408'].includes(studentInfo.errors[0].errorCode)
                            && showErrorMessage()}
                            {studentInfo && _size(studentInfo.errors) === 0
                                && <h3>{studentInfo.lastName}, {studentInfo.firstName}</h3>}
                            {parentAppData?.courseInfo?.name
                                && <p className='course-info'>{parentAppData.courseInfo.name}</p>}
                            {studentInfo && _size(studentInfo.tags) > 0 && (
                                <div className='student-tag-list'>
                                    <span className='student-detail-tag-icon'>
                                        <img className='icon' src={TagIcon} alt='tag-icon' />
                                    </span>
                                    <span>
                                        {studentInfo.tags.join(', ')}
                                    </span>
                                </div>
                            )}
                        </Col>
                        <Col sm={4} className='text-right'>
                            <OverlayTrigger
                                placement='bottom'
                                trigger={['hover', 'focus']}
                                overlay={emailTooltip}
                            >
                                <button
                                    type='button'
                                    className={`btn-email ${studentInfo && studentInfo.email && _size(studentInfo.email) === 0 ? 'disabled-email-btn' : ''}`}
                                    aria-label='Email Student'
                                    data-target='#compose_email'
                                    data-toggle='modal'
                                    onClick={() => {
                                        sendGTM({
                                            eventName: 'emailModalOpened',
                                            eventDesc: 'Email modal opened',
                                            eventLabel: 'Email modal opened',
                                            page: GAprops.studentDetailsPage
                                        });
                                    }}
                                    title={`${(!_isEmpty(emailObj)) ? emailObj.data.lists.length : '0'} - Student in mailing list`}
                                    disabled={
                                        studentInfo && studentInfo.email
                                        && _size(studentInfo.email) === 0
                                    }
                                >
                                    Email Student
                                </button>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                </div>
                {!noCourseError
                    && (
                        <div className='student-details-performance'>
                            <Row>
                                <Col sm={6}>
                                    <CourseKnowledgeLevel
                                        knowledgeScore={knwScr}
                                        knowledgeTitle='Student Knowledge Level'
                                        thresholdSettingsData={thresholdSettingsData}
                                        classAverage={classScr}
                                    />
                                </Col>
                                <Col sm={6}>
                                    <TopicPerformance
                                        perfData={topicInfo && topicInfo.topicDistribution}
                                        pageName='student-details'
                                        selectedFilters={selectedFilters}
                                        showError={hasAPIError}
                                        onFilterUpdate={onUpdateFilter}
                                    />
                                </Col>
                            </Row>
                        </div>
                    )}
                {!noCourseError
                    && (
                        <div className='student-details-topics'>
                            <Row className='topics-list-header'>
                                <Col className='topics-list-info' data-testid='student-topic-count'>
                                    <h3>{actualDataCount} {(actualDataCount === 1) ? 'topic' : 'topics'}</h3>
                                </Col>
                                <Col className='topics-list-actions text-right'>
                                    <CustomSearch
                                        searchText={searchText}
                                        placeholder='Search Student Topics'
                                        onSearch={(value, isClear = false) => {
                                            if (isClear) {
                                                sendGTM({
                                                    eventName: 'topicsSearchClearStudentDetailView',
                                                    eventDesc: 'Topics search text cleared',
                                                    eventLabel: 'Topics search text cleared',
                                                    page: GAprops.studentDetailsPage
                                                });
                                            }
                                            onSearchTopics(value);
                                        }}
                                    />
                                    <OverlayTrigger placement='bottom' trigger={['hover', 'focus']} overlay={tooltipExport} htmlFor='btn-export'>
                                        <button
                                            type='button'
                                            tabIndex='0'
                                            className='btn-export'
                                            onClick={() => triggerExport()}
                                            data-testid='export-btn'
                                            aria-label='Export Student Topics'
                                            disabled={actualDataCount === 0}
                                        />
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                            {isGridLoading && (
                                <div className='shadow-container'>
                                    <ShadowTableGrid />
                                </div>
                            )}
                            <div className={listGridCls.join(' ')}>
                                {!hasSearchAPIError && (
                                    <CustomTable
                                        data={actualData}
                                        columns={columns}
                                        defaultTableOpts={defaultTableOpts}
                                        onSort={sortData => onSortTopics(sortData.sortBy)}
                                        cellRenderProps={cellRenderProps}
                                        fetchData={fetchData}
                                        totalRowCount={actualDataCount}
                                        onPaginationChange={
                                            (selectedPage, selectedPageType, pageSize) => {
                                                sendGTM({
                                                    eventName: 'topicsPageChangeStudentDetailView',
                                                    eventDesc: 'Page Change',
                                                    eventLabel: `currentPageNo: ${selectedPage},  recordsPerPage:  ${pageSize}}, button: ${selectedPageType}`,
                                                    page: GAprops.studentDetailsPage
                                                });
                                            }
                                        }
                                        updatePaginationIndex={(_pageIndex,
                                            _selectedRowIds,
                                            currentPage,
                                            numberofRecords) => {
                                            sendGTM({
                                                eventName: 'topicsRecordsPerPageChangeStudentDetailVew',
                                                eventDesc: 'Records Per Page List Change',
                                                eventLabel: `currentPageNo: ${currentPage},  recordsPerPage:  ${numberofRecords}`,
                                                page: GAprops.studentDetailsPage
                                            });
                                        }}
                                        onHeaderInfoClick={() => {
                                            sendGTM({
                                                eventName: 'topicsgridinfoIconClickStudentDetailview',
                                                eventDesc: 'Topics grid header info icon click',
                                                eventLabel: 'Topics grid header info icon click',
                                                page: GAprops.studentDetailsPage
                                            });
                                        }}
                                    />
                                )}
                                {hasSearchAPIError && renderErrorComp()}
                                {(actualDataCount === 0 && !hasSearchAPIError) && noDataMessage}
                            </div>
                        </div>
                    )}
                {noCourseError && <NoCourseError />}
            </>
        );
    };

    const backTooltip = <Tooltip id='back-button' className='common-tooltip'>Back Button</Tooltip>;
    const actionsCls = ['student-details-actions'];
    if (!_isEmpty(userMessage)) {
        actionsCls.push('has-alert');
    }
    const isLoading = isPageLoading === 'true';

    return (
        <div className='student-details-container'>
            <div className={actionsCls.join(' ')}>
                <OverlayTrigger
                    placement='bottom'
                    trigger={['hover', 'focus']}
                    overlay={backTooltip}
                >
                    <button
                        type='button'
                        className='btn-back'
                        aria-label='Back Button'
                        onClick={(e) => {
                            sendGTM({
                                eventName: 'backButtonClicked',
                                eventDesc: 'Back button clicked in student detailview',
                                eventLabel: 'Back button clicked in student detailview',
                                page: GAprops.studentDetailsPage
                            });
                            redirectToStudentList(e);
                        }}
                        data-testid='back-button'
                    >
                        <img src={BackIcon} alt='back-icon' width='20' height='20' />
                    </button>
                </OverlayTrigger>
                <div className='float-right'>
                    <CustomSearch
                        searchText={studentSearchText}
                        placeholder='Search Students'
                        onSearch={onStudentSearch}
                        showError={hasSearchAPIErrorStuList}
                        showLoader={hasSearchLoader === 'true'}
                        isSearchDropdownEnabled
                        updateSearchDropDown={searchDropDownList}
                        onSearchResultClick={onSearchResultClick}
                        onSearchSubmit={onSearchSubmit}
                        onSearchInputOpen={(isOpen) => {
                            if (isOpen) {
                                sendGTM({
                                    eventName: 'searchBoxOpened',
                                    eventDesc: 'search button clicked in student detailview',
                                    eventLabel: 'search button clicked in student detailview',
                                    page: GAprops.studentDetailsPage
                                });
                            }
                        }}
                    />
                </div>
            </div>
            <div className='student-details-content'>
                {isLoading && <Shadow pageName='student-details' />}
                <div className={!isLoading ? '' : 'visibility-hidden'}>
                    {renderStudentDetails()}
                </div>
            </div>
            {emailObj && renderEmailComponent(emailObj)}
        </div>
    );
};

StudentDetails.propTypes = {
    actions: PropTypes.object,
    match: PropTypes.object,
    studentData: PropTypes.object,
    searchDropDownList: PropTypes.array,
    isPageLoading: PropTypes.string,
    isGridLoading: PropTypes.bool,
    commonData: PropTypes.object,
    hasSearchLoader: PropTypes.string,
    hasSearchAPIErrorStuList: PropTypes.bool
};

StudentDetails.defaultProps = {
    actions: {},
    match: {},
    studentData: {},
    searchDropDownList: [],
    commonData: {},
    hasSearchAPIErrorStuList: false,
    hasSearchLoader: 'true',
    isPageLoading: 'true',
    isGridLoading: false
};

const mapStateToProps = (state) => ({
    studentData: state.studentDetailsReducer,
    searchDropDownList: state.studentListReducer.searchDropDownList,
    commonData: state.commonReducer,
    hasSearchAPIErrorStuList: state.studentListReducer.hasSearchAPIError,
    hasSearchLoader: state.studentListReducer.isPageLoading,
    isPageLoading: state.studentDetailsReducer.isPageLoading,
    isGridLoading: state.studentDetailsReducer.isGridLoading
});

export const TempComp = StudentDetails;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StudentDetails);
