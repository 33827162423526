import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import MultiSlider from './multi-slider';

export default class SliderContent extends React.Component {
    constructor(props) {
        super(props);
        this.minValue = this.props.minValue == null ? 0 : this.props.minValue;
        this.maxValue = this.props.maxValue == null ? 100 : this.props.maxValue;
        this.state = {
            displayMinValue: this.minValue,
            displayMaxValue: this.maxValue,
            minValue: this.props.minValue == null ? 0 : this.props.minValue,
            maxValue: this.props.maxValue == null ? 100 : this.props.maxValue,
        };
    }

    static getDerivedStateFromProps = (nextProps, state) => {
        if (nextProps.maxValue !== state.maxValue || nextProps.minValue !== state.minValue) {
            const minValue = nextProps.minValue == null ? 0 : nextProps.minValue;
            const maxValue = nextProps.maxValue == null ? 100 : nextProps.maxValue;
            return {
                ...state,
                displayMinValue: minValue,
                displayMaxValue: maxValue,
                minValue,
                maxValue,
            };
        }
        return null;
    };

    onChange = (values) => {
        this.props.onChange && this.props.onChange(values[0], 100 - values[2]);
    };

    removeMinValuePercentage = () => {
        this.setState((prevState) => {
            return { displayMinValue: prevState.minValue };
        });
    };

    removeMaxValuePercentage = () => {
        this.setState((prevState) => {
            return { displayMaxValue: prevState.maxValue };
        });
    };

    addMinValuePercentage = (e) => {
        const minValue = !parseInt(e.target.value, 10)
            ? 0
            : parseInt(e.target.value, 10);
        this.props.onChange && this.props.onChange(minValue, this.state.maxValue);
        this.removeMinValuePercentage();
    };

    addMaxValuePercentage = (e) => {
        // istanbul ignore else
        if (e.target.value >= this.state.minValue && e.target.value <= 100) {
            const maxValue = !parseInt(e.target.value, 10)
                ? this.maxValue
                : parseInt(e.target.value, 10);
            this.props.onChange && this.props.onChange(this.state.minValue, maxValue);
            this.removeMaxValuePercentage();
        } else {
            this.props.onChange && this.props.onChange(this.state.minValue, this.state.maxValue);
        }
    };

    onMinValueChange = (e) => {
        if (e.target.value <= this.state.maxValue && e.target.value <= 100) {
            this.setState({
                displayMinValue: !parseInt(e.target.value, 10)
                    ? 0
                    : parseInt(e.target.value, 10),
            });
        }
    };

    onMaxValueChange = (e) => {
        if (e.target.value <= 100) {
            this.setState({
                displayMaxValue: !parseInt(e.target.value, 10)
                    ? 0
                    : parseInt(e.target.value, 10),
            });
        }
    };

    handleMinKeyPress = (e) => {
        const key = e.which || e.keyCode || 0;
        if (key === 9) {
            this.maxValueInput.focus();
        }
        if (key === 13) {
            this.minValueInput.blur();
        }
    };

    handleMaxKeyPress = (e) => {
        const key = e.which || e.keyCode || 0;
        if (key === 13) {
            this.maxValueInput.blur();
        }
    };

    handleMinKeyDown = (e) => {
        const key = e.which || e.keyCode || 0;
        // istanbul ignore else
        if (key === 38) {
            // istanbul ignore else
            if (this.state.minValue + 1 <= this.maxValue) {
                this.props.onChange
                    && this.props.onChange(this.state.minValue + 1, this.state.maxValue);
            }
        }
        if (key === 40) {
            if (this.state.minValue - 1 >= 0) {
                this.props.onChange
                    && this.props.onChange(this.state.minValue - 1, this.state.maxValue);
            }
        }
    };

    handleMaxKeyDown = (e) => {
        const key = e.which || e.keyCode || 0;
        // istanbul ignore else
        if (key === 38) {
            // istanbul ignore else
            if (this.state.maxValue + 1 <= 100) {
                this.props.onChange
                    && this.props.onChange(this.state.minValue, this.state.maxValue + 1);
            }
        }
        // istanbul ignore else
        if (key === 40) {
            // istanbul ignore else
            if (this.state.maxValue - 1 >= this.state.minValue) {
                this.props.onChange
                    && this.props.onChange(this.state.minValue, this.state.maxValue - 1);
            }
        }
    };

    render() {
        const colors = ['#d9d9d9', '#047a9c', '#d9d9d9'];
        const values = [
            this.state.minValue,
            this.state.maxValue - this.state.minValue,
            100 - this.state.maxValue,
        ];
        const { setDefaultMinValue, setDefaultMaxValue } = this.props;
        return (
            <Row className='show-grid'>
                <Col xs={10} sm={2}>
                    <div className='SliderText'>
                        <div className={`high-swarm ${this.props.bullet}`} />
                        {this.props.text}
                    </div>
                </Col>
                <Col xs={6} sm={7}>
                    <div className='Slider center-block'>
                        <MultiSlider
                            colors={colors}
                            values={values}
                            onChange={(v) => this.onChange(v)}
                            setDefaultMinValue={setDefaultMinValue}
                            setDefaultMaxValue={setDefaultMaxValue}
                        />
                    </div>
                </Col>
                <Col xs={10} sm={3} className='pull-right-lg text-box-col'>
                    <div className='pull-left-sm'>
                        <input
                            type='text'
                            size={1}
                            ref={(v) => {
                                this.minValueInput = v;
                            }}
                            className='slider-number-field'
                            value={this.state.displayMinValue}
                            disabled={this.props.setDefaultMinValue}
                            onFocus={this.removeMinValuePercentage}
                            onBlur={(e) => this.addMinValuePercentage(e)}
                            onChange={(e) => this.onMinValueChange(e)}
                            onKeyPress={(e) => {
                                this.handleMinKeyPress(e);
                            }}
                            onKeyDown={(e) => {
                                this.handleMinKeyDown(e);
                            }}
                            data-testid={`min_${this.props.identifier}`}
                        />
                        {' to '}
                        <input
                            type='text'
                            size={1}
                            ref={(v) => {
                                this.maxValueInput = v;
                            }}
                            className='slider-number-field'
                            value={this.state.displayMaxValue}
                            disabled={this.props.setDefaultMaxValue}
                            onFocus={this.removeMaxValuePercentage}
                            onBlur={(e) => this.addMaxValuePercentage(e)}
                            onChange={(e) => this.onMaxValueChange(e)}
                            onKeyPress={(e) => {
                                this.handleMaxKeyPress(e);
                            }}
                            onKeyDown={(e) => {
                                this.handleMaxKeyDown(e);
                            }}
                            data-testid={`max_${this.props.identifier}`}
                        />
                    </div>
                </Col>
            </Row>
        );
    }
}

SliderContent.propTypes = {
    onChange: PropTypes.func,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    bullet: PropTypes.string,
    text: PropTypes.string,
    setDefaultMinValue: PropTypes.bool,
    setDefaultMaxValue: PropTypes.bool,
    identifier: PropTypes.string
};

SliderContent.defaultProps = {
    onChange: null,
    minValue: 0,
    maxValue: 100,
    bullet: '',
    text: '',
    setDefaultMinValue: false,
    setDefaultMaxValue: false,
    identifier: ''
};
