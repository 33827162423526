import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { saveDataInLocalStorage } from '../../common/localstorageHelper';
import CoachMarkDashboardImg from '../../images/dashboard-graph.svg';
import './style.scss';

const InitialCoachMark = (props) => {
    const { showInitalCoachMarkModal,
        setShowInitalCoachMarkModal,
        setcurrentCoachMark,
        coachMarkMounting, coachMarkOrder } = props;

    return (
        <Modal size='sm' className='coach-mark-modal' backdrop='static' centered show={showInitalCoachMarkModal} onHide={() => setShowInitalCoachMarkModal(false)}>
            <Modal.Body className='d-flex flex-column align-items-center'>
                <div>
                    <img src={CoachMarkDashboardImg} alt='Coach Mark' />
                </div>

                <div>
                    <p className='coach-mark-heading'>We are trying something new!</p>
                </div>

                <div className='coach-mark-content'>
                    <p>
                        This is our experimental area, where we test new ideas and concepts.
                    </p>
                    <p>
                        It&apos;s important to note that this area does not currently
                        contain your data, but an example of
                        what data might look like for you.
                    </p>
                    <p>
                        Please explore and give us your feedback through
                        the survey at the top of the page.
                    </p>

                    <p>
                        <span>What&apos;s New! </span>
                        <ul>
                            <li>
                                Deeper insight at the Content
                                Topics/Learning Objectives level.
                            </li>
                            <li>
                                Easily see what topics students are struggling with across
                                your course and at the individual student level.
                            </li>
                        </ul>
                    </p>
                </div>

                <Modal.Footer>
                    <button
                        type='button'
                        data-testid='modalclose'
                        onClick={() => {
                            setShowInitalCoachMarkModal(false);
                            saveDataInLocalStorage('topicsCoachMark', ['initial']);
                            setcurrentCoachMark(coachMarkOrder[1]);

                            setTimeout(() => {
                                coachMarkMounting(coachMarkOrder[1]);
                            }, 500);
                        }}
                    >
                        Take a Look!
                    </button>
                </Modal.Footer>
            </Modal.Body>
        </Modal>
    );
};

InitialCoachMark.propTypes = {
    showInitalCoachMarkModal: PropTypes.bool,
    setShowInitalCoachMarkModal: PropTypes.func,
    setcurrentCoachMark: PropTypes.func,
    coachMarkMounting: PropTypes.func,
    coachMarkOrder: PropTypes.array
};

InitialCoachMark.defaultProps = {
    showInitalCoachMarkModal: false,
    setShowInitalCoachMarkModal: null,
    setcurrentCoachMark: null,
    coachMarkMounting: null,
    coachMarkOrder: []
};

export default InitialCoachMark;
