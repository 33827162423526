import React from 'react';
import PropTypes from 'prop-types';
import _omit from 'lodash/omit';

import './custom-tooltip.scss';

const CustomTooltip = (props) => {
    const parentClsName = ['vz-custom-tooltip-container'];
    if (props.parentClassName) {
        parentClsName.push(props.parentClassName);
    }
    const otherProps = _omit(props, ['placement', 'parentClassName', 'children', 'tooltipContent', 'ariaDescribedBy']);
    const contentProps = {};
    if (props.ariaDescribedBy) {
        contentProps.id = props.ariaDescribedBy;
        contentProps['data-testid'] = `tooltip_${props.ariaDescribedBy}`;
        otherProps['aria-describedby'] = props.ariaDescribedBy;
        otherProps['data-testid'] = `tooltipicon_${props.ariaDescribedBy}`;
    }

    if (props.tooltipContent) {
        return (
            <div className={parentClsName.join(' ')} {...otherProps}>
                {props.children}
                <div className={['vz-custom-tooltip', props.placement].join(' ')}>
                    <div className='vz-custom-tooltip-arrow' />
                    <div className='vz-custom-tooltip-content' {...contentProps}>{props.tooltipContent}</div>
                </div>
            </div>
        );
    }

    return (
        <div className={parentClsName.join(' ')} {...otherProps}>
            {props.children}
            <div className={['vz-custom-tooltip', props.placement].join(' ')}> </div>
        </div>
    );
};

CustomTooltip.propTypes = {
    placement: PropTypes.string,
    parentClassName: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.element
    ]).isRequired,
    tooltipContent: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.element
    ]).isRequired,
    ariaDescribedBy: PropTypes.string
};

CustomTooltip.defaultProps = {
    placement: 'bottom',
    parentClassName: '',
    ariaDescribedBy: null
};

export default CustomTooltip;
