import _isEmpty from 'lodash/isEmpty';

import { formatCourseStudentId } from './utils';

export const thresholdSettingsSchema = (data) => {
    const reqData = {};
    reqData.datum = {
        thresholds: [
            {
                name: 'Low',
                range: {
                    lower: {
                        limit: 0,
                        inclusive: true,
                    },
                    upper: {
                        limit: data.BelowAverage.maxValue,
                        inclusive: true,
                    },
                },
                sortOrder: 1,
            },
            {
                name: 'Medium',
                range: {
                    lower: {
                        limit: data.Average.minValue,
                        inclusive: false,
                    },
                    upper: {
                        limit: data.Average.maxValue,
                        inclusive: true,
                    },
                },
                sortOrder: 2,
            },
            {
                name: 'High',
                range: {
                    lower: {
                        limit: data.AboveAverage.minValue,
                        inclusive: false,
                    },
                    upper: {
                        limit: 100,
                        inclusive: true,
                    },
                },
                sortOrder: 3,
            },
        ],
    };
    return reqData.datum;
};

export const studentDetailsQuery = (
    stuId, commonData, searchTect, options, distributionThresholds
) => {
    const {
        configs
    } = commonData;
    const studentId = formatCourseStudentId(configs, stuId, 'student');
    return {
        query: `query singleStudentTopicListAndDistribution(
            $context: Context!
            $filter: FilterOptions
            $studentId: ID!
            $sort: [SortByFieldInput]
            $pagination: PaginationInput
            $distribution: [DistributionInput!]!
            $distributionLoaded: Boolean!
            $filterApplied: Boolean!
            $studentDistroNotLoadedAndFilterApplied: Boolean!
            ){
            studentLearningResourceKnowledgeInfoList( 
            context: $context,
            filter: $filter,
            learningResourceType: TOPIC,
            studentId: $studentId    
            )
            {
                studentLearningResourceKnowledgeInfoList(sort: $sort pagination: $pagination){
                    student{
                        id
                        firstName
                        lastName
                        emails
                        tags{
                            studentTagId
                            studentTagName
                        }
                    }
                    learningResource{
                        ...LRParts
                            
                    }
                    knowledgeInfo{
                        knowledgeLevel
                        correctOnFirstAttemptTry
                        timeOnTask
                        countSubmissions
                    }
                   
                }
                   learningResourceDistribution(thresholds: $distribution)
                   @skip(if: $filterApplied)
                   {
                        upperLimit{
                            value
                            inclusive
                        }
                      lowerLimit{
                            value
                            inclusive
                        }
                      count
                      name
                      percentage
                }
                totalStudents
                 pagination 
                  {
                    total
                    offset
                    limit
                  }
                  errors{
                    errorCode
                    errorMessage
                  }
            }
            learningResourceKnowledgeInfoList(
                context: $context,
                filter: $filter
                learningResourceType:TOPIC
            ) @skip(if: $distributionLoaded) {
               learningResourceDistribution (thresholds: $distribution)
                  {
                        upperLimit{
                            value
                            inclusive
                        }
                      lowerLimit{
                            value
                            inclusive
                        }
                      count
                      name
                      percentage
                }
                  totalStudents
                  pagination
                  {
                    total
                    offset
                    limit
                  }
                  errors{
                    errorCode
                    errorMessage
                  } 
            }
            distro: studentLearningResourceKnowledgeInfoList(
                context: $context,
                learningResourceType: TOPIC,
                studentId: $studentId    
            ) @include(if: $studentDistroNotLoadedAndFilterApplied){
                learningResourceDistribution(thresholds: $distribution){
                        upperLimit{
                            value
                            inclusive
                        }
                      lowerLimit{
                            value
                            inclusive
                        }
                      count
                      name
                      percentage
                }
                
                  errors{
                    errorCode
                    errorMessage
                  }
            }
        }
        fragment LRParts on LearningResource {
          id
          label
          type
        }`,
        variables: {
            filter: options.filter,
            context: {
                course: configs.COURSE_IDS,
                platformId: configs.LPID,
                subplatformId: configs.LPSID
            },
            studentId,
            distribution: distributionThresholds,
            sort: options.sortBy,
            pagination: options.pagination,
            distributionLoaded: true,
            studentDistroNotLoadedAndFilterApplied: false,
            filterApplied: options.filterApplied
        }
    };
};

export const courseKnowledgeLevelQuery = (filterData, configs) => {
    const catagoryArray = ['aboveAverage', 'average', 'belowAverage', 'noAttempts'];
    const selectedFilters = sessionStorage.getItem('selectedFilters');
    let filterArray = [];
    if (!_isEmpty(filterData)) {
        const topicsFilter = (selectedFilters
            && !_isEmpty(JSON.parse(selectedFilters).topicsFilter))
            ? JSON.parse(selectedFilters).topicsFilter : null;

        const topicsFilterArray = topicsFilter && topicsFilter.filter((item) => {
            return catagoryArray.indexOf(item) <= -1;
        });

        topicsFilterArray && topicsFilterArray.forEach((item) => {
            let filterItem = {};
            if (item.indexOf('chapter') > -1) {
                filterItem = { id: item, type: 'CHAPTER' };
            } else if (item.indexOf('section') > -1) {
                filterItem = { id: item, type: 'SECTION' };
            } else if (item.indexOf('topic') > -1) {
                filterItem = { id: item, type: 'TOPIC' };
            }
            filterArray.push(filterItem);
        });
    }

    filterArray = !_isEmpty(filterArray) ? filterArray : null;
    return {
        query: `query getCourseSummaryKL($context: Context! $filters: FilterOptions)  { 
                   courseSummary(context: $context
                     filter:$filters                
                    
        )
      {
      knowledgeInfo {
          knowledgeLevel
          }
         }
      }`,
        variables: {
            filters: { learningResources: filterArray },
            context: {
                course: configs.COURSE_IDS,
                platformId: configs.LPID,
                subplatformId: configs.LPSID
            }
        }
    };
};

export const filterSchema = (configs) => {
    return {
        operationName: 'titleHierarchyForFilters',
        query: 'query titleHierarchyForFilters($context: Context!) { getCourseDetails(context: $context) { books: learningResource { ...LRParts chapters: children { ...LRParts sections: children { ...LRParts topics: children { ...LRParts } } } } studentTags { studentTagId studentTagName courseSectionId } } } fragment LRParts on LearningResource { id title: label type }',
        variables: {
            context: {
                course: configs.COURSE_IDS,
                platformId: configs.LPID,
                subplatformId: configs.LPSID
            }
        }
    };
};

export const topicListSchema = (commonData, options, distributionThresholds) => {
    const {
        configs
    } = commonData;
    return {
        operationName: 'learningResourceKnowledgeInfoList',
        query: 'query learningResourceKnowledgeInfoList($context: Context! $filter: FilterOptions $sort: [SortByFieldInput] $pagination: PaginationInput $distributionThresholds: [DistributionInput!]! $withSearchOrThreshold: Boolean!) { learningResourceKnowledgeInfoList(context: $context, filter: $filter learningResourceType:TOPIC) { courses { id courseTitle code platformId subplatformId } learningResourceKnowledgeInfoList(sort: $sort pagination: $pagination) { learningResource { id type label } knowledgeInfo { knowledgeLevel countSubmissions correctOnFirstAttemptTry correctOnLastAttemptTry timeOnTask } studentDistribution(thresholds: $distributionThresholds) { upperLimit { value inclusive } lowerLimit { value inclusive } count name percentage } totalStudents } learningResourceDistribution (thresholds: $distributionThresholds) @skip(if: $withSearchOrThreshold) { upperLimit { value inclusive } lowerLimit { value inclusive } count name percentage } totalStudents pagination { total offset limit } errors { errorCode errorMessage } } learningResourceKnowledgeInfoList(context: $context, filter: $filter learningResourceType:TOPIC) @include(if: $withSearchOrThreshold) { learningResourceDistribution (thresholds: $distributionThresholds) { upperLimit { value inclusive } lowerLimit { value inclusive } count name percentage } } }',
        variables: {
            context: {
                course: configs.COURSE_IDS,
                platformId: configs.LPID,
                subplatformId: configs.LPSID
            },
            filter: options.filter,
            distributionThresholds,
            sort: options.sortBy,
            pagination: options.pagination,
            withSearchOrThreshold: options.withSearchOrThreshold
        }
    };
};

const barChartDistribution = Array.from(Array(20).keys()).map((itm) => {
    return {
        lowerLimit: {
            inclusive: false,
            value: itm * 5
        },
        name: `LEVEL${itm + 1}`,
        upperLimit: {
            inclusive: true,
            value: (itm + 1) * 5
        }
    };
});

export const exportTopicsSchema = (commonData, options, distributionThresholds) => {
    const {
        configs
    } = commonData;
    return {
        query: `query exportTopicsList (
            $context: Context!
            $filter: FilterOptions
            $sort: [SortByFieldInput]
            $distributionThresholds: [DistributionInput!]!
        ){ 
            exportTopicsList( 
            context: $context,
            filter: $filter
            learningResourceType:TOPIC
            sort: $sort
            thresholds: $distributionThresholds
            )
            {
        fileName
        errors
         
        { errorCode errorMessage }
         
        }
        }`,
        variables: {
            context: {
                course: configs.COURSE_IDS,
                platformId: configs.LPID,
                subplatformId: configs.LPSID
            },
            filter: options.filter,
            distributionThresholds,
            sort: options.sortBy
        }
    };
};

export const studentListSchema = (configs, studentPayload) => {
    const sort = studentPayload?.sort ? studentPayload.sort : [{ fieldName: 'student', sortOrder: 'DESC' }];
    const pagination = studentPayload?.pagination ? studentPayload.pagination : {
        offset: 1,
        limit: 10
    };
    const filter = studentPayload?.filter ? studentPayload.filter : null;
    const chartData = [
        {
            name: 'LEVEL',
            lowerLimit: { value: null, inclusive: true },
            upperLimit: { value: null, inclusive: true }
        },
        ...barChartDistribution
    ];

    return {
        operationName: 'studentCourseKnowledgeInfo',
        query: 'query studentCourseKnowledgeInfo( $context: Context! $filter: FilterOptions $sort: [SortByFieldInput] $pagination: PaginationInput $barChartDistribution: [DistributionInput!]! ){ studentCourseKnowledgeInfo( context: $context, filter: $filter ) { studentCourseKnowledgeInfoList(sort: $sort pagination: $pagination){ student{ id firstName lastName emails tags{ studentTagId studentTagName } } knowledgeInfo{ knowledgeLevel correctOnFirstAttemptTry timeOnTask } } studentDistribution(thresholds: $barChartDistribution){ upperLimit{ value inclusive } lowerLimit{ value inclusive } count } totalStudents totalNoPiiRecordCount pagination { total offset limit } errors { errorCode errorMessage } } }',
        variables: {
            context: {
                course: configs.COURSE_IDS,
                platformId: configs.LPID,
                subplatformId: configs.LPSID
            },
            filter,
            studentId: null,
            sort,
            pagination,
            barChartDistribution: chartData
        }
    };
};

export const studentListExportSchema = (configs, options) => {
    return {
        operationName: 'exportStudentList',
        query: `query exportStudentList(
                $context: Context!
                $filter: FilterOptions
                $sort: [SortByFieldInput]
                )
                {
                    exportStudentList( 
                        context: $context,
                        filter: $filter
                        sort: $sort
                        )
                        {
            
                    fileName
                    errors {
                        errorCode
                        errorMessage
                    }
                }
            }`,
        variables: {
            context: {
                course: configs.COURSE_IDS,
                platformId: configs.LPID,
                subplatformId: configs.LPSID
            },
            filter: options.filter,
            sort: options.sortBy
        }
    };
};

export const studentTopicsExportSchema = (stuId, configs, options) => {
    const studentId = formatCourseStudentId(configs, stuId, 'student');
    return {
        operationName: 'exportSingleStudent',
        query: `query exportSingleStudentLearningResourseList(
                $context: Context!
                $studentId: ID!
                $filter: FilterOptions
                $sort: [SortByFieldInput]
                )
                {
                    exportSingleStudentLearningResourseList( 
                        context: $context,
                        filter: $filter,
                        learningResourceType: TOPIC,
                        studentId: $studentId
                        sort: $sort
                        )
                        {
            
                    fileName
                    errors {
                        errorCode
                        errorMessage
                    }
                }
            }`,
        variables: {
            context: {
                course: configs.COURSE_IDS,
                platformId: configs.LPID,
                subplatformId: configs.LPSID
            },
            studentId,
            filter: options.filter,
            sort: options.sortBy
        }
    };
};

export const studentDetailsCourseSummaryQuery = (stuId, configs) => {
    const studentId = formatCourseStudentId(configs, stuId, 'student');
    return {
        query: `query getSingleStudentCourseSummary(
            $context: Context! $studentId: ID!
            $sort: [SortByFieldInput]
            $pagination: PaginationInput
            )  { 
            courseSummary(context: $context) {
            knowledgeInfo {
            knowledgeLevel,
            countSubmissions,
            correctOnFirstAttemptTry,
            correctOnLastAttemptTry,
            timeOnTask
            }
           }
           studentCourseKnowledgeInfo( 
            context: $context,
            studentId: $studentId
            ){
               studentCourseKnowledgeInfoList(sort: $sort pagination: $pagination){
                    student{
                        id
                        firstName
                        lastName
                        emails
                        tags{
                            studentTagId
                            studentTagName
                        }
                    }
                    knowledgeInfo{
                        knowledgeLevel
                        correctOnFirstAttemptTry
                        timeOnTask
                    }
                   
                }
                errors{
                    errorCode
                    errorMessage
                }
            }
        }`,
        variables: {
            context: {
                course: configs.COURSE_IDS,
                platformId: configs.LPID,
                subplatformId: configs.LPSID
            },
            studentId,
            sort: null,
            pagination: null
        }
    };
};

const schemaHelper = {
    thresholdSettingsSchema,
    filterSchema,
    courseKnowledgeLevelQuery,
    topicListSchema,
    studentListSchema,
    studentTopicsExportSchema,
    exportTopicsSchema,
    studentListExportSchema,
    studentDetailsQuery,
    studentDetailsCourseSummaryQuery
};

export default schemaHelper;
