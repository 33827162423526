import { useEffect } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const ToastMessage = props => {
    useEffect(() => {
        if (props.showMessage) {
            setTimeout(() => {
                props.handleCloseMessage();
            }, 5000);
        }
    }, [props.showMessage]);

    if (props.showMessage) {
        const clsName = ['notification', 'message-open'];
        if (props.isError) {
            clsName.push('notification-error');
        }
        return (
            <div className={clsName.join(' ')}>
                <button
                    type='button'
                    tabIndex='0'
                    className='close'
                    onClick={props.handleCloseMessage}
                >
                    &times;
                </button>
                <div className='notification-box'>
                    <span className='notification-box-icon'>
                        {props.isError
                        && (
                            <svg focusable='false' className='pe-icon--warning-sm-18'>
                                <use xlinkHref='#warning-sm-18' />
                            </svg>
                        )}
                    </span>
                    <div className='notification-box-content'>{props.message}</div>
                </div>
            </div>
        );
    }

    return null;
};

ToastMessage.propTypes = {
    handleCloseMessage: PropTypes.func,
    message: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    showMessage: PropTypes.bool,
    isError: PropTypes.bool
};

ToastMessage.defaultProps = {
    handleCloseMessage: null,
    message: '',
    showMessage: false,
    isError: false
};

export default ToastMessage;
