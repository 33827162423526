import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';
import _pick from 'lodash/pick';
import _result from 'lodash/result';
import _uniqBy from 'lodash/uniqBy';
import _size from 'lodash/size';

import CustomCheckbox from './custom-checkbox';
import { sendGTM } from '../../common/utils';

class FilterPopover extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedGroup: props.getParentGroup(props.editFilterItem),
            searchText: '',
            selectedFilters: props.selectedFilters,
            editFilterItem: props.editFilterItem
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (!_isEqual(props.editFilterItem, state.editFilterItem)) {
            return {
                selectedGroup: props.getParentGroup(props.editFilterItem),
                editFilterItem: props.editFilterItem
            };
        }

        return null;
    }

    onApplyFilter = (e) => {
        e.stopPropagation();
        const { selectedFilters } = this.state;
        this.props.onFilterUpdate(selectedFilters);
    };

    onClearFilter = (e) => {
        e.stopPropagation();
        const { selectedGroup, selectedFilters } = this.state;
        let filterArr = _cloneDeep(selectedFilters);
        let removeArr = [];
        if (selectedGroup.value) {
            removeArr = selectedGroup.children.map(item => item.id);
        } else if (selectedGroup.type) {
            const sType = selectedGroup.type.toLowerCase();
            if (sType === 'chapter') {
                removeArr = selectedGroup.sections.map(item => item.id);
            } else if (sType === 'section') {
                removeArr = selectedGroup.topics.map(item => item.id);
                filterArr = this.addOrRemoveHandler(selectedGroup, filterArr);
            }
            removeArr.push(selectedGroup.id);
        }

        filterArr = filterArr.filter(fa => !removeArr.includes(fa.id));
        sendGTM({
            eventName: 'clearFilters',
            eventDesc: 'Clear Filters',
            eventLabel: `countFiltersRemoved: ${_size(selectedFilters)}`,
            page: this.props.page
        });
        this.setState({ selectedFilters: filterArr });
    };

    getFilterObj = (item) => {
        return { id: item.id, title: item.title, type: item.type };
    };

    selectTopic = (tempArr, newFilterArr, parentData, isChecked) => {
        let _newFilterArr = _cloneDeep(newFilterArr);
        if (_size(tempArr) === _size(_newFilterArr.filter(fa => tempArr.includes(fa.id)))) {
            _newFilterArr = _newFilterArr.filter(fa => !tempArr.includes(fa.id));
            _newFilterArr = this.addOrRemoveHandler(parentData, _newFilterArr, isChecked);
        }
        return _newFilterArr;
    }

    selectSection = (tempArr, newFilterArr, parentData) => {
        let _newFilterArr = _cloneDeep(newFilterArr);
        if (_size(tempArr) === _size(_newFilterArr.filter(fa => tempArr.includes(fa.id)))) {
            _newFilterArr = _newFilterArr.filter(fa => !tempArr.includes(fa.id));
            _newFilterArr.push(this.getFilterObj(parentData));
        }
        return _newFilterArr;
    }

    deselectTopic = (newFilterArr, parentData, isChecked, item) => {
        let _newFilterArr = _cloneDeep(newFilterArr);
        let updateTopics = false;
        if (_newFilterArr.find(fa => fa.id === parentData.id)) {
            updateTopics = true;
            _newFilterArr = this.addOrRemoveHandler(parentData, _newFilterArr, isChecked);
        } else {
            const chapterData = this.props.getParentGroup(parentData);
            if (_newFilterArr.find(fa => fa.id === chapterData.id)) {
                updateTopics = true;
                _newFilterArr = this.addOrRemoveHandler(parentData, _newFilterArr, isChecked);
            }
        }

        if (updateTopics) {
            parentData.topics.forEach(topic => {
                if (topic.id !== item.id) {
                    _newFilterArr.push(this.getFilterObj(topic));
                }
            });
        }
        return _newFilterArr;
    }

    deselectSection = (newFilterArr, parentData, item) => {
        const _newFilterArr = _cloneDeep(newFilterArr);
        if (_newFilterArr.find(fa => fa.id === parentData.id)) {
            parentData.sections.forEach(sec => {
                if (sec.id !== item.id) {
                    _newFilterArr.push(this.getFilterObj(sec));
                }
            });
        }
        return _newFilterArr;
    }

    // To handle nested levels of chapter and section
    addOrRemoveHandler = (item, filterArr, isChecked = false) => {
        let newFilterArr = _cloneDeep(filterArr);
        const parentData = this.props.getParentGroup(item);
        const sType = item.type.toLowerCase();
        if (isChecked) {
            newFilterArr.push(this.getFilterObj(item));
            let tempArr = null;
            if (sType === 'topic') {
                // On selecting topic, updating sections and chapters
                tempArr = parentData.topics.map(tp => tp.id);
                newFilterArr = this.selectTopic(
                    tempArr,
                    newFilterArr,
                    parentData,
                    isChecked
                );
            } else if (sType === 'section') {
                // On selecting section, updating chapters and topics
                tempArr = parentData.sections.map(sec => sec.id);
                newFilterArr = this.selectSection(
                    tempArr,
                    newFilterArr,
                    parentData
                );
                tempArr = item.topics.map(tp => tp.id);
                newFilterArr = newFilterArr.filter(fa => !tempArr.includes(fa.id));
            } else if (sType === 'chapter') {
                // On selecting chapter, updating sections and topics
                tempArr = this.getTempChapterArr(item);
                newFilterArr = newFilterArr.filter(fa => !tempArr.includes(fa.id));
            }
        } else {
            newFilterArr = newFilterArr.filter(fa => fa.id !== item.id);
            if (sType === 'topic') {
                // On de-selecting topic, updating sections and chapters
                newFilterArr = this.deselectTopic(newFilterArr, parentData, isChecked, item);
            } else if (sType === 'section') {
                // On de-selecting section, updating chapters and topics
                newFilterArr = this.deselectSection(newFilterArr, parentData, item);
                newFilterArr = newFilterArr.filter(fa => fa.id !== parentData.id);
            }
        }
        return newFilterArr;
    };

    checkboxHandler = (e, value) => {
        e.stopPropagation();
        const selectedArr = _cloneDeep(this.state.selectedFilters);
        const filterArr = this.addOrRemoveHandler(value, selectedArr, e.target.checked);
        this.setState({ selectedFilters: _uniqBy(filterArr, 'id') });
    };

    getTempChapterArr = (data) => {
        const tempArr = [];
        data.sections.forEach(sec => {
            tempArr.push(sec.id);
            sec.topics.forEach(tp => tempArr.push(tp.id));
        });
        return tempArr;
    };

    handleChapter = (e, group, tempArr, filterArr, allFilter) => {
        let _tempArr = _cloneDeep(tempArr);
        let _filterArr = _cloneDeep(filterArr);
        group.children.forEach(chap => {
            const defArr = this.getTempChapterArr(chap);
            _tempArr.push(chap.id);
            _tempArr = [..._tempArr, ...defArr];
        });
        _filterArr = _filterArr.filter(value => !_tempArr.includes(value.id));
        if (e.target.checked) {
            allFilter.forEach(value => _filterArr.push(this.getFilterObj(value)));
        }
        return _filterArr;
    }

    handleSection = (e, group, filterArr) => {
        let _filterArr = _cloneDeep(filterArr);
        if (e.target.checked) {
            _filterArr.push(this.getFilterObj(group));
        } else {
            _filterArr = _filterArr.filter(value => value.id !== group.id);
        }
        return _filterArr;
    }

    handleTopic = (e, tempArr, group, allFilter, filterArr, parentData) => {
        let _filterArr = _cloneDeep(filterArr);
        let _tempArr = _cloneDeep(tempArr);
        if (e.target.checked) {
            // Remove topics
            _tempArr = allFilter.map(af => af.id);
            _filterArr = _filterArr.filter(value => !_tempArr.includes(value.id));

            // Add section and check whether all selected and update parent
            _tempArr = parentData.sections.map(pd => pd.id);
            _filterArr.push(this.getFilterObj(group));
            if (_size(_tempArr) === _size(_filterArr.filter(fa => _tempArr.includes(fa.id)))) {
                _filterArr = _filterArr.filter(value => !_tempArr.includes(value.id));
                _filterArr.push(this.getFilterObj(parentData));
            }
        } else {
            let addOtherSec = false;
            _filterArr = _filterArr.filter(value => {
                if (value.id === parentData.id) {
                    addOtherSec = true;
                }
                return (value.id !== group.id) && (value.id !== parentData.id);
            });
            if (addOtherSec) {
                parentData.sections.forEach(sec => {
                    if (sec.id !== group.id) {
                        _filterArr.push(this.getFilterObj(sec));
                    }
                });
            }
        }
        return _filterArr;
    }

    selectAllHandler = (e, allFilter, group) => {
        e.stopPropagation();
        const { selectedFilters } = this.state;
        let filterArr = _cloneDeep(selectedFilters);
        const grpType = _result(group, 'type', '').toLowerCase();
        let tempArr = [];

        if (group.value && (group.value === 'chapter')) {
            // To handle chapter select all
            filterArr = this.handleChapter(e, group, tempArr, filterArr, allFilter);
        } else if (grpType === 'chapter') {
            // To handle section select all
            tempArr = this.getTempChapterArr(group);
            filterArr = filterArr.filter(value => !tempArr.includes(value.id));
            filterArr = this.handleSection(e, group, filterArr);
        } else if (grpType === 'section') {
            const { getParentGroup } = this.props;
            // To handle topic select all
            const parentData = getParentGroup(group);
            filterArr = this.handleTopic(
                e,
                tempArr,
                group,
                allFilter,
                filterArr,
                parentData
            );
        } else if (e.target.checked) {
            // To handle other filter groups select all
            allFilter.forEach(value => filterArr.push(this.getFilterObj(value)));
        } else {
            // To handle other filter groups de-select all
            tempArr = allFilter.map(af => af.id);
            filterArr = filterArr.filter(value => !tempArr.includes(value.id));
        }

        this.setState({ selectedFilters: _uniqBy(filterArr, 'id') });
    };

    handleSearch = (e) => {
        e.stopPropagation();
        this.setState({ searchText: e.target.value });
    };

    handleChange = (e, group, isKeyPress) => {
        e.stopPropagation();
        const stateObj = { searchText: '' };
        // istanbul ignore else
        if (!group || ['studentTags', 'knowledgeScore'].includes(group.type)) {
            const { selectedFilters = {} } = this.props;
            stateObj.selectedFilters = selectedFilters;
        }
        if (isKeyPress) {
            const keyCode = e.which || e.keyCode;
            if ([13, 32].includes(keyCode)) {
                stateObj.selectedGroup = group;
            }
        } else {
            stateObj.selectedGroup = group;
        }

        this.setState(stateObj, () => {
            this.props.popperData && this.props.popperData.scheduleUpdate();
        });
    };

    handleBack = (e, isKeyPress = false, groupInfo = null) => {
        e.stopPropagation();
        let hasTrigger = false;
        const stateObj = { searchText: '' };
        // istanbul ignore else
        if (!groupInfo || ['studentTags', 'knowledgeScore'].includes(groupInfo.type)) {
            const { selectedFilters = {} } = this.props;
            stateObj.selectedFilters = selectedFilters;
        }
        if (isKeyPress) {
            const keyCode = e.which || e.keyCode;
            if ([13, 32].includes(keyCode)) {
                hasTrigger = true;
            }
        } else {
            hasTrigger = true;
        }

        if (hasTrigger) {
            const { getParentGroup } = this.props;
            stateObj.selectedGroup = getParentGroup(groupInfo);
        }

        this.setState(stateObj, () => {
            this.props.popperData && this.props.popperData.scheduleUpdate();
        });
    };

    renderSearchBox = () => {
        const { selectedGroup } = this.state;
        // istanbul ignore else
        if (selectedGroup && selectedGroup.value
            && (['knowledgeScore', 'studentTags'].includes(selectedGroup.value))) {
            return null;
        }

        return (
            <div className='custom-filter-search'>
                <div className='custom-filter-search-icon' />
                <Form.Control
                    custom
                    id='searchTextBox'
                    data-testid='searchTextBox'
                    type='text'
                    placeholder='Search'
                    bsCustomPrefix='custom-filter-search-box'
                    value={this.state.searchText}
                    onChange={(e) => this.handleSearch(e)}
                    autoComplete='off'
                />
            </div>
        );
    };

    renderSelectAll = (actualData, group) => {
        const { selectedFilters } = this.state;
        const formLabel = <span className='cfilter-form-label' title='Select all'>Select all</span>;
        const checkProps = { checked: false };
        const tempArr = actualData.map(af => af.id);
        let selectedCount = 0;
        selectedFilters.forEach(sf => {
            if (tempArr.includes(sf.id)) {
                selectedCount++;
            } else if (group) {
                if (sf.id === group.id) {
                    checkProps.checked = true;
                } else if (group.type && (group.type.toLowerCase() === 'section')) {
                    const parentData = this.props.getParentGroup(group);
                    if (sf.id === parentData.id) {
                        checkProps.checked = true;
                    }
                }
            }
        });
        // istanbul ignore else
        if (selectedCount > 0 && (selectedCount === tempArr.length)) {
            checkProps.checked = true;
        }
        return (
            <div key='selectall' className='custom-filter-popover-item'>
                <Form.Check
                    inline
                    custom
                    type='checkbox'
                    id='selectall'
                    data-testid='selectall'
                    name='selectall'
                    value='selectall'
                    label={formLabel}
                    {...checkProps}
                    onChange={(e) => this.selectAllHandler(e, actualData, group)}
                />
            </div>
        );
    };

    sectionChapter = (sType, group, actualData, groupTitle) => {
        let _actualData = _cloneDeep(actualData);
        let _groupTitle = _cloneDeep(groupTitle);
        // istanbul ignore else
        if (sType === 'chapter') {
            _actualData = group.sections;
            _groupTitle = 'Sections';
        } else if (sType === 'section') {
            _actualData = group.topics;
            _groupTitle = 'Topics';
        }
        return [_actualData, _groupTitle];
    }

    selectFilter = (sf, gItem, group, retVal) => {
        let _retVal = _cloneDeep(retVal);
        if ((sf.id === gItem.id) || (group && (sf.id === group.id))) {
            _retVal = sf;
        } else {
            const parentData = this.props.getParentGroup(group);
            if (parentData && (sf.id === parentData.id)) {
                _retVal = sf;
            }
        }
        return _retVal;
    }

    tempChapterSectionArr = (sType, tempArr, gItem) => {
        let _tempArr = _cloneDeep(tempArr);
        if (sType === 'chapter') {
            _tempArr = this.getTempChapterArr(gItem);
        } else if (sType === 'section') {
            _tempArr = gItem.topics.map(tp => tp.id);
        }
        return _tempArr;
    }

    updateCheckprops = (tempArr, selectedFilters, checkProps) => {
        const _checkProps = checkProps;
        // istanbul ignore else
        if (!_isEmpty(tempArr)) {
            for (let i = 0; i < _size(selectedFilters); i++) {
                if (tempArr.includes(selectedFilters[i].id)) {
                    _checkProps.indeterminate = true;
                    break;
                }
            }
        }
        return _checkProps;
    }

    renderFilterItems = (group) => {
        const { searchText, selectedFilters } = this.state;
        let actualData = [];
        let groupTitle = '';
        const headProps = { className: 'custom-filter-popover-head cursor-pointer', role: 'listitem' };
        if (group.value && ['studentTags', 'knowledgeScore', 'chapter'].includes(group.value)) {
            headProps.onClick = (e) => this.handleBack(e, false);
            headProps.onKeyUp = (e) => this.handleBack(e, true);
            actualData = group.children;
            groupTitle = group.name;
        } else if (group.type) {
            headProps.onClick = (e) => this.handleBack(e, false, _pick(group, ['id', 'type', 'title']));
            headProps.onKeyUp = (e) => this.handleBack(e, true, _pick(group, ['id', 'type', 'title']));
            const sType = group.type.toLowerCase();
            [actualData, groupTitle] = this.sectionChapter(
                sType,
                group,
                actualData,
                groupTitle
            );
        }

        // istanbul ignore else
        if (searchText) {
            actualData = actualData.filter(ad => {
                return ad.title.toLowerCase().indexOf(searchText.toLowerCase()) >= 0;
            });
        }

        const hasData = !_isEmpty(actualData);
        return (
            <>
                <div {...headProps}>
                    <span className='custom-filter-popover-action svg-color-prev-next' data-testid='filter-back'>
                        <svg focusable='false' className='pe-icon--chevron-back-sm-18'>
                            <use xlinkHref='#chevron-back-sm-18' />
                        </svg>
                    </span>
                    <span className='custom-filter-popover-label'>{groupTitle}</span>
                </div>
                {this.renderSearchBox()}
                <div className='custom-filter-popover-body' data-testid='filter-popover-body'>
                    {!hasData && <div className='no-data-div'>No data available</div>}
                    {hasData && this.renderSelectAll(actualData, group)}
                    {hasData && actualData.map((gItem) => {
                        let checkProps = { checked: false, indeterminate: false };
                        const sType = gItem.type.toLowerCase();
                        const isSelected = !!selectedFilters.find(sf => {
                            let retVal = null;
                            retVal = this.selectFilter(sf, gItem, group, retVal);
                            return retVal;
                        });
                        if (isSelected) {
                            checkProps.checked = true;
                        } else if (!_isEmpty(selectedFilters)) {
                            let tempArr = null;
                            tempArr = this.tempChapterSectionArr(sType, tempArr, gItem);

                            checkProps = this.updateCheckprops(
                                tempArr,
                                selectedFilters,
                                checkProps
                            );
                        }

                        const formLabel = <span className='cfilter-form-label' title={gItem.title}>{gItem.title}</span>;
                        const hasChild = ['chapter', 'section'].includes(sType);
                        const gItemProps = { className: 'svg-color-prev-next' };
                        if (hasChild) {
                            gItemProps.onClick = (e) => this.handleChange(e, gItem, false);
                            gItemProps.onKeyUp = (e) => this.handleChange(e, gItem, true);
                        }

                        return (
                            <div key={gItem.id} className='custom-filter-popover-item'>
                                <CustomCheckbox
                                    name={gItem.id}
                                    value={gItem.id}
                                    identifier={gItem.id}
                                    label={formLabel}
                                    {...checkProps}
                                    onChange={(e) => this.checkboxHandler(e, gItem)}
                                    labelProps={{ title: gItem.title }}
                                />
                                {hasChild && (
                                    <span {...gItemProps}>
                                        <svg focusable='false' className='pe-icon--chevron-next-sm-18'>
                                            <use xlinkHref='#chevron-next-sm-18' />
                                        </svg>
                                    </span>
                                )}
                            </div>
                        );
                    })}
                </div>
                <div className='custom-filter-popover-footer'>
                    <button
                        type='button'
                        aria-label='Clear'
                        className='custom-filter-clear'
                        data-testid='filterpopover-clear'
                        onClick={(e) => this.onClearFilter(e)}
                    >
                        Clear
                    </button>
                    <button
                        type='button'
                        aria-label='Apply'
                        className='custom-filter-apply'
                        data-testid='filterpopover-apply'
                        onClick={(e) => this.onApplyFilter(e)}
                    >
                        Apply
                    </button>
                </div>
            </>
        );
    };

    render() {
        const { addFilterText, filterOptions } = this.props;
        // istanbul ignore else
        if (!_isEmpty(this.state.selectedGroup)) {
            return (
                <div className='custom-filter-popover'>
                    {this.renderFilterItems(this.state.selectedGroup)}
                </div>
            );
        }

        return (
            <div className='custom-filter-popover'>
                <div className='custom-filter-popover-head'>
                    <div className='custom-filter-popover-label'>{addFilterText}</div>
                </div>
                <div className='custom-filter-popover-body' data-testid='filter-popover-body'>
                    {filterOptions.map((group) => {
                        return (
                            <div
                                role='listitem'
                                key={group.value}
                                className='custom-filter-popover-item cursor-pointer'
                                onClick={(e) => this.handleChange(e, group, false)}
                                onKeyUp={(e) => this.handleChange(e, group, true)}
                            >
                                <span>{group.name}</span>
                                <span className='svg-color-prev-next'>
                                    <svg focusable='false' className='pe-icon--chevron-next-sm-18'>
                                        <use xlinkHref='#chevron-next-sm-18' />
                                    </svg>
                                </span>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

FilterPopover.propTypes = {
    addFilterText: PropTypes.string,
    filterOptions: PropTypes.array.isRequired,
    onFilterUpdate: PropTypes.func.isRequired,
    getParentGroup: PropTypes.func.isRequired,
    selectedFilters: PropTypes.array,
    editFilterItem: PropTypes.object,
    popperData: PropTypes.object,
    page: PropTypes.string
};

FilterPopover.defaultProps = {
    addFilterText: 'Add Filter',
    selectedFilters: [],
    editFilterItem: null,
    popperData: null,
    page: ''
};

export default FilterPopover;
