import React from 'react';
import PropTypes from 'prop-types';
import _maxBy from 'lodash/maxBy';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import { renderErrorComp } from '../../common/utils';
import CustomProgressbar from './custom-progressbar';
import './style.scss';

const filterUpdate = (e, updateFilter, isKeyPress) => {
    let _updateFilter = _cloneDeep(updateFilter);
    if (isKeyPress) {
        const keyCode = e.which || e.keyCode;
        // istanbul ignore else
        if ([13, 32].includes(keyCode)) {
            _updateFilter = true;
        }
    } else {
        _updateFilter = true;
    }
    return _updateFilter;
};

const TopicPerformance = (props) => {
    const triggerFilterUpdate = (e, item, isFiltered, isKeyPress = false) => {
        let updateFilter = false;
        updateFilter = filterUpdate(e, updateFilter, isKeyPress);

        if (updateFilter && props.onFilterUpdate) {
            const filterData = [{
                id: item.id,
                title: item.name,
                type: 'knowledgeScore'
            }];
            props.onFilterUpdate(filterData, isFiltered);
        }
    };

    const setContainerCls = (item) => {
        const isFiltered = !!props.selectedFilters.find(sf => sf.id === item.id);
        const containerCls = ['progress-container'];
        // istanbul ignore else
        if (!_isEmpty(props.selectedFilters.find(sf => sf.type === 'knowledgeScore')) && !isFiltered) {
            containerCls.push('faded-progress');
        }
        return [isFiltered, containerCls];
    };

    let maxValue = 100;
    // istanbul ignore else
    if (!_isEmpty(props.perfData)) {
        maxValue = _maxBy(props.perfData, 'value').value;
        maxValue = maxValue === 0 ? 100 : maxValue;
    }

    return (
        <div className='topics-performance'>
            <h4>Topic Performance</h4>
            {props.showCoachMark && <span id={props.coachMarkId}><button className='d-none' type='button'>{props.coachMarkId}</button></span>}
            {props.showError && renderErrorComp()}
            {(!props.showError && props.perfData) && props.perfData.map((item) => {
                const [isFiltered, containerCls] = setContainerCls(item);

                return (
                    <div className={containerCls.join(' ')} key={item.type}>
                        <div
                            className='progress-title'
                            onClick={(e) => triggerFilterUpdate(e, item, isFiltered)}
                            onKeyUp={(e) => triggerFilterUpdate(e, item, isFiltered, true)}
                            role='link'
                            tabIndex={0}
                            data-testid={`progress-${item.id}`}
                        >
                            {item.name}
                        </div>
                        <CustomProgressbar
                            progressInfo={{
                                variant: item.type,
                                now: item.value,
                                label: <span className='label' data-testid={item.id}>{item.value}</span>,
                                max: maxValue
                            }}
                            hasTooltip={props.pageName === 'student-details'}
                            tooltipContent={(
                                <>
                                    <div className='title'>Class Comparison:</div>
                                    <div>{item.value} Topic {item.name}</div>
                                </>
                            )}
                        />
                    </div>
                );
            })}
        </div>
    );
};

TopicPerformance.propTypes = {
    perfData: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.number,
        type: PropTypes.string
    })),
    onFilterUpdate: PropTypes.func,
    selectedFilters: PropTypes.array,
    pageName: PropTypes.string,
    showError: PropTypes.bool,
    showCoachMark: PropTypes.bool,
    coachMarkId: PropTypes.string,
    hasError: PropTypes.bool,
    page: PropTypes.string
};

TopicPerformance.defaultProps = {
    perfData: [],
    onFilterUpdate: null,
    selectedFilters: [],
    pageName: 'topics',
    showError: false,
    showCoachMark: false,
    coachMarkId: '',
    hasError: false,
    page: ''
};

export default TopicPerformance;
