import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import './index.scss';

export const ShadowTableGrid = () => {
    return (
        <>
            <div className='shadow-table'>
                <div className='shadow-table-head' />
                <div className='shadow-table-body' />
                <div className='shadow-table-body' />
                <div className='shadow-table-body' />
                <div className='shadow-table-body' />
                <div className='shadow-table-body' />
                <div className='shadow-table-body' />
                <div className='shadow-table-body' />
                <div className='shadow-table-body' />
                <div className='shadow-table-body' />
                <div className='shadow-table-body' />
            </div>
            <div className='shadow-pagination'>
                <div className='shadow-pagination-left'>
                    <div className='shadow-pagination-dropdown' />
                    <div className='shadow-pagination-result' />
                </div>
                <div className='shadow-pagination-right'>
                    <div className='shadow-pagination-prev' />
                    <div className='shadow-pagination-value' />
                    <div className='shadow-pagination-value' />
                    <div className='shadow-pagination-value' />
                    <div className='shadow-pagination-next' />
                </div>
            </div>
        </>
    );
};

const ShadowTableActions = () => {
    return (
        <div className='shadow-table-actions'>
            <div className='shadow-table-count' />
            <div className='shadow-table-buttons'>
                <div className='shadow-table-button' />
                <div className='shadow-table-button' />
            </div>
        </div>
    );
};

const ShadowPerformance = () => {
    return (
        <div className='shadow-performance'>
            <Row>
                <Col sm={6}>
                    <div className='shadow-performance-info' />
                    <div className='shadow-performance-graph' />
                </Col>
                <Col sm={6}>
                    <div className='shadow-performance-info' />
                    <div className='shadow-performance-graph' />
                </Col>
            </Row>
        </div>
    );
};

const StudentsListPage = () => {
    return (
        <div className='students-list-shadow'>
            <div className='shadow-filter-button' />
            <div className='shadow-header'>
                <div className='shadow-heading' />
                <div className='shadow-settings' />
            </div>
            <div className='students-list-students-description' />
            <div className='students-list-chart' />
            <div className='students-list-email-selected' />
            <ShadowTableActions />
            <ShadowTableGrid />
        </div>
    );
};

const StudentDetailsPage = () => {
    return (
        <div className='student-details-shadow'>
            <div className='student-details-student-info'>
                <div className='student-info'>
                    <div className='student-details-name' />
                    <div className='student-details-course' />
                    <div className='student-details-tags' />
                </div>
                <div className='student-details-email' />
            </div>
            <ShadowPerformance />
            <ShadowTableActions />
            <ShadowTableGrid />
        </div>
    );
};

const TopicsOverviewPage = () => {
    return (
        <div className='topic-shadow' data-testid='topic-shadow'>
            <div className='shadow-filter-button' />
            <div className='shadow-header'>
                <div className='shadow-heading' />
                <div className='shadow-settings' />
            </div>
            <div className='shadow-header-info'>
                <div className='shadow-info-title' />
                <div className='shadow-info-desc' />
                <div className='shadow-info-desc' />
            </div>
            <ShadowPerformance />
            <ShadowTableActions />
            <ShadowTableGrid />
        </div>
    );
};

const Shadow = ({ pageName }) => {
    return (
        <div className='shadow-container'>
            {pageName === 'student-list' && <StudentsListPage />}
            {pageName === 'student-details' && <StudentDetailsPage />}
            {pageName === 'topics-overview' && <TopicsOverviewPage />}
        </div>
    );
};

Shadow.propTypes = {
    pageName: PropTypes.string
};

Shadow.defaultProps = {
    pageName: 'student-list'
};

export default Shadow;
