import React from 'react';
import PropTypes from 'prop-types';
import _isNumber from 'lodash/isNumber';
import { ProgressBar, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { PERF_GROUP } from '../../common/constants';
import { renderErrorComp } from '../../common/utils';

import './style.scss';

class CourseKnowledgeLevel extends React.Component {
    renderKnowledgeScore = () => {
        const { knowledgeScore, thresholdSettingsData } = this.props;
        const belowAverageRange = thresholdSettingsData.BelowAverage.maxValue;
        const averageRange = thresholdSettingsData.Average.maxValue;

        let type = '';
        const scoreLabelClassList = ['knowledge-score-label'];
        if (knowledgeScore <= belowAverageRange) {
            type = PERF_GROUP.belowAverage.title;
            scoreLabelClassList.push('avg-danger');
        } else if ((knowledgeScore > belowAverageRange)
            && (knowledgeScore <= averageRange)) {
            type = PERF_GROUP.average.title;
            scoreLabelClassList.push('avg-warning');
        } else {
            type = PERF_GROUP.aboveAverage.title;
            scoreLabelClassList.push('avg-success');
        }
        const tooltip = <Tooltip id='knowledge-score' className='knowledge-score-tooltip'>{knowledgeScore}</Tooltip>;
        return (
            <div id='courseKnowledgeTooltip' className='common-tooltip' style={{ marginLeft: `${knowledgeScore}%` }}>
                <div className='knowledge-score-box'>
                    <OverlayTrigger placement='top' trigger={['hover', 'focus']} overlay={tooltip}>
                        <div role='button' aria-label='knowledge score' tabIndex='0' className={scoreLabelClassList.join(' ')}>{type}</div>
                    </OverlayTrigger>
                    <div className='line' />
                </div>
            </div>
        );
    };

    renderNoAttempt = () => {
        return (
            <div id='courseKnowledgeTooltip' className='common-tooltip box-no-attempts'>
                <div className='knowledge-score-box'>
                    <div role='button' aria-label='knowledge score' className='knowledge-score-label no-attempts'>No Attempts</div>
                    <div className='line hide-line' />
                </div>
            </div>
        );
    }

    renderClassAverage = () => {
        const { classAverage } = this.props;
        const tooltip = <Tooltip id='knowledge-score' className='knowledge-score-tooltip'>{classAverage}</Tooltip>;
        return (
            <div className='class-average-line' style={{ marginLeft: `${classAverage}%` }}>
                <OverlayTrigger placement='top' trigger={['hover', 'focus']} overlay={tooltip}>
                    <div tabIndex='0' role='button' aria-label='class average' className='line' />
                </OverlayTrigger>
            </div>
        );
    }

    knowledgeScore = (knowledgeScore) => {
        // istanbul ignore else
        if (_isNumber(knowledgeScore)) {
            return this.renderKnowledgeScore();
        }
        return this.renderNoAttempt();
    }

    render() {
        const {
            thresholdSettingsData, knowledgeTitle, classAverage,
            knowledgeScore, showError
        } = this.props;
        return (
            <div>
                <h4 className='course-knowledge-text'>{knowledgeTitle}</h4>
                <div className='course-knowledge-progress'>
                    {showError ? renderErrorComp() : (
                        <>
                            <div>{this.knowledgeScore(knowledgeScore)}
                            </div>
                            <ProgressBar>
                                <ProgressBar variant='danger' now={thresholdSettingsData.BelowAverage.maxValue} key={1} />
                                <ProgressBar variant='warning' now={thresholdSettingsData.Average.maxValue - thresholdSettingsData.BelowAverage.maxValue} key={2} />
                                <ProgressBar variant='success' now={100 - thresholdSettingsData.Average.maxValue} key={3} />
                            </ProgressBar>
                            {_isNumber(classAverage) && <div>{this.renderClassAverage()}</div>}
                        </>
                    )}
                </div>
            </div>
        );
    }
}

CourseKnowledgeLevel.propTypes = {
    knowledgeScore: PropTypes.number,
    knowledgeTitle: PropTypes.string,
    thresholdSettingsData: PropTypes.object,
    classAverage: PropTypes.number,
    showError: PropTypes.bool
};

CourseKnowledgeLevel.defaultProps = {
    knowledgeScore: null,
    knowledgeTitle: 'Course Knowledge Level',
    thresholdSettingsData: {},
    classAverage: null,
    showError: false
};

export default CourseKnowledgeLevel;
