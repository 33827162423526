import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import _size from 'lodash/size';
import _isNumber from 'lodash/isNumber';

const Bars = (props) => {
    const [selectedBarChartIndex, setSelectedBarChartIndex] = useState(null);
    const { toolTipLabel } = props;

    const removeTooltip = () => {
        if (d3.selectAll('.histogram-tooltip').size() > 0) {
            d3.selectAll('.histogram-tooltip').remove();
        }
    };

    const hightLightAndAddToolTipBarChartColumn = (datum, index, nodes) => {
        const datumSize = _size(datum);
        const tooltip = d3.select('body').append('div')
            .html(`<div class='tooltip-count' data-testid='tooltip-count'>${datumSize} ${datumSize === 1 ? toolTipLabel.single : toolTipLabel.plural}</div>`)
            .attr('class', 'tooltip histogram-tooltip');

        const node = nodes[index];
        if (d3.select('.o-score-histogram-chart').node()) {
            const chartRect = d3.select('.o-score-histogram-chart').node().getBoundingClientRect();
            const tooltipRect = tooltip.node().getBoundingClientRect();

            const tooltipTop = chartRect.top + node.getBBox().y - tooltipRect.height - 10;
            tooltip.style('top', `${tooltipTop}px`);

            const tooltipLeft = chartRect.left + node.getBBox().x
                + (node.getBBox().width / 2) - (tooltipRect.width / 2);
            tooltip.style('left', `${tooltipLeft}px`);
        }
    };

    const selectDeselectBarChartColumn = (datum, index) => {
        if (selectedBarChartIndex === index) {
            setSelectedBarChartIndex(null);
        } else {
            setSelectedBarChartIndex(index);
        }
        props.onBarSelectDeselct && props.onBarSelectDeselct(index);
    };

    useEffect(() => {
        if (props.refreshChart) {
            setSelectedBarChartIndex(null);
            removeTooltip();
        }
    }, [props.refreshChart]);

    useEffect(() => {
        const barsWrapper = d3.select('.bars-wrapper');
        const { xScale, yScale } = props.scales;
        barsWrapper.append('g')
            .selectAll('rect')
            .data(props.bins)
            .enter()
            .append('rect')
            .attr('x', d => xScale(d.x0) + 1)
            .attr('y', d => yScale(d.length))
            .attr('width', d => xScale(d.x1) - xScale(d.x0) - 1)
            .attr('height', d => yScale(0) - yScale(d.length))
            .attr('tabindex', (d) => {
                return d.length ? 0 : -1;
            })
            .attr('class', (d, index) => {
                const clsName = [];
                if (d.x0 < 0) {
                    clsName.push('distro-noattempts');
                }
                if (_isNumber(selectedBarChartIndex) && (index !== selectedBarChartIndex)) {
                    clsName.push('inactive');
                }
                return clsName.join(' ');
            })
            .attr('data-testid', d => d.x0);

        // tooltip for the dots
        barsWrapper.selectAll('rect')
            .on('mouseover', hightLightAndAddToolTipBarChartColumn)
            .on('mouseout', removeTooltip)
            .on('click', selectDeselectBarChartColumn)
            .on('keyup', (datum, index, nodes) => {
                const e = d3.event;
                e.preventDefault();
                // istanbul ignore else
                if (e.keyCode === 13 || e.keyCode === 32) {
                    hightLightAndAddToolTipBarChartColumn(datum, index, nodes);
                    selectDeselectBarChartColumn(datum, index);
                } else if (e.keyCode === 9 || (e.shiftKey && e.keyCode === 9)) {
                    removeTooltip();
                    hightLightAndAddToolTipBarChartColumn(datum, index, nodes);
                }
            });
        return () => {
            // Removing existing bars if any
            barsWrapper.select('g').remove();
        };
    }, [props]);

    useEffect(() => {
        return () => {
            removeTooltip();
        };
    }, []);

    return (<g className='bars-wrapper' />);
};

Bars.propTypes = {
    scales: PropTypes.object,
    bins: PropTypes.array,
    hasMorePercent: PropTypes.number,
    onBarSelectDeselct: PropTypes.func,
    refreshChart: PropTypes.bool,
    toolTipLabel: PropTypes.object
};

Bars.defaultProps = {
    scales: {},
    bins: [],
    hasMorePercent: 0,
    onBarSelectDeselct: null,
    refreshChart: false,
    toolTipLabel: {
        single: '',
        plural: ''
    }
};

export default Bars;
