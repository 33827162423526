import _isEmpty from 'lodash/isEmpty';
import _size from 'lodash/size';

import { makeAPICall } from '../common/utils';
import { filterSchema } from '../common/schemaHelper';
import integrationHelper from '../common/integrationHelper';
import { ERRORS } from '../common/constants';

const STORE_RESET = 'filter/STORE_RESET';
const INIT_FILTER_DATA = 'filter/INIT_FILTER_DATA';
const FILTER_ERROR = 'filter/FILTER_ERROR';

const initialState = {
    chapterList: [],
    knowledgeScore: [{
        name: 'Knowledge Score',
        value: 'knowledgeScore',
        children: [
            {
                id: 'aboveAverage',
                title: 'Above Average',
                type: 'knowledgeScore'
            },
            {
                id: 'average',
                title: 'Average',
                type: 'knowledgeScore'
            },
            {
                id: 'belowAverage',
                title: 'Below Average',
                type: 'knowledgeScore'
            },
            {
                id: 'noAttempts',
                title: 'No Attempts',
                type: 'knowledgeScore'
            }
        ]
    }],
    studentTags: [],
    updatedInfo: null,
    hasAPIError: false,
    hasNoTopics: false
};

const resetFilterData = () => ({ type: STORE_RESET });

const processAndSetFilterList = ({ getCourseDetails }) => {
    const filterTags = [];
    let chapters = [];
    let hasNoTopics = true;
    if (!getCourseDetails || _isEmpty(getCourseDetails.books)) {
        hasNoTopics = false;
    } else {
        getCourseDetails.books.some(book => {
            let exitBooksforEach = false;
            (book.chapters || []).some(chapter => {
                let exitChapterforEach = false;
                (chapter.sections || []).some(section => {
                    if (_size(section.topics) > 0) {
                        hasNoTopics = false;
                        exitBooksforEach = true;
                        exitChapterforEach = true;
                        return true;
                    }
                    return false;
                });

                if (exitChapterforEach) {
                    return true;
                }
                return false;
            });
            if (exitBooksforEach) {
                return true;
            }
            return false;
        });

        getCourseDetails.books.forEach(book => {
            chapters = [...chapters, ...book.chapters];
        });
    }

    getCourseDetails?.studentTags.forEach(st => {
        filterTags.push({
            id: st.studentTagId, title: st.studentTagName, type: 'studentTags'
        });
    });

    if (hasNoTopics) {
        integrationHelper.triggerCallback(ERRORS.NOTOPICS_ERROR, 'updateRoute');
    }

    return {
        type: INIT_FILTER_DATA,
        payload: {
            chapters: [{ name: 'Chapter', value: 'chapter', children: chapters }],
            studentTags: [{ name: 'Student Tags', value: 'studentTags', children: filterTags }],
            hasNoTopics
        }
    };
};

const setFilterAPIError = (payload) => {
    return { type: FILTER_ERROR, payload };
};

const initiateFilterData = () => {
    return async (dispatch, getState) => {
        const { chapterList, studentTags } = getState().filterReducer;
        const { configs } = getState().commonReducer;
        if (_isEmpty(chapterList) || _isEmpty(studentTags)) {
            try {
                const query = filterSchema(configs);
                const resp = await makeAPICall(configs.API_URL, query, 'POST');
                if (resp.status === 200 && resp.data) {
                    return dispatch(processAndSetFilterList(resp.data.data));
                }
            } catch (e) {
                if (process.env.NODE_ENV === 'development') {
                    /* eslint-disable-next-line no-console */
                    console.log('Filter Error:', e);
                }
                return dispatch(setFilterAPIError(true));
            }
        }

        return null;
    };
};

const filterReducer = (state = initialState, action) => {
    const { type, payload } = action;
    let nextState;
    switch (type) {
    case INIT_FILTER_DATA:
        nextState = {
            ...state,
            chapterList: payload.chapters,
            studentTags: payload.studentTags,
            hasAPIError: false,
            updatedInfo: new Date().getTime(),
            hasNoTopics: payload.hasNoTopics
        };
        return nextState;
    case FILTER_ERROR:
        nextState = {
            ...state,
            hasAPIError: payload,
            updatedInfo: new Date().getTime()
        };
        return nextState;
    case STORE_RESET:
        return initialState;
    default:
        return state;
    }
};

export const actions = {
    initiateFilterData,
    resetFilterData
};

export default filterReducer;
