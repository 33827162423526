import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Col, Overlay, Popover, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import _isNumber from 'lodash/isNumber';
import _size from 'lodash/size';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import _round from 'lodash/round';
import _cloneDeep from 'lodash/cloneDeep';

import StudentDistributionChart from '../student-distribution-chart';
import CustomTable from '../custom-table';
import CustomSearch from '../custom-search';
import CustomFilter from '../custom-filter';
import KnowledgeScoreSettings from '../knowledge-group-settings';
import Shadow, { ShadowTableGrid } from '../shadow';
import {
    secondsToHms, getEmailProps, renderEmailComponent, getPiiErrorMsg,
    getStyledKnowledgeScore, getSelectedFilterInfo, getPageCount, GAprops, sendGTM,
    renderKnowledgeScoreInfoContent, renderErrorComp
} from '../../common/utils';
import { PII_ERROR_DELAY } from '../../common/constants';
import { mapDispatchToProps } from '../../reducers/utils';

import './index.scss';

const StudentList = (props) => {
    const history = useHistory();
    const customTableREF = useRef();
    const [searchText, setSearchText] = useState(props.studentSearchText);
    const [selectedRowIds, setSelectedRowIds] = useState({});
    const [currentpagination, setCurrentpagination] = useState({ offset: 1, limit: 10 });
    const [sortArr, setSortArray] = useState([{ id: 'firstName', desc: false }]);
    const [enableEmailBtn, setenableEmailBtn] = useState(false);
    const [emailObj, setEmailObj] = useState({});
    const [histogramSelectedBarIndex, setHistogramSelectedBarIndex] = useState(null);
    const [refreshChart, setRefreshChart] = useState(false);
    const [tagStudentId, setTagStudentId] = useState(null);
    const [placement, setPlacement] = useState('bottom');
    const [selectedSort, setSelectedSort] = useState([{ fieldName: 'familyName', sortOrder: 'ASC' }, { fieldName: 'givenName', sortOrder: 'ASC' }]);
    const [showModalData, setShowModalData] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const { actions, studentList, searchDropDownList, filterUpdated,
        commonData, filterInfo, scoreHistogramData, isPageLoading, piiErrorCount,
        pagination, thresholdSettingsData, hasAPIError, hasSearchAPIError, isGridLoading } = props;
    const histogramData = _cloneDeep(scoreHistogramData);
    if (hasAPIError) {
        histogramData.dataLoadFailure = true;
    }
    const actualData = _cloneDeep(studentList);
    useEffect(() => {
        if (!_size(studentList)) {
            let { selectedFilters } = filterInfo;
            let storageFilters = sessionStorage.getItem('selectedFilters');
            storageFilters = storageFilters ? JSON.parse(storageFilters).studentListFilter : [];
            // istanbul ignore else
            if (!_size(selectedFilters && !_isEmpty(storageFilters))) {
                selectedFilters = getSelectedFilterInfo(storageFilters, filterInfo.filterOptions);
                actions.updateStuListSelectedFilters(selectedFilters, true);
            }

            const params = {
                selectedFilters,
                thresholdSettingsData: commonData.thresholdSettingsData,
                lrSearchKeyword: searchText,
                // sort: selectedSort
            };
            actions.initiateStudentListData
                && actions.initiateStudentListData(params, GAprops.initialLoad);
        }
        return () => {
            actions.resetStudentData && actions.resetStudentData();
        };
    }, []);

    useEffect(() => {
        let storageFilters = sessionStorage.getItem('selectedFilters');
        storageFilters = storageFilters ? JSON.parse(storageFilters).studentListFilter : '';
        if (filterUpdated && !_isEmpty(storageFilters)) {
            const selectedFilters = getSelectedFilterInfo(storageFilters, filterInfo.filterOptions);
            actions.updateStuListSelectedFilters(selectedFilters, true);
            const params = {
                selectedFilters,
                thresholdSettingsData: commonData.thresholdSettingsData,
                lrSearchKeyword: searchText,
                sort: selectedSort,
                pagination: { offset: 1, limit: currentpagination.limit }
            };
            actions.initiateStudentListData(params);
        }
    }, [filterUpdated]);

    useEffect(() => {
        if (pagination.offset !== currentpagination.offset
            || pagination.limit !== currentpagination.limit) {
            const params = {
                lrSearchKeyword: searchText,
                selectedFilters: filterInfo.selectedFilters,
                thresholdSettingsData: commonData.thresholdSettingsData,
                sort: selectedSort,
                pagination: currentpagination,
                histogramIndex: histogramSelectedBarIndex
            };
            actions.updatePageIndex && actions.updatePageIndex(params);
        }
    }, [currentpagination]);

    useEffect(() => {
        if (commonData.settingsSuccess) {
            setShowModalData(false);
            setRefreshChart(true);
            const params = {
                lrSearchKeyword: searchText,
                selectedFilters: filterInfo.selectedFilters,
                thresholdSettingsData: commonData.thresholdSettingsData,
                sort: selectedSort,
                pagination: { offset: 1, limit: currentpagination.limit }
            };
            actions.initiateStudentListData
                && actions.initiateStudentListData(params, GAprops.initialLoad);
        }
    }, [commonData.settingsSuccess]);

    useEffect(() => {
        if (piiErrorCount > 0) {
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, PII_ERROR_DELAY);
        }
    }, [piiErrorCount, studentList]);

    const fetchData = useCallback((paginationInfo) => {
        setCurrentpagination({
            offset: paginationInfo.pageIndex + 1,
            limit: paginationInfo.pageSize
        });
    }, []);

    const triggerExport = () => {
        const params = {
            selectedFilters: filterInfo.selectedFilters,
            thresholdSettingsData: commonData.thresholdSettingsData,
            histogramIndex: histogramSelectedBarIndex,
            lrSearchKeyword: searchText
        };
        actions.exportStudentListData(params);
    };

    // Default Table Options
    const defaultTableOpts = {
        disableSortBy: false,
        disableMultiSort: true,
        manualSortBy: true,
        disableSortRemove: true,
        autoResetSortBy: false,
        manualPagination: true,
        pageCount: getPageCount(pagination),
        currentpageIndex: pagination.offset - 1,
        initialState: {
            sortBy: sortArr,
            selectedRowIds
        }
    };

    const cellRenderProps = {
        searchText
    };

    const noDataMessage = () => {
        const message = props.errors && props.errors.length > 0 && ['408', '204'].includes(props.errors[0].errorCode) ? 'No data available.' : 'There are no student list found for current search.';
        return (
            <div className='no-data-message'>{message}</div>
        );
    };

    const renderStudentName = item => {
        return (
            <>
                <span
                    className='label'
                    onClick={(e) => onStudentNameClick(e, item)}
                    onKeyUp={(e) => onStudentNameClick(e, item, true)}
                    role='button'
                    tabIndex='0'
                >
                    {item.lastName}, {item.firstName}
                </span>
                {_size(item.tags) > 0 ? studentTags(item) : null}
            </>
        );
    };

    const tagClick = (e, studentId = null) => {
        // istanbul ignore else
        if (!e.key || e.key === ' ' || e.key === 'Spacebar' || e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            setTagStudentId(studentId);
        }
    };

    const handleTagClick = (e, tag) => {
        e.stopPropagation();
        document.body.click();
        const filterArr = [];

        // istanbul ignore else
        if (!filterArr.find(fa => fa.id === tag.studentTagId)) {
            filterArr.push({ id: tag.studentTagId, title: tag.studentTagName, type: 'studentTags' });
        }
        resetGrid();
        setRefreshChart(true);
        setHistogramSelectedBarIndex(null);
        sendGTM({
            eventName: 'studentTagFilter',
            eventDesc: 'student tag filter',
            eventLabel: `tag: ${tag.studentTagName}`,
            page: GAprops.studentPage
        });
        const params = {
            selectedFilters: filterArr,
            thresholdSettingsData: commonData.thresholdSettingsData,
            pagination: { offset: 1, limit: currentpagination.limit }
        };
        actions.updateStuListSelectedFilters(filterArr);
        actions.initiateStudentListData && actions.initiateStudentListData(params);
    };

    const onEnterFunc = (e, id) => {
        const elem = document.querySelector(`[data-studenttag="studoverlay-${id}"]`);
        let elemHeight = 0;

        // istanbul ignore else
        if (elem) {
            elemHeight = elem.getBoundingClientRect().bottom;
        }
        const popoverHeight = e.scrollHeight;
        const { clientHeight } = document.body;
        let newPlacement = 'bottom';

        // istanbul ignore else
        if (clientHeight < (elemHeight + popoverHeight + 10)) {
            newPlacement = 'top';
        }
        setPlacement(newPlacement);
    };

    const popoverHandler = (student) => {
        let studTagHtml = '';
        // istanbul ignore else
        if (_size(student.tags) > 0) {
            const studTags = student.tags.map(d => (
                <li
                    key={d.studentTagId}
                    aria-label={`Student tag: ${d.studentTagName}`}
                    onClick={e => handleTagClick(e, d)}
                    onKeyUp={(e) => {
                        // istanbul ignore else
                        if (e.key === ' ' || e.key === 'Spacebar' || e.key === 'Enter') {
                            handleTagClick(e, d);
                        }
                    }}
                    tabIndex='0'
                    role='menuitem'
                >
                    {d.studentTagName}
                </li>
            ));
            studTagHtml = (
                <div className='tag-content' data-testid='tag-content'>
                    <strong>Student Tags:</strong>
                    <ul>{studTags}</ul>
                </div>
            );
        }
        return (
            <Popover id='popover-tags' data-testid='popover-tags'>
                {studTagHtml}
            </Popover>
        );
    };

    const studentTags = (student) => {
        return (
            <>
                <span
                    id={`studoverlay-${student.studentId}`}
                    data-studenttag={`studoverlay-${student.studentId}`}
                    data-testid={`studoverlay-${student.studentId}`}
                    className='tag-pop-over-label'
                    tabIndex='0'
                    aria-label='Student tags'
                    onKeyUp={e => {
                        // istanbul ignore else
                        if (e.key === ' ' || e.key === 'Spacebar' || e.key === 'Enter') {
                            tagClick(e, student.studentId);
                        }
                    }}
                    onClick={e => tagClick(e, student.studentId)}
                    role='button'
                />
                <Overlay
                    target={document.querySelector(`#studoverlay-${student.studentId}`)}
                    container={document.querySelector(`#studoverlay-${student.studentId}`)}
                    onEnter={(e) => onEnterFunc(e, student.studentId)}
                    placement={placement}
                    rootClose
                    show={tagStudentId === student.studentId}
                    onHide={() => (tagStudentId === student.studentId ? setTagStudentId(null) : '')}
                >
                    {popoverHandler(student)}
                </Overlay>
            </>
        );
    };

    const renderStudentKnowledge = ({ knowledgeScore }) => {
        return getStyledKnowledgeScore(knowledgeScore, commonData.thresholdSettingsData);
    };

    const columns = React.useMemo(() => [
        { Header: 'Student', id: 'familyName', minWidth: 100, width: 150, accessor: renderStudentName },
        { Header: 'Knowledge Score', id: 'knowledgeLevel', width: 80, accessor: renderStudentKnowledge, isInfoIcon: true, infoContent: renderKnowledgeScoreInfoContent() },
        { Header: 'Correct on First Try', id: 'correctOnFirstAttemptTry', width: 80, accessor: ({ correctFirstTry }) => (_isNumber(correctFirstTry) ? `${_round(correctFirstTry)}%` : '--'), isInfoIcon: false },
        { Header: 'Time Spent', id: 'timeOnTask', width: 100, accessor: ({ timeOnTask }) => secondsToHms(timeOnTask), isInfoIcon: false }
    ], [tagStudentId, placement, commonData.thresholdSettingsData]);

    const onStudentNameClick = (e, item, isKeyPress = false) => {
        e && e.stopPropagation();
        let triggerNameClick = false;
        if (isKeyPress) {
            const keyCode = e.which || e.keyCode;
            // istanbul ignore else
            if ([13, 32].includes(keyCode)) {
                triggerNameClick = true;
            }
        } else {
            triggerNameClick = true;
        }

        // istanbul ignore else
        if (triggerNameClick) {
            if (document.body.firstChild.className === 'notification message-open') {
                document.body.firstChild.classList.remove('message-open');
            }
            const { configs } = commonData;
            history.push(`/student-details/${item.id}?env=${configs.ENV}`);
            sendGTM({
                eventName: 'studentNameClickedInGrid',
                eventDesc: 'Student name clicked',
                eventLabel: `studentId: ${item.id}`,
                page: GAprops.studentPage
            });
        }
    };

    const setStudentEmailObj = (selectedRows) => {
        const maillers = [];
        selectedRows.forEach((item) => {
            maillers.push({
                fullName: `${item.original.lastName}, ${item.original.firstName}`,
                lastName: item.original.lastName,
                firstName: item.original.firstName,
                email: item.original.primaryEmailAddress,
                studentId: item.id
            });
        });
        setEmailObj(getEmailProps(maillers, commonData, GAprops.studentPage));
    };

    const onRowCheckBoxSelectDeselect = (row, isSelectAllRows = false) => {
        setSelectedRowIds(row.selectedRowIds);
        // istanbul ignore else
        if (_size(row.selectedFlatRows) > 0) {
            setStudentEmailObj(row.selectedFlatRows);
            setenableEmailBtn(true);
        } else {
            setenableEmailBtn(false);
        }
        if (isSelectAllRows) {
            sendGTM({
                eventName: 'selectAllCheckboxChecked',
                eventDesc: 'select all check box checked ',
                eventLabel: `selectedStudents: ${_size(row.selectedRowIds)}, pageSize: ${currentpagination.limit}`,
                page: GAprops.studentPage
            });
        }
    };

    const onSearchResultClick = (studentInfo) => {
        const { configs } = commonData;
        history.push(`/student-details/${studentInfo.id}?env=${configs.ENV}`);
    };

    const onSearch = (value, isClear = false) => {
        if (isClear) {
            setSearchText('');
            resetGrid();
            setRefreshChart(true);
            const params = {
                selectedFilters: filterInfo.selectedFilters,
                thresholdSettingsData: commonData.thresholdSettingsData,
                lrSearchKeyword: null,
                sort: selectedSort,
                pagination: { offset: 1, limit: currentpagination.limit }
            };

            actions?.initiateStudentListData(params, GAprops.onSearchClear);
        } else if (_size(value) >= 3) {
            setSearchText(value);
            onSearchSubmit(value, GAprops.onSearch);
        } else {
            setSearchText(value);
            if (_size(value) === 0) {
                onSearchSubmit(value);
            }
        }
    };

    const resetGrid = () => {
        customTableREF.current && customTableREF.current.deSelectAllRows();
        setSelectedRowIds({});
        setenableEmailBtn(false);
    };

    const onSearchSubmit = (searchValue, GAtype = null) => {
        resetGrid();
        setRefreshChart(true);
        const params = {
            lrSearchKeyword: searchValue,
            selectedFilters: filterInfo.selectedFilters,
            thresholdSettingsData: commonData.thresholdSettingsData,
            sort: selectedSort
        };
        actions.filterStudentListGrid && actions.filterStudentListGrid(params, GAtype);
    };

    const updatePaginationIndex = (_value, selectedId, currentPage,
        numberofRecords) => {
        setSelectedRowIds(selectedId);
        sendGTM({
            eventName: 'recordsPerPageListChange',
            eventDesc: 'Records Per Page List Change',
            eventLabel: `currentPageNo: ${currentPage},  recordsPerPage:  ${numberofRecords}`,
            page: GAprops.studentPage
        });
    };

    const onPaginationChange = (selectedPage, selectedPageType, pageSize) => {
        sendGTM({
            eventName: 'studentListPageChange',
            eventDesc: 'Page Change',
            eventLabel: `currentPageNo: ${selectedPage},  recordsPerPage:  ${pageSize}`,
            button: selectedPageType,
            page: GAprops.studentPage
        });
    };

    const renderBtnEmailSelected = () => {
        return (
            <button
                className='button-email'
                type='button'
                data-target='#compose_email'
                data-toggle='modal'
                title={`${(!_isEmpty(emailObj) && enableEmailBtn) ? emailObj.data.lists.length : '0'} - Students in mailing list`}
                disabled={!enableEmailBtn}
                onClick={() => {
                    sendGTM({
                        eventName: 'emailModalOpened',
                        eventDesc: 'Email modal opened',
                        eventLabel: 'Email modal opened',
                        page: GAprops.studentPage
                    });
                }}
            >
                Email Selected
            </button>
        );
    };
    const onHelpIconClick = () => {
        sendGTM({
            eventName: 'studentsHelpIconClicked',
            eventDesc: 'Students help icon clicked',
            page: GAprops.studentPage
        });
    };

    const renderHelpIcon = () => {
        const { configs } = commonData;
        // istanbul ignore else
        if (configs && configs.HELP_WORKFLOW_URL_STUDENT) {
            return (
                <a className='help-icon' href={configs.HELP_WORKFLOW_URL_STUDENT} target='_blank' rel='noreferrer' aria-label='Topics Help' onClick={onHelpIconClick}>
                    <svg focusable='false' className='pe-icon--help-outline-18'>
                        <use xlinkHref='#help-fill-18' />
                    </svg>
                </a>
            );
        }

        return null;
    };

    const onBarSelectDeselct = (index) => {
        if (histogramSelectedBarIndex === index) {
            setHistogramSelectedBarIndex(null);
            const params = {
                selectedFilters: filterInfo.selectedFilters,
                thresholdSettingsData: commonData.thresholdSettingsData,
                lrSearchKeyword: searchText,
                sort: selectedSort,
                pagination: { offset: 1, limit: currentpagination.limit }
            };
            actions.initiateStudentListData && actions.initiateStudentListData(params, null, true);
        } else {
            setHistogramSelectedBarIndex(index);
            const params = {
                selectedFilters: filterInfo.selectedFilters,
                thresholdSettingsData: commonData.thresholdSettingsData,
                histogramIndex: index,
                lrSearchKeyword: searchText,
                pagination: { offset: 1, limit: currentpagination.limit }
            };
            actions.updateHistogramStudentList
                && actions.updateHistogramStudentList(params, GAprops.onStudentBarChartFilter);
        }
        resetGrid();
        setRefreshChart(false);
    };

    const onCustomTableSort = (sortData) => {
        if (!_isEqual(sortData.sortBy[0], sortArr[0])) {
            setSortArray(sortData.sortBy);
            let sortObj = [{ fieldName: 'familyName', sortOrder: sortData.sortBy[0].desc ? 'DESC' : 'ASC' }, { fieldName: 'givenName', sortOrder: sortData.sortBy[0].desc ? 'DESC' : 'ASC' }];
            if (sortData.sortBy[0].id !== 'familyName') {
                sortObj = [{ fieldName: sortData.sortBy[0].id, sortOrder: sortData.sortBy[0].desc ? 'DESC' : 'ASC' }];
            }
            setSelectedSort(sortObj);
            const params = {
                sort: sortObj,
                selectedFilters: filterInfo.selectedFilters,
                thresholdSettingsData: commonData.thresholdSettingsData,
                lrSearchKeyword: searchText,
                histogramIndex: histogramSelectedBarIndex,
                pagination: { offset: 1, limit: currentpagination.limit }
            };
            actions?.sortStudentList(params);
            sendGTM({
                eventName: 'studentGridSort',
                eventDesc: 'Student grid sorted',
                eventLabel: `field: ${sortData.sortBy[0].id}, sortDirection: ${sortData.sortBy[0].desc ? 'DESC' : 'ASC'}`,
                page: GAprops.studentPage
            });
        }
    };

    const showKnowledgeScoreModal = () => {
        setShowModalData(true);
        actions.setSettingsSuccess && actions.setSettingsSuccess();
        sendGTM({
            eventName: 'knowledgeScoreGroupSettingsOpen',
            eventDesc: 'Knowledge score group settings opened',
            eventLabel: 'Knowledge score group settings opened',
            page: GAprops.studentPage
        });
    };

    const handleClose = () => {
        setShowModalData(!showModalData);
    };

    const saveKnowledgeScore = (data) => {
        actions.saveKnowledgeScore
            && actions.saveKnowledgeScore(data, commonData);
    };

    const knowledgeScoreSave = (data) => {
        if (data.task === 'SAVE') {
            saveKnowledgeScore(data);
        } else if (data.task === 'CANCEL') {
            setShowModalData(false);
        }
    };

    const tooltip = <Tooltip id='topics-setting' className='common-tooltip'>Topics Setting</Tooltip>;
    const tooltipExport = <Tooltip id='topics-setting' className='common-tooltip'>Export Students</Tooltip>;
    const isLoading = isPageLoading === 'true';
    const listGridCls = ['student-list-grid', 'grid-check-box'];
    // istanbul ignore else
    if (isGridLoading) {
        listGridCls.push('visibility-hidden');
    }
    return (
        <div className='student-container'>
            {isLoading && <Shadow pageName='student-list' />}
            <div className={!isLoading ? '' : 'visibility-hidden'}>
                <div className='student-list-filter'>
                    <CustomFilter
                        addFilterText='Add Filter'
                        filterOptions={filterInfo.filterOptions}
                        selectedFilters={filterInfo.selectedFilters}
                        onFilterUpdate={
                            (payload) => {
                                setRefreshChart(true);
                                setHistogramSelectedBarIndex(null);
                                actions.updateStuListSelectedFilters(payload);
                                resetGrid();

                                const params = {
                                    selectedFilters: payload,
                                    thresholdSettingsData: commonData.thresholdSettingsData,
                                    pagination: {
                                        offset: 1,
                                        limit: currentpagination.limit
                                    }
                                };
                                actions.initiateStudentListData(params);
                            }
                        }
                        page={GAprops.studentPage}
                    />
                </div>
                <Row>
                    <Col sm={11}>
                        <div className='student-list-title'>
                            <h1 className='heading-h1'>Students {renderHelpIcon()}</h1>
                        </div>
                    </Col>
                    <Col sm={1} className='text-right'>
                        <OverlayTrigger placement='bottom' trigger={['hover', 'focus']} overlay={tooltip}>
                            <button type='button' tabIndex='0' aria-label='Topics Setting' className='btn-setting' data-testid='btn-setting' onClick={() => showKnowledgeScoreModal()} />
                        </OverlayTrigger>
                    </Col>
                </Row>
                <StudentDistributionChart
                    scoreHistogramData={histogramData}
                    onBarSelectDeselct={onBarSelectDeselct}
                    refreshChart={refreshChart}
                />
                <div className='student-list'>
                    <div className='d-flex justify-content-between align-items-center'>
                        {renderBtnEmailSelected()}
                        {showAlert && (<div className='alert-pii-error'>{getPiiErrorMsg(piiErrorCount)}</div>)}
                    </div>
                    <Row className='student-list-header'>
                        <Col className='student-list-info' data-testid='student-list-info'>
                            <h3>{`${pagination.total} ${pagination.total === 1 ? 'student' : 'students'}`}</h3>
                            {(piiErrorCount > 0) && (
                                <h5>&nbsp;({getPiiErrorMsg(piiErrorCount)})</h5>)}
                        </Col>
                        <Col className='student-list-actions text-right'>
                            <CustomSearch
                                searchText={searchText}
                                placeholder='Search Students'
                                onSearch={onSearch}
                                isSearchDropdownEnabled
                                updateSearchDropDown={searchDropDownList}
                                onSearchResultClick={onSearchResultClick}
                                onSearchSubmit={onSearchSubmit}
                                page={GAprops.studentPage}
                                showError={hasSearchAPIError}
                                showLoader={isGridLoading}
                            />
                            <OverlayTrigger placement='bottom' trigger={['hover', 'focus']} overlay={tooltipExport} htmlFor='btn-export'>
                                <button
                                    type='button'
                                    tabIndex='0'
                                    className='btn-export'
                                    onClick={() => triggerExport()}
                                    data-testid='export-btn'
                                    aria-label='Export Students'
                                    disabled={_size(actualData) === 0}
                                />
                            </OverlayTrigger>
                            {showModalData && (
                                <Modal className='threshold_wrapper knowledge-score-madal' show={showModalData} onHide={handleClose}>
                                    <Modal.Body>
                                        <KnowledgeScoreSettings
                                            data={thresholdSettingsData}
                                            knowledgeScoreSave={knowledgeScoreSave}
                                            knowledgeScoreError={
                                                commonData.knowledgeGroupSaveError
                                            }
                                            page={GAprops.studentPage}
                                        />
                                    </Modal.Body>
                                </Modal>
                            )}
                        </Col>
                    </Row>
                    {isGridLoading && (
                        <div className='shadow-container'>
                            <ShadowTableGrid />
                        </div>
                    )}
                    <Row className={listGridCls.join(' ')}>
                        {!hasAPIError && (
                            <CustomTable
                                data={actualData}
                                columns={columns}
                                defaultTableOpts={defaultTableOpts}
                                onSort={onCustomTableSort}
                                cellRenderProps={cellRenderProps}
                                isCheckBoxGrid
                                onRowSelect={onRowCheckBoxSelectDeselect}
                                updatePaginationIndex={updatePaginationIndex}
                                onPaginationChange={onPaginationChange}
                                ref={customTableREF}
                                fetchData={fetchData}
                                totalRowCount={pagination.total}
                                page={GAprops.studentPage}
                            />
                        )}
                        {hasAPIError && (
                            <div className='ca-error'>
                                {renderErrorComp()}
                            </div>
                        )}
                        {(_size(actualData) === 0 && !hasAPIError) && noDataMessage()}
                    </Row>
                </div>
            </div>
            {emailObj && renderEmailComponent(emailObj)}
        </div>
    );
};

StudentList.propTypes = {
    actions: PropTypes.object,
    studentList: PropTypes.array,
    isPageLoading: PropTypes.string,
    isGridLoading: PropTypes.bool,
    pagination: PropTypes.object,
    piiErrorCount: PropTypes.number,
    errors: PropTypes.array,
    studentSearchText: PropTypes.string,
    searchDropDownList: PropTypes.array,
    filterInfo: PropTypes.shape({
        filterOptions: PropTypes.array,
        selectedFilters: PropTypes.array
    }),
    scoreHistogramData: PropTypes.object,
    commonData: PropTypes.object,
    filterUpdated: PropTypes.number,
    sortBy: PropTypes.array,
    hasAPIError: PropTypes.bool,
    hasSearchAPIError: PropTypes.bool,
    thresholdSettingsData: PropTypes.object
};

StudentList.defaultProps = {
    actions: {},
    studentList: [],
    pagination: {},
    piiErrorCount: 0,
    errors: [],
    studentSearchText: '',
    isPageLoading: 'true',
    isGridLoading: 'false',
    sortBy: [{ fieldName: 'familyName', sortOrder: 'ASC' }, { fieldName: 'givenName', sortOrder: 'ASC' }],
    searchDropDownList: [],
    filterInfo: {
        filterOptions: [],
        selectedFilters: []
    },
    scoreHistogramData: {},
    commonData: {},
    filterUpdated: null,
    hasAPIError: false,
    hasSearchAPIError: false,
    thresholdSettingsData: {}
};

const mapStateToProps = (state) => ({
    commonData: state.commonReducer,
    studentSearchText: state.studentListReducer.studentSearchText,
    studentList: state.studentListReducer.studentList,
    pagination: state.studentListReducer.pagination,
    piiErrorCount: state.studentListReducer.piiErrorCount,
    errors: state.studentListReducer.errors,
    searchDropDownList: state.studentListReducer.searchDropDownList,
    isPageLoading: state.studentListReducer.isPageLoading,
    isGridLoading: state.studentListReducer.isGridLoading,
    filterInfo: {
        filterOptions: [
            ...state.filterReducer.chapterList,
            ...state.filterReducer.knowledgeScore,
            ...state.filterReducer.studentTags
        ],
        selectedFilters: state.studentListReducer.selectedFilters
    },
    scoreHistogramData: state.studentListReducer.scoreHistogramData,
    filterUpdated: state.filterReducer.updatedInfo,
    sortBy: state.studentListReducer.sortBy,
    thresholdSettingsData: state.commonReducer.thresholdSettingsData,
    hasAPIError: state.studentListReducer.hasAPIError,
    hasSearchAPIError: state.studentListReducer.hasSearchAPIError
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StudentList);
