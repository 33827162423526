import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, NavLink, useHistory, useLocation } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import parse from 'html-react-parser';
import _isEmpty from 'lodash/isEmpty';

import { mapDispatchToProps } from '../../reducers/utils';
import { MENU_NAVIGATION, ERRORS } from '../../common/constants';
import integrationHelper from '../../common/integrationHelper';
import Topics from '../topics';
import Assignments from '../assignments';
import StudentList from '../student-list';
import StudentDetails from '../student-details';
import ToastMessage from '../toast-message';
import NoCourseError from '../nocourse-error';
import NoTopicsError from '../notopics-error';

import './app.scss';

const App = (props) => {
    const { clientConfig, actions, parentAppData, commonData, hasExportError } = props;
    const history = useHistory();
    const location = useLocation();
    const rootRef = useRef(null);
    let heightObserver = null;

    useEffect(() => {
        actions.updateConfig({ clientConfig, parentAppData });
        actions.getUserMessage();
        initAppMethods();

        return () => {
            actions.commonStoreReset();
            heightObserver && heightObserver.disconnect();
        };
    }, [actions, clientConfig]);

    useEffect(() => {
        if (commonData.configs && commonData.parentAppData) {
            actions.getKnowledgeScore && actions.getKnowledgeScore(commonData);
            actions.initiateFilterData();
        }

        return () => {
            actions.resetFilterData();
        };
    }, [commonData.configs]);

    const initAppMethods = () => {
        integrationHelper.initCallback(redirectPage, 'updateRoute');

        // istanbul ignore else
        if (rootRef && rootRef.current) {
            heightObserver = new MutationObserver(mutationCallback);
            heightObserver.observe(rootRef.current, { childList: true, subtree: true });
        }
    };

    const mutationCallback = () => {
        let customHgt = 5;
        // istanbul ignore else
        if (rootRef.current) {
            customHgt += rootRef.current.clientHeight;
        }
        integrationHelper.sendMessageToPEP({ type: 'setIframeHeight', height: customHgt });
    };

    const onLogout = (e) => {
        e.stopPropagation();
        window.piSession.logout();
    };

    const redirectPage = (tab) => {
        const menuItem = MENU_NAVIGATION.find(m => m.id === tab);
        if (menuItem) {
            history.push(`${menuItem.route}?env=${clientConfig.ENV}`);
        } else if (tab === ERRORS.NOCOURSE_ERROR) {
            history.push(`/nocourse-error?env=${clientConfig.ENV}`);
        } else if (tab === ERRORS.NOTOPICS_ERROR) {
            history.push(`/notopics-error?env=${clientConfig.ENV}`);
        } else {
            history.push(`/?env=${clientConfig.ENV}`);
        }
    };

    const renderUserMessages = () => {
        if (!_isEmpty(commonData.userMessage)) {
            let alertCounter = 1;
            return (
                <nav className='ca-alerts'>
                    {commonData.userMessage.map(({ level, text }, idx) => {
                        alertCounter += idx;
                        return <Alert key={alertCounter} variant={level}>{parse(text)}</Alert>;
                    })}
                </nav>
            );
        }

        return null;
    };

    const handleCloseMessage = (e, actName) => {
        e && e.preventDefault();
        actions[actName] && actions[actName](false);
    };

    const redirectByParent = () => {
        const curTab = parentAppData && parentAppData.custom_deep_link_landing_page;
        if (curTab) {
            const menuItem = MENU_NAVIGATION.find(m => m.id === curTab);
            return <Redirect to={menuItem.route} />;
        }

        return <Redirect to='/' />;
    };

    return (
        <div className='ca-app-container' ref={rootRef}>
            {integrationHelper.get('standalone') && (
                <header>
                    {MENU_NAVIGATION.map(menu => {
                        return (
                            <NavLink key={menu.sortOrder} exact to={`${menu.route}?env=${clientConfig.ENV}`} location={location}>
                                {menu.name}
                            </NavLink>
                        );
                    })}
                    <button type='button' className='btn-logout' onClick={(e) => onLogout(e)}>Sign out</button>
                </header>
            )}
            {renderUserMessages()}
            <main>
                {commonData.settingsSuccess && (
                    <ToastMessage
                        showMessage={commonData.settingsSuccess}
                        handleCloseMessage={(e) => handleCloseMessage(e, 'setSettingsSuccess')}
                        message={(
                            <div>
                                Knowledge group settings updated successfully.
                            </div>
                        )}
                    />
                )}
                {hasExportError && (
                    <ToastMessage
                        isError
                        showMessage={hasExportError}
                        handleCloseMessage={(e) => handleCloseMessage(e, 'setTopicExportError')}
                        message={(
                            <div>
                                Download failed, file location is missing.
                            </div>
                        )}
                    />
                )}
                <Switch>
                    <Route exact path='/' component={Topics} />
                    <Route path='/assignments' component={Assignments} />
                    <Route path='/student-list' component={StudentList} />
                    <Route path='/student-details/:studentId' component={StudentDetails} />
                    <Route path='/nocourse-error' component={NoCourseError} />
                    <Route path='/notopics-error' component={NoTopicsError} />
                    <Route path='*'>
                        {redirectByParent()}
                    </Route>
                </Switch>
            </main>
        </div>
    );
};

App.propTypes = {
    actions: PropTypes.object,
    clientConfig: PropTypes.object,
    commonData: PropTypes.object,
    parentAppData: PropTypes.object,
    handleCloseMessage: PropTypes.func,
    hasExportError: PropTypes.bool
};

App.defaultProps = {
    actions: {},
    clientConfig: {},
    commonData: {},
    parentAppData: {},
    handleCloseMessage: null,
    hasExportError: false
};

const mapStateToProps = (state) => ({
    commonData: state.commonReducer,
    hasExportError: state.commonReducer.hasExportError
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
