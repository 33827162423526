const appObj = {
    cancelTokens: []
};

const set = (key, value) => {
    appObj[key] = value;
};

const get = (key) => appObj[key] || '';

const cancelTokens = () => {
    appObj.cancelTokens.forEach(item => {
        Object.keys(item).forEach(key => item[key]());
    });
    appObj.cancelTokens = [];
};

const commonHelper = {
    set,
    get,
    cancelTokens
};

export default commonHelper;
