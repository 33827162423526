import { bindActionCreators } from 'redux';
import { actions } from './index';

export const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
});

const reducerUtils = {
    mapDispatchToProps
};

export default reducerUtils;
