import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';

import SliderContent from './slider-content';
import { sendGTM, GAprops } from '../../common/utils';
import './style.scss';

class Threshold extends React.Component {
    constructor(props) {
        super(props);
        this.initialSetup = {
            AboveAverage: {
                minValue: 66,
                maxValue: 100,
                setDefaultMaxValue: true,
            },
            Average: {
                minValue: 40,
                maxValue: 65,
            },
            BelowAverage: {
                maxValue: 39,
                minValue: 0,
                setDefaultMinValue: true,
            },
        };
        this.state = !_isEmpty(props.data) ? props.data : this.initialSetup;
    }

    static getDerivedStateFromProps = (nextProps, state) => {
        // istanbul ignore else
        if (nextProps.knowledgeScoreError !== state.knowledgeScoreError) {
            return {
                ...state,
                errorMessage: nextProps.knowledgeScoreError
            };
        }
        return null;
    };

    resetHandler = () => {
        this.setState({ ...this.initialSetup });
        sendGTM({
            eventName: 'knowledgeScoreGroupSettingsReset',
            eventDesc: 'Knowledge score group settings has been reset',
            eventLabel: 'Knowledge score group settings  has been reset',
            page: this.props.page
        });
    };

    handleSave = (taskTd) => {
        const { knowledgeScoreSave } = this.props;
        if (taskTd === 1) {
            const { BelowAverage, Average, AboveAverage } = this.state;
            knowledgeScoreSave && knowledgeScoreSave({ task: 'SAVE', data: this.state });
            sendGTM({
                eventName: 'knowledgeScoreGroupSettingsSave',
                eventDesc: 'Knowledge score group settings has been saved',
                eventLabel: `Below Average: ${BelowAverage.minValue}-${BelowAverage.maxValue}, Above Average ${AboveAverage.minValue}-${AboveAverage.maxValue}, Average ${Average.minValue}-${Average.maxValue}`,
                page: this.props.page
            });
        } else {
            knowledgeScoreSave && knowledgeScoreSave({ task: 'CANCEL', data: this.state });
            sendGTM({
                eventName: 'knowledgeScoreGroupSettingsCancel',
                eventDesc: 'Knowledge score group settings has been cancelled',
                eventLabel: 'Knowledge score group settings  has been cancelled',
                page: this.props.page
            });
        }
    };

    onBelowAverageChange = (minValue, maxValue) => {
        const averageminValue = maxValue + 1;
        // istanbul ignore else
        if (maxValue < this.state.Average.maxValue) {
            this.setState((prevState) => {
                return {
                    BelowAverage: {
                        ...prevState.BelowAverage,
                        minValue: 0,
                        maxValue,
                    },
                    Average: {
                        ...prevState.Average,
                        minValue: averageminValue,
                    },
                };
            });
        } else {
            this.forceUpdate();
        }
    };

    onAverageChange = (minimumValue, maximumValue) => {
        const minValue = minimumValue > 0 ? minimumValue : 1;
        const maxValue = maximumValue < 100 ? maximumValue : 99;
        const belowAveragemaxValue = minValue - 1;
        const AboveAverageminValue = maxValue + 1;
        this.setState((prevState) => {
            return {
                BelowAverage: {
                    ...prevState.BelowAverage,
                    minValue: 0,
                    maxValue: belowAveragemaxValue,
                },
                Average: {
                    ...prevState.Average,
                    minValue,
                    maxValue,
                },
                AboveAverage: {
                    ...prevState.AboveAverage,
                    minValue: AboveAverageminValue,
                    maxValue: 100,
                },
            };
        });
    };

    onAboveAverageChange = (minValue) => {
        const averagemaxValue = minValue - 1;
        // istanbul ignore else
        if (minValue > this.state.Average.minValue) {
            this.setState((prevState) => {
                return {
                    Average: {
                        ...prevState.Average,
                        maxValue: averagemaxValue,
                    },
                    AboveAverage: {
                        ...prevState.AboveAverage,
                        minValue,
                        maxValue: 100,
                    },
                };
            });
        } else {
            this.forceUpdate();
        }
    };

    render() {
        return (
            <Container>
                <div className='container1'>
                    <div className='header'>Customize Knowledge Score group</div>
                    <Row className='show-grid'>
                        <Col xs={10} sm={8}>
                            <div className='Content'>
                                Adjust the sliders below to customize how you view student score
                                groups across the performance dashboard.
                            </div>
                        </Col>
                    </Row>
                    <SliderContent
                        maxValue={this.state.BelowAverage.maxValue}
                        setDefaultMinValue={this.state.BelowAverage.setDefaultMinValue}
                        text='Below Average'
                        bullet='bullet-strugglers'
                        onChange={(mi, ma) => this.onBelowAverageChange(mi, ma)}
                        key='BelowAverage'
                        identifier='BelowAverage'
                    />
                    <div className='EndLine' />
                    <SliderContent
                        minValue={this.state.Average.minValue}
                        maxValue={this.state.Average.maxValue}
                        text='Average'
                        bullet='bullet-at-expectation'
                        onChange={(mi, ma) => this.onAverageChange(mi, ma)}
                        key='Average'
                        identifier='Average'
                    />
                    <div className='EndLine' />
                    <SliderContent
                        minValue={this.state.AboveAverage.minValue}
                        setDefaultMaxValue={this.state.AboveAverage.setDefaultMaxValue}
                        text='Above Average'
                        bullet='bullet-achiever'
                        onChange={(mi, ma) => this.onAboveAverageChange(mi, ma)}
                        key='AboveAverage'
                        identifier='AboveAverage'
                    />
                    <Row className='show-grid buttonGroup'>
                        <Col sm={6} xs={4}>
                            <button
                                type='button'
                                className='linkbutton'
                                onClick={() => this.resetHandler()}
                            >
                                Reset to Defaults
                            </button>
                        </Col>
                        <Col sm={6} xs={8}>
                            <div className='pull-left-sm'>
                                <button
                                    type='button'
                                    className='button cancelbutton'
                                    onClick={() => this.handleSave(0)}
                                    data-testid='knowledge-score-cancel-button'
                                >
                                    Cancel
                                </button>
                                <button
                                    type='button'
                                    className='button savebutton'
                                    onClick={() => this.handleSave(1)}
                                    data-testid='knowledge-score-save-button'
                                >
                                    Save
                                </button>
                            </div>
                        </Col>
                    </Row>
                    {this.state.errorMessage && <div className='error-message'>Something went wrong. Please try again.</div>}
                </div>
            </Container>
        );
    }
}

Threshold.propTypes = {
    knowledgeScoreSave: PropTypes.func,
    data: PropTypes.object,
    knowledgeScoreError: PropTypes.bool,
    page: PropTypes.string
};

Threshold.defaultProps = {
    knowledgeScoreSave: null,
    data: {},
    knowledgeScoreError: false,
    page: GAprops.topicPage
};

export default Threshold;
