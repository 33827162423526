import React from 'react';
import { Row } from 'react-bootstrap';

import WomanChairGrap from '../../images/woman-chair-graphic.png';

import './style.scss';

const NoCourseError = () => {
    return (
        <div className='ca-nocourse-error' data-testid='nocourse-error'>
            <Row>
                <p className='catext-collecting-data'>We’re collecting your course data</p>
            </Row>
            <Row>
                <p className='catext-check-back'>
                    Please check back once it has been 24 hours
                    since students have engaged with their assignments.
                </p>
            </Row>
            <Row>
                <p className='catext-no-data-after'>No data after 24 hours? Please visit Pearson Support.</p>
            </Row>
            <Row>
                <img className='caimg-woman-chair' src={WomanChairGrap} alt='nocourse_error' />
            </Row>
        </div>
    );
};

export default NoCourseError;
