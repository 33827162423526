import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import commonReducer, { actions as commonActions } from './common';
import filterReducer, { actions as filterActions } from './filter';
import topicsReducer, { actions as topicActions } from './topics';
import studentListReducer, { actions as studentListActions } from './studentList';
import studentDetailsReducer, { actions as studentDetailActions } from './studentDetails';
import history from '../common/history';

const rootReducer = combineReducers({
    router: connectRouter(history),
    commonReducer,
    filterReducer,
    topicsReducer,
    studentListReducer,
    studentDetailsReducer
});

export const actions = {
    ...commonActions,
    ...filterActions,
    ...topicActions,
    ...studentListActions,
    ...studentDetailActions
};

export default rootReducer;
