import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import _size from 'lodash/size';

const Axis = (props) => {
    const axisElement = useRef(null);

    useEffect(() => {
        const axisType = `axis${props.orient}`;
        const axis = d3[axisType]()
            .scale(props.scale)
            .tickPadding([props.tickPadding])
            .tickFormat(d3.format('.0f'))
            .tickSizeOuter(0);

        _size(props.tickValues) && axis.tickValues(props.tickValues);
        props.tickSize && axis.tickSize(-props.tickSize);
        if ((props.orient === 'Bottom') && props.hasMorePercent) {
            props.tickSuffix && axis.tickFormat((d) => ((d === 100) ? '>=100%' : (d + props.tickSuffix)));
        } else {
            props.tickSuffix && axis.tickFormat((d) => d + props.tickSuffix);
        }
        d3.select(axisElement.current).call(axis);
        d3.select(axisElement.current).selectAll('g.tick').each((datum, index, nodes) => {
            const node = nodes[index];

            if (props.orient === 'Bottom') {
                d3.select(node).select('line').attr('y1', '-3');
            }

            if (props.hideValues) {
                d3.select(node).select('text').attr('class', 'hide-tick');
            } else {
                d3.select(node).select('text').attr('class', '');
            }
        });
    }, [props]);

    return (
        <g
            className={`Axis Axis-${props.orient}`}
            ref={axisElement}
            transform={props.translate}
            data-testid={`Axis-${props.orient}`}
        />
    );
};

Axis.propTypes = {
    orient: PropTypes.string,
    scale: PropTypes.func,
    tickPadding: PropTypes.number,
    tickValues: PropTypes.array,
    hasMorePercent: PropTypes.number,
    tickSuffix: PropTypes.string,
    translate: PropTypes.string,
    hideValues: PropTypes.bool,
    tickSize: PropTypes.number
};

Axis.defaultProps = {
    orient: '',
    scale: null,
    tickPadding: 0,
    tickValues: [],
    hasMorePercent: 0,
    tickSuffix: '',
    translate: '',
    hideValues: false,
    tickSize: 0
};

export default Axis;
