import PropTypes from 'prop-types';
import ScoreHistogramBuilder from '../score-histogram';
import './index.scss';

const StudentDistributionChart = (props) => {
    const { scoreHistogramData, onBarSelectDeselct, refreshChart } = props;

    return (
        <div className='student-distribution'>
            <ScoreHistogramBuilder
                config={{
                    ...scoreHistogramData,
                    onBarSelectDeselct,
                    refreshChart,
                    toolTipLabel: {
                        single: 'Student',
                        plural: 'Students'
                    }
                }}
            />
        </div>
    );
};

StudentDistributionChart.propTypes = {
    scoreHistogramData: PropTypes.object,
    onBarSelectDeselct: PropTypes.func,
    refreshChart: PropTypes.bool
};

StudentDistributionChart.defaultProps = {
    scoreHistogramData: {},
    onBarSelectDeselct: null,
    refreshChart: false
};

export default StudentDistributionChart;
