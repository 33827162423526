import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { useTable, useResizeColumns, useFlexLayout, useSortBy, usePagination, useRowSelect } from 'react-table';
import { Pagination as PearsonPagination } from '@pearson-components/pagination/build/dist.pagination';
import _size from 'lodash/size';
import _cloneDeep from 'lodash/cloneDeep';

import CustomTooltip from '../custom-tooltip';
import CustomDropdown from '../custom-dropdown';
import './style.scss';

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef();
        const resolvedRef = ref || defaultRef;
        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <div className='checkBox-container'>
                <input type='checkbox' ref={resolvedRef} {...rest} />
                <span>
                    <svg focusable='false' className='pe-icon--check-sm-18' />
                </span>
            </div>
        );
    }
);

const CustomTable = forwardRef((props, ref) => {
    const {
        columns, data, defaultTableOpts, onSort, pagination, cellRenderProps,
        isCheckBoxGrid, onRowSelect, updatePaginationIndex, onHeaderInfoClick,
        fetchData, totalRowCount, onPaginationChange
    } = props;
    const defaultColumn = React.useMemo(() => ({ minWidth: 30, width: 150, maxWidth: 200 }), []);
    const plugins = [useResizeColumns, useFlexLayout, useSortBy, usePagination];
    if (isCheckBoxGrid) {
        plugins.push(useRowSelect);
        plugins.push(hooks => {
            hooks.visibleColumns.push(cols => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: (headerProps) => {
                        const { getToggleAllRowsSelectedProps } = headerProps;
                        return (
                            <div>
                                <IndeterminateCheckbox
                                    {...getToggleAllRowsSelectedProps({
                                        onChange: toggleAllRowOnChange
                                    })}
                                />
                            </div>
                        );
                    },
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: (rowProps) => {
                        const { row } = rowProps;
                        return (
                            <div>
                                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                            </div>
                        );
                    }
                },
                ...cols,
            ]);
        });
    }
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        rows,
        prepareRow,
        setPageSize,
        pageCount,
        gotoPage,
        currentpageIndex,
        state: { pageIndex, pageSize, sortBy, selectedRowIds },
        toggleAllRowsSelected,
        selectedFlatRows,
        toggleRowSelected
    } = useTable({
        columns,
        data,
        defaultColumn,
        getRowId: (row, relativeIndex) => {
            return row.id ? row.id : relativeIndex;
        },
        ...defaultTableOpts
    }, ...plugins);
    const [selectAllRows, setSelectAllRows] = useState(false);

    useEffect(() => {
        onSort && onSort({ sortBy });
    }, [sortBy]);

    useEffect(() => {
        gotoPage(currentpageIndex);
    }, [currentpageIndex]);

    useEffect(() => {
        if (selectAllRows) {
            const selectedRowIsdObj = {};
            const selectedFlatRowsdObj = [];
            data.forEach((itm) => {
                selectedRowIsdObj[itm.id] = true;
                selectedFlatRowsdObj.push({ original: itm });
            });
            onRowSelect && onRowSelect({
                selectedFlatRows: selectedFlatRowsdObj,
                selectedRowIds: selectedRowIsdObj
            }, true);
        }
        toggleAllRowsSelected && toggleAllRowsSelected(selectAllRows);
    }, [selectAllRows]);

    const renderSortOption = (column) => {
        if (column.isSorted) {
            if (column.isSortedDesc) {
                return (<span className='hcol-sort custom-table-sorted-desc' />);
            }

            return (<span className='hcol-sort custom-table-sorted-asc' />);
        }

        return (<span className='hcol-sort custom-table-sort' />);
    };

    const setTriggerClick = (e, isKeyPress, triggerClick) => {
        let _triggerClick = _cloneDeep(triggerClick);
        if (isKeyPress) {
            const keyCode = e.which || e.keyCode;
            // istanbul ignore else
            if ([13, 32].includes(keyCode)) {
                _triggerClick = true;
            }
        } else {
            _triggerClick = true;
        }
        return _triggerClick;
    };

    const onInfoClick = (e, column, isKeyPress = false) => {
        e && e.stopPropagation();
        let triggerClick = false;
        triggerClick = setTriggerClick(e, isKeyPress, triggerClick);

        (triggerClick && onHeaderInfoClick) && onHeaderInfoClick(column);
    };

    const renderInfoIcon = (column) => {
        return (
            <CustomTooltip
                role='link'
                placement='top'
                parentClassName='ct-info-icon'
                tooltipContent={column.infoContent}
                tabIndex='0'
                ariaDescribedBy={column.id}
                onClick={(e) => onInfoClick(e, column)}
                onKeyUp={(e) => onInfoClick(e, column, true)}
            >
                <svg focusable='false' className='pe-icon--help-outline-18'>
                    <use xlinkHref='#info-fill-18' />
                </svg>
            </CustomTooltip>
        );
    };

    const setSelectedPageType = (e, selectedPageType) => {
        let _selectedPageType = _cloneDeep(selectedPageType);
        // istanbul ignore else
        if (e.currentTarget.ariaLabel === 'Previous page') {
            _selectedPageType = 'Previous';
        } else if (e.currentTarget.ariaLabel === 'Next page') {
            _selectedPageType = 'Next';
        }
        return _selectedPageType;
    };

    const renderPagination = () => {
        const { pageSize: pageSizeData } = pagination;
        const rowItmCount = totalRowCount || _size(rows);
        const currentPage = pageIndex + 1;
        const curPageSize = pageSize * currentPage;
        const recordCount = rowItmCount < curPageSize ? rowItmCount : curPageSize;
        return (
            <div className='custom-table-pagination'>
                <CustomDropdown
                    pageSizeRange={pageSizeData.sizes}
                    pageSize={pageSize}
                    onSelect={(item) => {
                        setPageSize(Number(item));
                        fetchData && fetchData({ pageIndex: 0, pageSize: item });
                        updatePaginationIndex && updatePaginationIndex(0,
                            selectedRowIds, currentPage, item);
                        gotoPage(0);
                    }}
                />
                <div className='custom-table-page-info'>Showing {recordCount} of {rowItmCount} records</div>
                <div className={`custom-table-paginate${rowItmCount < pageSize ? ' disable' : ''}`}>
                    <PearsonPagination
                        pages={pageCount}
                        activePage={currentPage}
                        onSelect={(selectedPage, e) => {
                            const pageNo = Number(selectedPage) - 1;
                            fetchData && fetchData({ pageIndex: pageNo, pageSize });
                            gotoPage(pageNo);
                            let selectedPageType = selectedPage;
                            selectedPageType = setSelectedPageType(e, selectedPageType);

                            onPaginationChange && onPaginationChange(selectedPage,
                                selectedPageType, pageSize);
                        }}
                    />
                </div>
            </div>
        );
    };

    const toggleAllRowOnChange = () => {
        setSelectAllRows(prevValue => {
            if (prevValue) {
                onRowSelect && onRowSelect({
                    selectedFlatRows: [],
                    selectedRowIds: {}
                });
            }
            return !prevValue;
        });
    };

    const rowCount = _size(page);

    useImperativeHandle(
        ref,
        () => ({
            deSelectAllRows() {
                toggleAllRowsSelected(false);
                setSelectAllRows(false);
            }
        }),
    );

    const setSelectRowId = (row, deSelectedRow, selectedRows) => {
        selectedFlatRows.forEach(item => {
            if (item.id === row.id) {
                deSelectedRow.push(item);
            } else {
                selectedRows.push(item);
            }
        });
        return [deSelectedRow, selectedRows];
    };

    const onRowClick = (row) => {
        let obj = {};
        if (selectedRowIds && Object.keys(selectedRowIds).includes(row.id.toString())) {
            const [deSelectedRow, selectedRows] = setSelectRowId(row, [], []);
            toggleRowSelected(deSelectedRow[0].id, false);
            delete selectedRowIds[row.id];
            obj = {
                selectedRowIds, selectedFlatRows: selectedRows
            };
        } else if (selectedFlatRows) {
            selectedFlatRows.push(row);
            obj = {
                selectedRowIds: { ...selectedRowIds, [row.id]: true },
                selectedFlatRows
            };
        } else {
            obj = row;
        }
        onRowSelect && onRowSelect(obj);
    };

    const toggleBySortOrder = (keyCode, isSorted, isSortedDesc, column) => {
        // istanbul ignore else
        if ([13, 32].includes(keyCode)) {
            const sortOrder = isSorted ? !isSortedDesc : false;
            column.toggleSortBy(sortOrder);
        }
    };

    return (
        <>
            <Table {...getTableProps()} className='custom-table'>
                <thead data-testid='table-head'>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, index) => {
                                const sortProps = {};
                                const { canSort, isSorted, isSortedDesc } = column;
                                if (canSort) {
                                    sortProps.tabIndex = '0';
                                    sortProps.onKeyUp = (e) => {
                                        e && e.stopPropagation();
                                        const keyCode = e.which || e.keyCode;
                                        // istanbul ignore else
                                        toggleBySortOrder(keyCode, isSorted, isSortedDesc, column);
                                    };
                                }
                                return (
                                    <th {...column.getHeaderProps()}>
                                        <span {...column.getSortByToggleProps(sortProps)} className={isCheckBoxGrid && index === 0 ? '' : 'custom-table-hcol noselect'}>
                                            <span className={`hcol-title${isSorted ? ' fontWeightBold' : ''}`}>
                                                {column.render('Header')}
                                                {
                                                    column.showcoachMark
                                                    && <span id={column.coachMarkId}><button className='d-none' type='button'>{column.coachMarkId}</button></span>
                                                }
                                            </span>
                                            {column.isInfoIcon && renderInfoIcon(column)}
                                            {canSort && renderSortOption(column)}
                                        </span>
                                        {column.canResize && (
                                            <div {...column.getResizerProps()} className={`resizer ${column.isResizing ? 'isResizing' : ''}`} />
                                        )}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()} data-testid='table-body'>
                    {(rowCount > 0) && page.map(row => {
                        const otherRowProps = { className: '' };
                        const rowClsName = [];
                        if (onRowSelect) {
                            otherRowProps.onClick = () => onRowClick(row);
                            rowClsName.push('has-hover');
                        }
                        if (row.isSelected) {
                            rowClsName.push('rowSelected');
                        }
                        otherRowProps.className = rowClsName.join(' ');
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps(otherRowProps)}>
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell', cellRenderProps)}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            {(rowCount > 0) && renderPagination()}
        </>
    );
});

CustomTable.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    defaultTableOpts: PropTypes.object.isRequired,
    onSort: PropTypes.func,
    pagination: PropTypes.object,
    cellRenderProps: PropTypes.object,
    getToggleAllRowsSelectedProps: PropTypes.func,
    row: PropTypes.func,
    isCheckBoxGrid: PropTypes.bool,
    onRowSelect: PropTypes.func,
    updatePaginationIndex: PropTypes.func,
    onHeaderInfoClick: PropTypes.func,
    fetchData: PropTypes.func,
    totalRowCount: PropTypes.number,
    page: PropTypes.string,
    onPaginationChange: PropTypes.func
};

CustomTable.defaultProps = {
    onSort: null,
    cellRenderProps: {},
    pagination: {
        pageSize: {
            sizes: [10, 25, 50],
            placeholder: 'Page Size'
        }
    },
    getToggleAllRowsSelectedProps: null,
    row: null,
    isCheckBoxGrid: false,
    onRowSelect: null,
    updatePaginationIndex: null,
    onHeaderInfoClick: null,
    fetchData: null,
    totalRowCount: 0,
    page: '',
    onPaginationChange: null
};

IndeterminateCheckbox.propTypes = {
    indeterminate: PropTypes.bool
};

IndeterminateCheckbox.defaultProps = {
    indeterminate: false
};

export default CustomTable;
