import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, ProgressBar, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import _isNumber from 'lodash/isNumber';
import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';
import _round from 'lodash/round';
import _pick from 'lodash/pick';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import CoachMark from '@pearson-components/coach-mark/build/dist.coach-mark';

import {
    secondsToHms, getStyledKnowledgeScore, getSelectedFilterInfo,
    getPageCount, GAprops, sendGTM, renderKnowledgeScoreInfoContent,
    renderStudentKnowledgeInfoContent, renderErrorComp
} from '../../common/utils';
import { mapDispatchToProps } from '../../reducers/utils';
import integrationHelper from '../../common/integrationHelper';
import { saveDataInLocalStorage, getDataFromLocalStorage } from '../../common/localstorageHelper';
import KnowledgeScoreSettings from '../knowledge-group-settings';
import CustomTable from '../custom-table';
import CustomSearch from '../custom-search';
import CustomFilter from '../custom-filter';
import TopicPerformance from '../topic-performance';
import CourseKnowledgeLevel from '../course-knowledge-level';
import InitialCoachMark from '../initial-coach-mark';
import CustomTooltip from '../custom-tooltip';
import Shadow, { ShadowTableGrid } from '../shadow';

import './topics.scss';

const Topics = props => {
    const history = useHistory();
    const [showModalData, setShowModalData] = useState(false);
    const { actions, topicsList, filterInfo, isPageLoading, topicDistribution,
        thresholdSettingsData, commonData, filterUpdated, listInfo, isGridLoading } = props;
    const [showInitalCoachMarkModal, setShowInitalCoachMarkModal] = useState(false);
    const [currentCoachMark, setcurrentCoachMark] = useState('');
    const [componentMounted, setComponentMounted] = useState(false);

    const coachMarkOrder = ['initial', 'knowledgeScore', 'knowledgeGroup', 'topicCoach'];
    const coachMarks = {
        knowledgeScore: { id: 'knowledgeScore', title: '<span class="coachmark-title">Calculating a Knowledge Score<span>', text: '<span class="coachmark-text">Using factors like correct on first try and correct attempt rate at the question level, we are calculating a knowledge score to show you how your students are understanding the topics.</span>', offsetX: -30, offsetY: -15 },
        knowledgeGroup: { id: 'knowledgeGroup', title: '<span class="coachmark-title">Filter to see struggling topics<span>', text: '<span class="coachmark-text">Select a knowledge score group to see the topics within each category.</span>', offsetX: 90, offsetY: 0 },
        topicCoach: { id: 'topicCoach', title: '<span class="coachmark-title">Dig into the Content Topics<span>', text: '<span class="coachmark-text">Select an individual topic to see how students are scoring at the topic level.</span>', offsetX: -10, offsetY: 0 }
    };

    useEffect(() => {
        if (componentMounted) {
            // istanbul ignore else
            if (!getDataFromLocalStorage('topicsCoachMark')) {
                setShowInitalCoachMarkModal(true);
            } else {
                const selectedCoachMark = getDataFromLocalStorage('topicsCoachMark');
                // istanbul ignore else
                if (_size(selectedCoachMark) < _size(coachMarkOrder)) {
                    setcurrentCoachMark(coachMarkOrder[selectedCoachMark.length]);
                    // istanbul ignore else
                    if (!isGridLoading) {
                        coachMarkMounting(coachMarkOrder[selectedCoachMark.length]);
                    } else {
                        removeCoachMark();
                    }
                }
            }
        }
    }, [isGridLoading, isPageLoading]);

    // Used as constructor
    useEffect(() => {
        const { configs } = commonData;
        let selectedCoachMark = getDataFromLocalStorage('topicsCoachMark');
        if (!configs.SHOWINITIALCOACHMARK && !selectedCoachMark) {
            saveDataInLocalStorage('topicsCoachMark', ['initial']);
        }
        if (!getDataFromLocalStorage('topicsCoachMark')) {
            setShowInitalCoachMarkModal(true);
        } else {
            selectedCoachMark = getDataFromLocalStorage('topicsCoachMark');
            // istanbul ignore else
            if (_size(selectedCoachMark) < _size(coachMarkOrder)) {
                setcurrentCoachMark(coachMarkOrder[selectedCoachMark.length]);
                const coachAll = document.querySelectorAll('[data-id]');
                if (!_isEmpty(coachAll)) {
                    setTimeout(() => {
                        coachMarkMounting(coachMarkOrder[selectedCoachMark.length]);
                    }, 500);
                }
            }
        }
        setComponentMounted(true);

        return () => {
            removeCoachMark();
            actions.resetTopicsData && actions.resetTopicsData();
        };
    }, []);

    // Used to trigger API after getting threshold and filter data
    useEffect(() => {
        let storageFilters = sessionStorage.getItem('selectedFilters');
        storageFilters = storageFilters ? JSON.parse(storageFilters).topicsFilter : null;

        // istanbul ignore else
        if (filterUpdated && !commonData.topicsClsAvg) {
            actions.getCourseKnowledgeLevel?.(commonData, storageFilters, 'topic');
        }

        // istanbul ignore else
        if (filterUpdated) {
            if (!_isEmpty(storageFilters)) {
                const selFilters = getSelectedFilterInfo(storageFilters, filterInfo.filterOptions);
                actions.updateSelectedFilters(selFilters, true);
            }

            actions.getTopicsData?.(false,
                { offset: 1, limit: listInfo.pagination.limit },
                GAprops.initialLoad);
        }
    }, [filterUpdated]);

    useEffect(() => {
        if (commonData.settingsSuccess) {
            setShowModalData(false);
            actions.getTopicsData?.(false, { offset: 1, limit: listInfo.pagination.limit }, null);
        }
    }, [commonData.settingsSuccess]);

    const onSearchTopics = (value) => {
        actions?.setTopicsSearch(value);
        if (_isEmpty(value) || (_size(value) > 2)) {
            const type = _isEmpty(value) ? GAprops.onSearchClear : GAprops.onSearch;
            actions.getTopicsData?.(false,
                { offset: 1, limit: listInfo.pagination.limit }, type, true);
        }
    };

    const onSortTopics = (value) => {
        actions.setTopicsSortBy && actions.setTopicsSortBy(value);
        if (!_isEqual(listInfo.sortBy[0], value[0])) {
            actions.getTopicsData?.(false,
                { offset: 1, limit: listInfo.pagination.limit }, null, true);
            sendGTM({
                eventName: 'topicsGridSort',
                eventDesc: 'Topics grid sorted',
                eventLabel: `field :${value[0].id}, sortDirection: ${value[0].desc ? 'DESC' : 'ASC'}`,
                page: GAprops.topicPage
            });
        }
    };

    const onRowSelect = (row) => {
        const { id, topic } = row.original;
        const { configs } = commonData;
        actions.updateStuListSelectedFilters([{ id, title: topic, type: 'TOPIC' }]);
        history.push(`/student-list?env=${configs.ENV}`);
        integrationHelper.sendMessageToPEP({ type: 'scrollToTop' });
        integrationHelper.changeParentTab('DATACARD_3');
    };

    const renderStudentAttempted = ({ studentAttempted }) => {
        if (!studentAttempted || !studentAttempted.totalAttempts) {
            return '--';
        }
        return `${studentAttempted.totalAttempts}/${studentAttempted.totalStudents}`;
    };

    const renderknowledgeScore = ({ knowledgeScore }) => {
        return getStyledKnowledgeScore(knowledgeScore, thresholdSettingsData);
    };

    const renderStudentKnowledge = ({ studentKnowledge }) => {
        if (!studentKnowledge) {
            return (
                <div>--</div>
            );
        }
        let maxVal = 0;
        Object.keys(studentKnowledge).forEach(sk => {
            // istanbul ignore else
            if (_isNumber(studentKnowledge[sk])) {
                maxVal += studentKnowledge[sk];
            }
        });
        // istanbul ignore else
        if (!maxVal) {
            return (
                <div>--</div>
            );
        }
        const tooltipContent = (
            <div>
                {(studentKnowledge.aboveAverage > 0)
                    && <div>Above Average: {studentKnowledge.aboveAverage}% </div>}
                {(studentKnowledge.average > 0) && <div>Average: {studentKnowledge.average}% </div>}
                {(studentKnowledge.belowAverage > 0)
                    && <div>Below Average: {studentKnowledge.belowAverage}% </div>}
            </div>
        );

        return (
            <CustomTooltip role='tooltip' placement='top' tooltipContent={tooltipContent} tabIndex='0'>
                <ProgressBar>
                    {(studentKnowledge.belowAverage > 0) && <ProgressBar variant='danger' now={studentKnowledge.belowAverage} key={1} max={maxVal} />}
                    {(studentKnowledge.average > 0) && <ProgressBar variant='warning' now={studentKnowledge.average} key={2} max={maxVal} />}
                    {(studentKnowledge.aboveAverage > 0) && <ProgressBar variant='success' now={studentKnowledge.aboveAverage} key={3} max={maxVal} />}
                </ProgressBar>
            </CustomTooltip>
        );
    };

    const renderTopic = item => {
        // istanbul ignore else
        if (_size(item.searchText) < 3) {
            return <span className='topic-grid-title has-link' title={item.value}>{item.value}</span>;
        }

        const repSearchText = item.searchText.replace(/[.*+\-?^${}()|[\]\\]/g, '');
        const parts = item.value.split(new RegExp(`(${repSearchText})`, 'gi'));
        let topicCounter = 1;
        return (
            <span className='topic-grid-title' title={item.value}>
                {parts.map((part, i) => {
                    topicCounter += i;
                    return (
                        <span key={`part-${topicCounter}`} id='topics-name' className={part.toLowerCase() === item.searchText.toLowerCase() ? 'highlight-text' : ''}>
                            {part}
                        </span>
                    );
                })}
            </span>
        );
    };

    const onHelpIconClick = () => {
        sendGTM({
            eventName: 'topicsHelpIconClicked',
            eventDesc: 'Topics help icon clicked',
            page: GAprops.topicPage
        });
    };

    const renderHelpIcon = () => {
        const { configs } = commonData;
        if (configs && configs.HELP_WORKFLOW_URL_TOPIC) {
            return (
                <a className='help-icon' href={configs.HELP_WORKFLOW_URL_TOPIC} target='_blank' rel='noreferrer' aria-label='Topics Help' onClick={onHelpIconClick}>
                    <svg focusable='false' className='pe-icon--help-outline-18'>
                        <use xlinkHref='#help-outline-18' />
                    </svg>
                </a>
            );
        }

        return null;
    };

    const columns = React.useMemo(() => [
        { Header: 'Topic', id: 'id', minWidth: 100, width: 150, accessor: 'topic', Cell: renderTopic, showcoachMark: currentCoachMark === 'topicCoach', coachMarkId: 'topicCoach' },
        { Header: 'Knowledge Score', id: 'knowledgeScore', width: 80, accessor: renderknowledgeScore, isInfoIcon: true, infoContent: renderKnowledgeScoreInfoContent(), showcoachMark: currentCoachMark === 'knowledgeScore', coachMarkId: 'knowledgeScore' },
        { Header: 'Student Knowledge', id: 'studentKnowledge', minWidth: 85, width: 90, disableSortBy: true, defaultCanSort: false, accessor: renderStudentKnowledge, isInfoIcon: true, infoContent: renderStudentKnowledgeInfoContent() },
        { Header: 'Student Attempted', id: 'studentsAttempted', width: 85, accessor: renderStudentAttempted },
        { Header: 'Average Duration', id: 'timeTaken', width: 100, accessor: ({ avgDuration }) => secondsToHms(avgDuration) },
        { Header: 'Correct on First Try', id: 'cftr', width: 80, accessor: ({ correctFirstTry }) => (_isNumber(correctFirstTry) ? `${_round(correctFirstTry)}%` : '--') }
    ], [thresholdSettingsData, currentCoachMark]);

    // Export List
    const triggerExport = () => {
        sendGTM({
            eventName: 'topicsListExport',
            eventDesc: 'Topics list export',
            eventLabel: `exported ${listInfo.pagination.total} rows`,
            page: GAprops.topicPage
        });
        actions.getExportData && actions.getExportData();
    };

    const coachMarkMounting = (index) => {
        return new CoachMark({
            elementId: coachMarks[index].id,
            opts: {
                id: `CM_${coachMarks[index].id}`,
                title: `<span class="coachmark-title">${coachMarks[index].title}<span>`,
                text: `<span class="coachmark-text">${coachMarks[index].text}</span>`,
                gotIt: true,
                showClose: false,
                forceAbove: true,
                stopScroll: true,
                offsetX: coachMarks[index].offsetX,
                offsetY: coachMarks[index].offsetY,
                zIndex: 900,
            },
            callback: () => {
                const nextIndex = coachMarkOrder.indexOf(index) + 1;
                const selectedCoachMark = getDataFromLocalStorage('topicsCoachMark');
                const newselectedCoachMark = [...selectedCoachMark, index];
                saveDataInLocalStorage('topicsCoachMark', newselectedCoachMark);

                if (coachMarkOrder[nextIndex]) {
                    setcurrentCoachMark(coachMarkOrder[nextIndex]);
                    setTimeout(() => {
                        coachMarkMounting(coachMarkOrder[nextIndex]);
                    }, 500);
                }
            }
        });
    };

    const removeCoachMark = () => {
        const coachAll = document.querySelectorAll('[data-id]');
        coachAll.forEach((coach) => {
            ReactDOM.unmountComponentAtNode(coach);
            coach.remove();
        });
    };

    // Default Table Options
    const defaultTableOpts = {
        disableSortBy: false,
        disableMultiSort: true,
        manualSortBy: true,
        manualPagination: true,
        disableSortRemove: true,
        autoResetSortBy: false,
        autoResetPage: false,
        currentpageIndex: listInfo.pagination.offset - 1,
        pageCount: getPageCount(listInfo.pagination),
        initialState: {
            sortBy: listInfo.sortBy
        }
    };

    // Props to access on manual render columns
    const cellRenderProps = { searchText: listInfo.searchText };

    const fetchData = useCallback(({ pageSize, pageIndex }) => {
        actions.getTopicsData?.(false, {
            offset: pageIndex + 1, limit: pageSize
        }, null, true);
    }, []);

    const showKnowledgeScoreModal = () => {
        setShowModalData(true);
        actions.setSettingsSuccess && actions.setSettingsSuccess();
        sendGTM({
            eventName: 'knowledgeScoreGroupSettingsOpen',
            eventDesc: 'Knowledge score group settings opened',
            eventLabel: 'Knowledge score group settings opened',
            page: GAprops.topicPage
        });
    };

    const saveKnowledgeScore = (data) => {
        actions.saveKnowledgeScore
            && actions.saveKnowledgeScore(data, commonData);
    };

    const knowledgeScoreSave = (data) => {
        // istanbul ignore else
        if (data.task === 'SAVE') {
            saveKnowledgeScore(data);
        } else if (data.task === 'CANCEL') {
            setShowModalData(false);
        }
    };

    const filterUpdate = (topicsFilter) => {
        actions.updateSelectedFilters(topicsFilter);
        actions.getCourseKnowledgeLevel(commonData, topicsFilter, 'topic');
        actions?.getTopicsData(false, { offset: 1, limit: listInfo.pagination.limit });
    };

    const handleClose = () => {
        setShowModalData(!showModalData);
    };

    const updateSelectedFiltersPerformance = (perfFilter, isFiltered) => {
        let selectedFilters = _cloneDeep(filterInfo.selectedFilters);
        const [selectedItem] = perfFilter;
        if (isFiltered) {
            selectedFilters = selectedFilters.filter((item) => item.id !== selectedItem.id);
        } else {
            selectedFilters.push(selectedItem);
        }
        actions.updateSelectedFilters(selectedFilters);
        actions?.getTopicsData(true, { offset: 1, limit: listInfo.pagination.limit });
        sendGTM({
            eventName: 'topicGroupings',
            eventDesc: 'Filter topics by groupings',
            eventLabel: `${perfFilter[0]?.title}`,
            page: GAprops.topicPage
        });
    };

    const tooltip = <Tooltip id='topics-setting' className='common-tooltip'>Topics Setting</Tooltip>;
    const tooltipExport = <Tooltip id='export-csv' className='common-tooltip'>Export Topics</Tooltip>;
    const noDataMessage = <div className='no-data-message'>There are no topics found for current search.</div>;
    const actualDataCount = listInfo.pagination.total;
    const isLoading = isPageLoading === 'true';
    const listGridCls = ['topics-list-grid'];
    if (isGridLoading) {
        listGridCls.push('visibility-hidden');
    }

    return (
        <div className='topics-container'>
            {isLoading && <Shadow pageName='topics-overview' />}
            <div className={!isLoading ? '' : 'visibility-hidden'}>
                <div className='topics-filter'>
                    <CustomFilter
                        addFilterText='Add Filter'
                        filterOptions={filterInfo.filterOptions}
                        selectedFilters={filterInfo.selectedFilters}
                        onFilterUpdate={filterUpdate}
                        page={GAprops.topicPage}
                    />
                </div>
                <Row>
                    <Col sm={11}>
                        <div className='topics-title'>
                            <h1 className='heading-h1'>Topics {renderHelpIcon()}</h1>
                        </div>
                    </Col>
                    <Col sm={1} className='text-right'>
                        <OverlayTrigger placement='bottom' trigger={['hover', 'focus']} overlay={tooltip}>
                            <button type='button' tabIndex='0' aria-label='Topics Setting' className='btn-setting' data-testid='btn-setting' onClick={() => showKnowledgeScoreModal()} />
                        </OverlayTrigger>
                    </Col>
                </Row>
                <div className='topics-overview'>
                    <div className='topics-overview-title'>
                        <h2 className='heading-h2'>Topic Analysis</h2>
                    </div>
                    <p className='topics-overview-description'>
                        Topic Analysis shows you how well students understand the learning
                        objectives in your course based on their performance on homework,
                        quizzes and tests, enabling you to target your instruction or provide
                        personalized attention.
                    </p>
                </div>
                <div className='topics-info'>
                    <Row>
                        <Col sm={6}>
                            <CourseKnowledgeLevel
                                thresholdSettingsData={thresholdSettingsData}
                                knowledgeScore={commonData.topicsClsAvg !== null
                                    ? _round(commonData.topicsClsAvg * 100) : null}
                                showError={commonData.classAvgError}
                            />
                        </Col>
                        <Col sm={6}>
                            <TopicPerformance
                                perfData={topicDistribution}
                                onFilterUpdate={updateSelectedFiltersPerformance}
                                selectedFilters={filterInfo.selectedFilters}
                                showCoachMark={currentCoachMark === 'knowledgeGroup'}
                                coachMarkId='knowledgeGroup'
                                showError={listInfo.hasAPIError}
                                page={GAprops.topicPage}
                            />
                        </Col>
                    </Row>
                </div>
                {showInitalCoachMarkModal
                    && (
                        <InitialCoachMark
                            showInitalCoachMarkModal={showInitalCoachMarkModal}
                            setShowInitalCoachMarkModal={setShowInitalCoachMarkModal}
                            setcurrentCoachMark={setcurrentCoachMark}
                            coachMarkMounting={coachMarkMounting}
                            coachMarkOrder={coachMarkOrder}
                        />
                    )}
                <div className='topics-list'>
                    <Row className='topics-list-header'>
                        <Col className='topics-list-info' data-testid='topic-count'>
                            <h3>{actualDataCount} {(actualDataCount === 1) ? 'topic' : 'topics'}</h3>
                        </Col>
                        <Col className='topics-list-actions text-right'>
                            <CustomSearch
                                searchText={listInfo.searchText}
                                placeholder='Search Topics'
                                onSearch={(value) => onSearchTopics(value)}
                            />
                            <OverlayTrigger placement='bottom' trigger={['hover', 'focus']} overlay={tooltipExport}>
                                <button
                                    type='button'
                                    tabIndex='0'
                                    className='btn-export'
                                    aria-labelledby='export button'
                                    onClick={() => triggerExport()}
                                    data-testid='export-btn'
                                    disabled={actualDataCount === 0}
                                />
                            </OverlayTrigger>
                            {showModalData && (
                                <Modal className='threshold_wrapper knowledge-score-madal' data-testid='topics-knowledgescore-modal' show={showModalData} onHide={handleClose}>
                                    <Modal.Body>
                                        <KnowledgeScoreSettings
                                            data={thresholdSettingsData}
                                            knowledgeScoreSave={knowledgeScoreSave}
                                            knowledgeScoreError={commonData.knowledgeGroupSaveError}
                                            page={GAprops.topicPage}
                                        />
                                    </Modal.Body>
                                </Modal>
                            )}
                        </Col>
                    </Row>
                    {isGridLoading && (
                        <div className='shadow-container'>
                            <ShadowTableGrid />
                        </div>
                    )}
                    <div className={listGridCls.join(' ')}>
                        {!listInfo.hasAPIError && (
                            <CustomTable
                                data={topicsList}
                                columns={columns}
                                defaultTableOpts={defaultTableOpts}
                                onSort={sortData => onSortTopics(sortData.sortBy)}
                                cellRenderProps={cellRenderProps}
                                fetchData={fetchData}
                                onRowSelect={onRowSelect}
                                totalRowCount={actualDataCount}
                                page={GAprops.topicPage}
                                updatePaginationIndex={(_pageIndex,
                                    _selectedRowIds,
                                    currentPage,
                                    numberofRecords) => {
                                    sendGTM({
                                        eventName: 'recordsPerPageListChange',
                                        eventDesc: 'Records Per Page List Change',
                                        eventLabel: `currentPageNo: ${currentPage},  recordsPerPage:  ${numberofRecords}`,
                                        page: GAprops.topicPage
                                    });
                                }}
                                onPaginationChange={(selectedPage, selectedPageType, pageSize) => {
                                    sendGTM({
                                        eventName: 'topicsPageChange',
                                        eventDesc: 'Page Change',
                                        eventLabel: `currentPageNo: ${selectedPage},  recordsPerPage:  ${pageSize}`,
                                        button: selectedPageType,
                                        page: GAprops.topicPage
                                    });
                                }}
                            />
                        )}
                        {listInfo.hasAPIError && renderErrorComp()}
                        {((actualDataCount === 0) && !listInfo.hasAPIError) && noDataMessage}
                    </div>
                </div>
            </div>
        </div>
    );
};

Topics.propTypes = {
    commonData: PropTypes.object.isRequired,
    actions: PropTypes.object,
    topicsList: PropTypes.array,
    topicDistribution: PropTypes.array,
    isPageLoading: PropTypes.string,
    isGridLoading: PropTypes.bool,
    listInfo: PropTypes.shape({
        searchText: PropTypes.string,
        sortBy: PropTypes.array,
        pagination: PropTypes.object,
        hasAPIError: PropTypes.bool
    }),
    filterInfo: PropTypes.shape({
        filterOptions: PropTypes.array,
        selectedFilters: PropTypes.array
    }),
    thresholdSettingsData: PropTypes.object,
    filterUpdated: PropTypes.number
};

Topics.defaultProps = {
    actions: {},
    topicsList: [],
    topicDistribution: [],
    isPageLoading: 'true',
    isGridLoading: false,
    listInfo: {
        searchText: '',
        sortBy: [{ id: 'id', desc: false }],
        pagination: {},
        hasAPIError: false
    },
    filterInfo: {
        filterOptions: [],
        selectedFilters: []
    },
    thresholdSettingsData: {},
    filterUpdated: null
};

const mapStateToProps = (state) => ({
    commonData: state.commonReducer,
    topicsList: state.topicsReducer.topicsList,
    topicDistribution: state.topicsReducer.topicDistribution,
    isPageLoading: state.topicsReducer.isPageLoading,
    isGridLoading: state.topicsReducer.isGridLoading,
    listInfo: _pick(state.topicsReducer, ['searchText', 'sortBy', 'pagination', 'hasAPIError']),
    filterInfo: {
        filterOptions: [...state.filterReducer.chapterList, ...state.filterReducer.knowledgeScore],
        selectedFilters: state.topicsReducer.selectedFilters
    },
    thresholdSettingsData: state.commonReducer.thresholdSettingsData,
    filterUpdated: state.filterReducer.updatedInfo
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Topics);
