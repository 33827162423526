import React from 'react';
import PropTypes from 'prop-types';

export default class Track extends React.Component {
    render() {
        const { color, y, fromX, toX, lineWidth, events } = this.props;
        return (
            <line
                x1={fromX}
                x2={toX}
                y1={y}
                y2={y}
                strokeWidth={lineWidth}
                stroke={color}
                strokeLinecap='round'
                {...events}
            />
        );
    }
}

Track.propTypes = {
    color: PropTypes.string,
    y: PropTypes.number,
    fromX: PropTypes.number,
    toX: PropTypes.number,
    lineWidth: PropTypes.number,
    events: PropTypes.object
};

Track.defaultProps = {
    color: '',
    y: 0,
    fromX: 0,
    toX: 0,
    lineWidth: 0,
    events: {}
};
