import { makeAPICall, formatCourseStudentId } from '../common/utils';
import { thresholdSettingsSchema, courseKnowledgeLevelQuery } from '../common/schemaHelper';

const COMMON_STORE_RESET = 'common/STORE_RESET';
const COMMON_UPDATE_CONFIG = 'common/COMMON_UPDATE_CONFIG';
const COMMON_UPDATE_USERMESSAGE = 'common/COMMON_UPDATE_USERMESSAGE';
const GET_THRESHOLD_DATA = 'topics/GET_THRESHOLD_DATA';
const SAVE_THRESHOLD_DATA = 'topics/SAVE_THRESHOLD_DATA';
const SET_SAVE_SUCCESS = 'topics/SET_SAVE_SUCCESS';
const ERROR_THRESHOLD_DATA = 'topics/ERROR_THRESHOLD_DATA';
const GET_CLASS_AVG_TOPICS = 'topics/GET_CLASS_AVG_TOPICS';
const ERROR_GET_CLASS_AVG = 'topics/ERROR_GET_CLASS_AVG';
const EXPORT_ERROR_MESSAGE = 'common/EXPORT_ERROR_MESSAGE';

const initialState = {
    configs: null,
    parentAppData: null,
    userMessage: [],
    thresholdSettingsData: {
        AboveAverage: {
            minValue: 66,
            maxValue: 100,
            setDefaultMaxValue: true,
        },
        Average: {
            minValue: 40,
            maxValue: 65,
        },
        BelowAverage: {
            maxValue: 39,
            minValue: 0,
            setDefaultMinValue: true,
        }
    },
    settingsSuccess: false,
    knowledgeGroupSaveError: false,
    topicsClsAvg: null,
    classAvgError: false,
    hasExportError: false
};

const updateConfig = (data) => {
    const configData = { ...data.clientConfig };
    const pepData = data.parentAppData;
    if (pepData) {
        if (pepData.subPlatformId) {
            configData.LPSID = pepData.subPlatformId;
        }

        if (pepData.custom_is_demo === 'true') {
            configData.API_URL = configData.DEMO_API_URL;
        }

        if (pepData.courseInfo) {
            configData.COURSE_IDS = [
                { courseId: formatCourseStudentId(configData, pepData.courseInfo.id, 'course') }
            ];
            const courseDetails = [];
            configData.COURSE_IDS.forEach((item) => {
                const courseInfo = {
                    courseId: item.courseId,
                    platformid: configData.LPID,
                    subPlatformId: pepData.subPlatformId
                };
                courseDetails.push(courseInfo);
            });
            sessionStorage.setItem('courseDetails', JSON.stringify(courseDetails));
        }
    }
    return {
        type: COMMON_UPDATE_CONFIG,
        payload: {
            clientConfig: configData,
            parentAppData: pepData
        }
    };
};

const commonStoreReset = () => ({ type: COMMON_STORE_RESET });

const getUserMessage = () => {
    return async (dispatch) => {
        try {
            const msgUrl = process.env.NODE_ENV === 'development' ? '/content-analysis/notifications.json' : '/content-analysis/notifications';
            const resp = await makeAPICall(msgUrl, null, 'GET');
            if (resp.status === 200 && resp.data) {
                dispatch({ type: COMMON_UPDATE_USERMESSAGE, payload: resp.data });
            } else {
                dispatch({ type: COMMON_UPDATE_USERMESSAGE, payload: [] });
            }
        } catch (e) {
            dispatch({ type: COMMON_UPDATE_USERMESSAGE, payload: [] });
        }
    };
};

const groupingData = (data) => {
    return (dispatch) => {
        const thValues = {};
        JSON.parse(data.mergedJson).thresholds.forEach((obj) => {
            switch (obj.name) {
            case 'Low':
                thValues.BelowAverage = {
                    maxValue: (obj.range.upper.limit !== undefined
                        && obj.range.upper.limit !== null) ? obj.range.upper.limit : 69,
                    minValue: 0,
                    setDefaultMinValue: true
                }; break;
            case 'Medium':
                thValues.Average = {
                    minValue: (obj.range.lower.limit !== undefined
                        && obj.range.lower.limit !== null) ? obj.range.lower.limit : 70,
                    maxValue: (obj.range.upper.limit !== undefined
                        && obj.range.upper.limit !== null) ? obj.range.upper.limit : 89
                }; break;
            case 'High':
                thValues.AboveAverage = {
                    minValue: (obj.range.lower.limit !== undefined
                        && obj.range.lower.limit !== null) ? obj.range.lower.limit : 90,
                    maxValue: 100,
                    setDefaultMaxValue: true
                }; break;
            default: break;
            }
        });
        dispatch({ type: GET_THRESHOLD_DATA, payload: thValues });
    };
};

const getKnowledgeScore = (commonData) => {
    return async (dispatch) => {
        try {
            const getUrl = `${commonData.configs.PERFORMANCE_API_URL}/knowledgeGroupSettingsRoute/${commonData.configs.LPID}/${commonData.configs.LPSID}/${commonData.configs.COMPOSITE_APP_NAME}/${commonData.configs.ROLE}/${window.piSession.userId()}`;
            const resp = await makeAPICall(getUrl, null, 'GET');
            if (resp.status === 200 && resp.data) {
                dispatch(groupingData(resp.data));
            }
        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                /* eslint-disable-next-line no-console */
                console.log('Common Error:', e);
            }
        }
    };
};

const saveKnowledgeScore = (data, commonData) => {
    return async (dispatch) => {
        try {
            const thresholdData = thresholdSettingsSchema(data.data);
            const postUrl = `${commonData.configs.PERFORMANCE_API_URL}/knowledgeGroupSettingsSave/${commonData.configs.LPID}/${commonData.configs.LPSID}/${commonData.configs.COMPOSITE_APP_NAME}/${commonData.configs.ROLE}/${window.piSession.userId()}`;
            const putResp = await makeAPICall(postUrl, thresholdData, 'POST');
            if ((putResp.status === 200 || putResp.status === 201) && putResp.data) {
                dispatch({ type: SAVE_THRESHOLD_DATA, payload: data.data });
            }
        } catch (e) {
            dispatch({ type: ERROR_THRESHOLD_DATA });
        }
    };
};

const getCourseKnowledgeLevel = (commonData, topicsFilter = null) => {
    return async (dispatch) => {
        try {
            const query = courseKnowledgeLevelQuery(topicsFilter, commonData.configs);
            const postUrl = commonData.configs.API_URL;
            const resp = await makeAPICall(postUrl, query, 'POST');
            if (resp.status === 200 && resp.data) {
                dispatch({
                    type: GET_CLASS_AVG_TOPICS,
                    payload: resp.data.data.courseSummary.knowledgeInfo
                });
            }
        } catch (e) {
            dispatch({ type: ERROR_GET_CLASS_AVG });
        }
    };
};

const setSettingsSuccess = () => {
    return { type: SET_SAVE_SUCCESS };
};

const commonReducer = (state = initialState, action) => {
    const { type, payload } = action;
    let nextState;
    switch (type) {
    case COMMON_UPDATE_CONFIG:
        nextState = {
            ...state,
            configs: payload.clientConfig,
            parentAppData: payload.parentAppData
        };
        return nextState;
    case COMMON_UPDATE_USERMESSAGE:
        nextState = { ...state, userMessage: payload };
        return nextState;
    case COMMON_STORE_RESET:
        return initialState;
    case GET_THRESHOLD_DATA:
        nextState = { ...state, thresholdSettingsData: payload };
        return nextState;
    case SAVE_THRESHOLD_DATA:
        nextState = { ...state, thresholdSettingsData: payload, settingsSuccess: true };
        return nextState;
    case SET_SAVE_SUCCESS:
        nextState = {
            ...state,
            settingsSuccess: false,
            knowledgeGroupSaveError: false,
            hasExportError: false
        };
        return nextState;
    case ERROR_THRESHOLD_DATA:
        nextState = { ...state, knowledgeGroupSaveError: true };
        return nextState;
    case GET_CLASS_AVG_TOPICS:
        nextState = {
            ...state,
            topicsClsAvg: payload.knowledgeLevel,
            classAvgError: false
        };
        return nextState;
    case ERROR_GET_CLASS_AVG:
        nextState = { ...state, classAvgError: true };
        return nextState;
    case EXPORT_ERROR_MESSAGE:
        nextState = { ...state, hasExportError: payload };
        document.body.focus();
        document.body.scrollIntoView();
        return nextState;
    default:
        return state;
    }
};

export const actions = {
    commonStoreReset,
    updateConfig,
    getUserMessage,
    getKnowledgeScore,
    saveKnowledgeScore,
    setSettingsSuccess,
    getCourseKnowledgeLevel
};

export default commonReducer;
