import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import * as d3 from 'd3';

import ScoreHistogram from './ScoreHistogram/ScoreHistogram';
import './index.scss';

const ScoreHistogramBuilder = (props) => {
    const [width, setWidth] = useState(1);
    const [threshold, setThreshold] = useState(20);

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        handleResize();
    }, [props]);

    const handleResize = () => {
        const { offsetWidth } = d3.select('.o-score-histogram-wrapper').node();
        setWidth(offsetWidth);
    };

    const handleThresholdChange = (event) => {
        setThreshold(event.target.value);
    };

    return (
        <div className='o-score-histogram-wrapper'>
            {props.config.title
                && (
                    <Row>
                        <Col sm={12} md={12}>
                            <div className='o-score-histogram-title'>{props.config.title}</div>
                        </Col>
                    </Row>
                )}
            {props.config.showController
                && props.config.showController.binCount
                && (
                    <Row>
                        <Col sm={3} md={3} className='o-score-histogram-controller'>
                            <label htmlFor='histBinCount'>Histogram Bins:
                                <span>{threshold}</span>
                                <div>
                                    <input id='histBinCount' type='range' min='10' max='100' value={threshold} step='10' onChange={handleThresholdChange} />
                                </div>
                            </label>
                        </Col>
                    </Row>
                )}

            {width
                && (
                    <Row>
                        <Col sm={12} md={12}>
                            <ScoreHistogram
                                data={props.config}
                                width={width}
                                height={props.config.chartSize && props.config.chartSize.height}
                                threshold={threshold}
                            />
                        </Col>
                    </Row>
                )}
        </div>
    );
};

ScoreHistogramBuilder.propTypes = {
    config: PropTypes.object
};

ScoreHistogramBuilder.defaultProps = {
    config: {
        chartSize: {
            height: 1
        }
    }
};

export default ScoreHistogramBuilder;
