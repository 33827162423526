import React from 'react';
import objectAssign from 'object-assign';
import PropTypes from 'prop-types';
import useTouches from './use-touches';

export default class Handle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
        };
    }

  hoverIn = () => this.setState({
      hover: true,
  });

  hoverOut = () => this.setState({
      hover: false,
  });

  render() {
      const { state } = this;
      const { hover } = state;
      const { props } = this;
      const { active, x, y, size, strokeWidth, innerRadius, bg, sliderKey } = props;
      const events = objectAssign(
          useTouches()
              ? {}
              : {
                  onMouseEnter: this.hoverIn,
                  onMouseLeave: this.hoverOut
              },
          props.events
      );
      const style = {
          cursor: active ? 'ew-resize' : 'pointer',
          WebkitTapHighlightColor: 'rgba(0,0,0,0)',
      };
      const hoverOpacity = hover ? 0.8 : 1;
      return (
          <g
              tabIndex={0}
              style={style}
              {...events}
              onKeyDown={(e) => { props.handleKeyDown(e, sliderKey); }}
              data-testid='slider-circle'
          >
              <circle key='1' cx={x} cy={y} r={size} fill='#9B9C9C' />
              <circle
                  key='2'
                  opacity={active ? 0 : hoverOpacity}
                  cx={x}
                  cy={y}
                  r={size - strokeWidth}
                  fill={bg}
              />
              <circle
                  key='3'
                  cx={x}
                  cy={y}
                  r={innerRadius}
                  fill={active ? bg : 'black'}
              />
          </g>
      );
  }
}

Handle.propTypes = {
    active: PropTypes.bool,
    x: PropTypes.number,
    y: PropTypes.number,
    size: PropTypes.number,
    strokeWidth: PropTypes.number,
    innerRadius: PropTypes.number,
    bg: PropTypes.string,
    color: PropTypes.string,
    events: PropTypes.object,
    handleKeyDown: PropTypes.func,
    sliderKey: PropTypes.string
};

Handle.defaultProps = {
    active: false,
    x: 0,
    y: 0,
    size: 0,
    strokeWidth: 0,
    innerRadius: 0,
    bg: '',
    color: '',
    events: {},
    handleKeyDown: null,
    sliderKey: ''
};
