export const MENU_NAVIGATION = [
    { id: 'DATACARD_1', name: 'Overview', requiredAttributes: '', sortOrder: 1, route: '/' },
    { id: 'DATACARD_2', name: 'Assignments', requiredAttributes: '', sortOrder: 2, route: '/assignments' },
    { id: 'DATACARD_3', name: 'Student List', requiredAttributes: '', sortOrder: 3, route: '/student-list' }
];

export const SCROLL_SIZE = 150;

export const PERF_GROUP = {
    aboveAverage: { id: 'aboveAverage', title: 'Above Average', variant: 'success' },
    average: { id: 'average', title: 'Average', variant: 'warning' },
    belowAverage: { id: 'belowAverage', title: 'Below Average', variant: 'danger' },
    noAttempts: { id: 'noAttempts', title: 'No Attempts', variant: 'info' }
};

export const EXPORT_STUDENT_PAGE_HEADER = [
    { name: 'Topic', key: 'topic' },
    { name: 'Knowledge Score', key: 'knowledgeScore' },
    { name: 'Attempted Questions', key: 'studentAttempted' },
    { name: 'Duration', key: 'avgDuration' },
    { name: 'Correct on First Try', key: 'correctFirstTry' }
];

export const EXPORT_TOPICS_HEADER = [
    { name: 'Topic', key: 'topic' },
    { name: 'Knowledge Score', key: 'knowledgeScore' },
    { name: 'Student Knowledge', key: 'studentKnowledge' },
    { name: 'Student Attempted', key: 'studentAttempted' },
    { name: 'Average Duration', key: 'avgDuration' },
    { name: 'Correct on First Try', key: 'correctFirstTry' }
];

export const ERRORS = {
    NOCOURSE_ERROR: 'nocourse-error'
};

export const PII_ERROR_DELAY = 5000;

export const RETRY_LIMIT = 3;

const constants = {
    MENU_NAVIGATION,
    SCROLL_SIZE,
    PERF_GROUP,
    EXPORT_STUDENT_PAGE_HEADER,
    EXPORT_TOPICS_HEADER,
    ERRORS,
    PII_ERROR_DELAY,
    RETRY_LIMIT
};

export default constants;
