import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';

import './style.scss';

const CustomDropdown = (props) => {
    const CustomToggle = React.forwardRef((togProps, ref) => (
        <button
            className={`${togProps.className} pe-icon--btn dropdown-activator`}
            type='button'
            aria-expanded={togProps['aria-expanded'].toString()}
            aria-controls='custom-dropmenu'
            aria-haspopup={togProps['aria-haspopup'].toString()}
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                togProps.onClick(e);
            }}
        >
            {togProps.children}
        </button>
    ));

    const CustomMenu = React.forwardRef(
        ({ style, className, 'aria-labelledby': labelledBy }, ref) => {
            return (
                <div
                    ref={ref}
                    style={style}
                    className={className}
                    aria-labelledby={labelledBy}
                >
                    <ul className='list-unstyled'>
                        {props.pageSizeRange.map(pageSizeNum => (
                            <Dropdown.Item
                                key={pageSizeNum}
                                as='li'
                                className='custom-dropitem'
                                eventKey={pageSizeNum}
                                active={props.pageSize === pageSizeNum}
                                onClick={(e) => e.stopPropagation(e)}
                                aria-checked={props.pageSize === pageSizeNum ? 'true' : 'false'}
                            >
                                <button role='menuitem' type='button' className='checkmark' tabIndex={props.pageSize === pageSizeNum ? '-1' : '0'}>
                                    <span className='icon' style={{ visibility: props.pageSize === pageSizeNum ? 'visable' : 'hidden' }}>
                                        <svg focusable='false' aria-hidden='true' className='pe-icon--check-sm-18'>
                                            <use xlinkHref='#check-sm-18' />
                                        </svg>
                                    </span>
                                    <span className='icon-padding'>{pageSizeNum}</span>
                                </button>
                            </Dropdown.Item>
                        ))}
                    </ul>
                </div>
            );
        }
    );

    return (
        <Dropdown
            drop='up'
            className='custom-dropdown'
            onSelect={(e) => props.onSelect?.(e)}
        >
            <Dropdown.Toggle as={CustomToggle} id='custom-droptoggle'>
                <div>
                    {props.pageSize} items
                    <svg focusable='false' aria-hidden='true' className='pe-icon--dropdown-open-sm-18'>
                        <use xlinkHref='#dropdown-open-sm-18' />
                    </svg>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu id='custom-dropmenu' as={CustomMenu} />
        </Dropdown>
    );
};

CustomDropdown.propTypes = {
    pageSizeRange: PropTypes.array,
    pageSize: PropTypes.number,
    onSelect: PropTypes.func,
    onClick: PropTypes.func,
    children: PropTypes.node,
    style: PropTypes.object,
    className: PropTypes.string,
    'aria-labelledby': PropTypes.string
};

CustomDropdown.defaultProps = {
    pageSizeRange: [10, 25, 50],
    pageSize: 10,
    onSelect: null,
    onClick: null,
    children: null,
    style: null,
    className: '',
    'aria-labelledby': ''
};

export default CustomDropdown;
