import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, FormControl, OverlayTrigger, Tooltip } from 'react-bootstrap';
import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';
import SearchIcon from '../../images/search-18-black.svg';

import './style.scss';

class CustomSearch extends React.Component {
    constructor(props) {
        super(props);

        const hasSearchText = !_isEmpty(props.searchText);
        this.state = {
            showSearchBox: hasSearchText,
            isSearchDropdownEnabled: hasSearchText ? false : props.isSearchDropdownEnabled
        };

        this.searchRef = React.createRef();
    }

    handleSearchClear = () => {
        this.searchRef.focus();
        this.props.onSearch('', true);
    };

    handleKeyUpSearchClear = (e) => {
        e.preventDefault();
        // istanbul ignore else
        if (e.keyCode === 13 || e.keyCode === 32) {
            this.handleSearchClear();
        }
    };

    handleSearchSubmit = (e) => {
        e.preventDefault();
        const { searchText, onSearchSubmit } = this.props;
        this.setState({ isSearchDropdownEnabled: false }, () => {
            onSearchSubmit && onSearchSubmit(searchText);
        });
    };

    handleSearchChange = (e) => {
        e.preventDefault();
        const { value } = e.target;
        this.props.onSearch(value);
        if (this.props.isSearchDropdownEnabled) {
            if (_size(value) >= 3) {
                this.setState({ isSearchDropdownEnabled: true });
            } else {
                this.setState({ isSearchDropdownEnabled: false });
            }
        }
    };

    setShowSearchBar = (e, type) => {
        const { showSearchBox } = this.state;
        if (type === 'button') {
            this.setState({ showSearchBox: !showSearchBox, isSearchDropdownEnabled: false }, () => {
                this.searchRef.focus();
            });
            this.props.onSearchInputOpen && this.props.onSearchInputOpen(!showSearchBox);
        } else if (_size(e.target.value) < 3) {
            this.setState({ showSearchBox: !showSearchBox, isSearchDropdownEnabled: false });
            this.props.onSearchInputOpen && this.props.onSearchInputOpen(!showSearchBox);
        }
    };

    renderTooltip = () => {
        return (
            <Tooltip id='customSearchTooltip' className='common-tooltip'>
                <div>{this.props.placeholder}</div>
            </Tooltip>
        );
    };

    onSearchResultClick = (e, item, isKeyPress = false) => {
        e && e.stopPropagation();
        let triggerNameClick = false;
        if (isKeyPress) {
            const keyCode = e.which || e.keyCode;
            // istanbul ignore else
            if ([13, 32].includes(keyCode)) {
                triggerNameClick = true;
            }
        } else {
            triggerNameClick = true;
        }

        // istanbul ignore else
        if (triggerNameClick) {
            this.setState({ isSearchDropdownEnabled: false }, () => {
                this.props.onSearchResultClick && this.props.onSearchResultClick(item);
            });
        }
    }

    getDropDown = () => {
        const { updateSearchDropDown, searchText, showError, showLoader } = this.props;
        let dropDownDom = '';
        if (showLoader) {
            dropDownDom = <div className='dropdown-item-error'>Loading...</div>;
        } else if (showError) {
            dropDownDom = (
                <div className='dropdown-item-error' data-testid='custom-search-error'>
                    Failed to load
                </div>
            );
        } else if (updateSearchDropDown.length === 0 && !showError) {
            dropDownDom = <div className='dropdown-item-error'>No Matches found</div>;
        } else {
            dropDownDom = updateSearchDropDown.map((item) => {
                let counter = 1;
                const repSearchText = searchText.replace(/\*/g, '');
                const parts = `${item.lastName}, ${item.firstName}`.split(new RegExp(`(${repSearchText})`, 'gi'));
                const name = parts.map((part, i) => {
                    counter += i;
                    return (
                        <span
                            key={`part-${counter}`}
                            className={part.toLowerCase() === searchText.toLowerCase() ? 'highlight-text' : ''}
                        >
                            {part}
                        </span>
                    );
                });

                return (
                    <div
                        className='dropdown-item'
                        key={item.studentId}
                        onClick={(e) => this.onSearchResultClick(e, item)}
                        onKeyUp={(e) => this.onSearchResultClick(e, item, true)}
                        role='button'
                        tabIndex='0'
                    >
                        <div className='dropdown-item-1' title={`${item.lastName}, ${item.firstName}`}>
                            {name}
                        </div>
                        <div className='dropdown-item-2' title={item.studentId}>{item.studentId}</div>
                    </div>
                );
            });
        }

        return <div className='dropdown-menu show'>{dropDownDom}</div>;
    };

    render() {
        const { placeholder, disabled, searchText } = this.props;
        const { showSearchBox, isSearchDropdownEnabled } = this.state;
        if (showSearchBox) {
            return (
                <form role='search' onSubmit={this.handleSearchSubmit} className='custom-search'>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <div className='input-group-text'>
                                <img className='icon' src={SearchIcon} alt='search-icon' />
                            </div>
                        </InputGroup.Prepend>
                        <FormControl
                            ref={ref => { this.searchRef = ref; }}
                            type='text'
                            placeholder={placeholder}
                            size='large'
                            bsPrefix='custom-search-input'
                            value={searchText}
                            onChange={this.handleSearchChange}
                            onBlur={(e) => this.setShowSearchBar(e, 'input')}
                        />
                    </InputGroup>
                    {searchText && (
                        <div role='button' tabIndex={0} aria-label='Clear Search Text' className='custom-search-close close clear-searchtext' onClick={this.handleSearchClear} onKeyUp={(e) => this.handleKeyUpSearchClear(e)}>
                            <span className='close-icon' aria-hidden='true'>&times;</span>
                        </div>
                    )}
                    {isSearchDropdownEnabled && _size(searchText) >= 3 && this.getDropDown()}
                </form>
            );
        }

        return (
            <OverlayTrigger
                placement='bottom'
                trigger={['hover', 'focus']}
                overlay={this.renderTooltip()}
            >
                <button
                    type='button'
                    tabIndex='0'
                    aria-label={placeholder}
                    className='btn-search'
                    onClick={(e) => this.setShowSearchBar(e, 'button')}
                    disabled={disabled}
                />
            </OverlayTrigger>
        );
    }
}

CustomSearch.propTypes = {
    searchText: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    onSearch: PropTypes.func.isRequired,
    isSearchDropdownEnabled: PropTypes.bool,
    updateSearchDropDown: PropTypes.array,
    onSearchResultClick: PropTypes.func,
    onSearchSubmit: PropTypes.func,
    onSearchInputOpen: PropTypes.func,
    showError: PropTypes.bool,
    showLoader: PropTypes.bool
};

CustomSearch.defaultProps = {
    searchText: '',
    placeholder: 'Search',
    disabled: false,
    isSearchDropdownEnabled: false,
    updateSearchDropDown: [],
    onSearchResultClick: null,
    onSearchSubmit: null,
    onSearchInputOpen: null,
    showError: false,
    showLoader: false
};

export default CustomSearch;
