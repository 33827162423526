import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormCheck } from 'react-bootstrap';

const CustomCheckbox = (props) => {
    const checkRef = useRef(null);

    useEffect(() => {
        // istanbul ignore else
        if (checkRef) {
            checkRef.current.indeterminate = props.indeterminate;
            checkRef.current.checked = props.checked;
        }
    }, [props.indeterminate, props.checked]);

    return (
        <FormCheck inline custom>
            <FormCheck.Input
                type='checkbox'
                id={props.identifier}
                data-testid={props.identifier}
                ref={checkRef}
                name={props.name}
                value={props.value}
                onChange={e => props.onChange && props.onChange(e)}
            />
            <FormCheck.Label htmlFor={props.identifier} {...props.labelProps}>
                {props.label}
            </FormCheck.Label>
        </FormCheck>
    );
};

CustomCheckbox.propTypes = {
    indeterminate: PropTypes.bool,
    checked: PropTypes.bool,
    name: PropTypes.string,
    value: PropTypes.string,
    identifier: PropTypes.string,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    onChange: PropTypes.func,
    labelProps: PropTypes.shape({
        title: PropTypes.string
    })
};

CustomCheckbox.defaultProps = {
    indeterminate: false,
    checked: false,
    name: '',
    value: '',
    identifier: '',
    label: '',
    onChange: null,
    labelProps: {}
};

export default CustomCheckbox;
