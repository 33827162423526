import React from 'react';
import PropTypes from 'prop-types';
import { Overlay, Popover } from 'react-bootstrap';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import _size from 'lodash/size';
import _isEqual from 'lodash/isEqual';
import _groupBy from 'lodash/groupBy';

import { SCROLL_SIZE } from '../../common/constants';
import { getParentGroupFilter, sendGTM } from '../../common/utils';
import FilterPopover from './filter-popover';

import './styles.scss';

class CustomFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showPopover: false,
            target: null,
            showAllFilters: false,
            selectedFilters: [],
            editFilterItem: null
        };

        this.scrollWrapperRef = React.createRef();
        this.scrollRef = React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        if (!_isEqual(props.selectedFilters, state.selectedFilters)) {
            const stateObj = { selectedFilters: props.selectedFilters };
            if (state.showAllFilters && (_size(props.selectedFilters) <= 3)) {
                stateObj.showAllFilters = false;
            }
            return stateObj;
        }

        return null;
    }

    componentDidUpdate(prevState) {
        const { showAllFilters, selectedFilters } = this.state;
        const scrollWrap = this.scrollWrapperRef.current;
        if ((prevState.showAllFilters !== showAllFilters) && showAllFilters) {
            // istanbul ignore else
            if (scrollWrap && this.scrollRef.current) {
                this.scrollRef.current.style.width = 'auto';
                const scrollWdt = scrollWrap.scrollWidth;
                this.scrollRef.current.style.width = `${(scrollWdt + 45) * 0.0625}rem`;
            }
        } else if (showAllFilters && !_isEqual(prevState.selectedFilters, selectedFilters)) {
            scrollWrap.scrollLeft = 0;
        }
    }

    handleFilterPopover = (e, show, editItem = null, isKeyPress = false) => {
        e && e.stopPropagation();

        let triggerPopover = false;
        if (isKeyPress) {
            const keyCode = e.which || e.keyCode;
            // istanbul ignore else
            if ([13, 32].includes(keyCode)) {
                triggerPopover = true;
            }
        } else {
            triggerPopover = true;
        }

        if (triggerPopover) {
            let currentTarget = null;
            // istanbul ignore else
            if (e && e.target) {
                currentTarget = e.target;
            }
            this.setState({
                showPopover: show,
                target: currentTarget,
                editFilterItem: editItem
            });
        }
        sendGTM({
            eventName: 'openFilterOptions',
            eventDesc: 'Add Filter Options Clicked',
            page: this.props.page
        });
    };

    handleFilterUpdate = filterData => {
        this.props.onFilterUpdate(filterData);
        // istanbul ignore else
        if (this.state.showPopover) {
            this.handleFilterPopover(null, false);
        }
        sendGTM({
            eventName: 'applyFilter',
            eventDesc: 'Filter Applied',
            eventLabel: `countFilters: ${filterData.length}`,
            page: this.props.page
        });
    };

    handleClearAll = (e) => {
        e && e.stopPropagation();
        this.setState({
            showAllFilters: false,
            showPopover: false,
            target: null,
            editFilterItem: null
        }, () => {
            this.props.onFilterUpdate([]);
        });
        sendGTM({
            eventName: 'clearAllFilters',
            eventDesc: 'Clear all Filters',
            eventLabel: `countFiltersRemoved: ${_size(this.groupedFilters)}`,
            page: this.props.page
        });
    };

    handleShowMoreFilters = (e, show) => {
        e && e.stopPropagation();
        this.setState({ showAllFilters: show });
    };

    handlePrevNext = (type) => {
        let clearVal = 0;
        const scrollEle = this.scrollWrapperRef.current;
        switch (type) {
        case 'back': {
            clearVal = scrollEle.scrollLeft - SCROLL_SIZE;
            scrollEle.scrollLeft = clearVal < 0 ? 0 : clearVal;
            break;
        }
        case 'next':
        default: {
            clearVal = scrollEle.scrollLeft + SCROLL_SIZE;
            // istanbul ignore else
            if (clearVal >= scrollEle.scrollWidth) {
                scrollEle.scrollLeft = scrollEle.scrollWidth;
            } else {
                scrollEle.scrollLeft = clearVal;
            }
            break;
        }
        }
    };

    handleRemoveFilter = (e, removeItem, isKeyPress) => {
        e && e.stopPropagation();
        let triggerRemove = false;
        if (isKeyPress) {
            const keyCode = e.which || e.keyCode;
            // istanbul ignore else
            if ([13, 32].includes(keyCode)) {
                triggerRemove = true;
            }
        } else {
            triggerRemove = true;
        }

        // istanbul ignore else
        if (triggerRemove) {
            let removeArr = [removeItem.id];
            if (!_isEmpty(removeItem.children)) {
                removeArr = removeItem.children.map(ra => ra.id);
            }
            const filterData = this.props.selectedFilters.filter(sf => !removeArr.includes(sf.id));
            this.handleFilterPopover(null, false);
            this.props.onFilterUpdate(filterData);
        }
        sendGTM({
            eventName: 'clearFilters',
            eventDesc: 'Clear Filters',
            eventLabel: `countFiltersRemoved: ${removeItem.children ? _size(removeItem.children) : 1}`,
            page: this.props.page
        });
    };

    renderSelectedFilters = (selectedFilters) => {
        return selectedFilters.map((item) => {
            return (
                <div
                    key={item.id}
                    tabIndex='0'
                    role='button'
                    className='custom-filter-edit'
                    aria-label={item.title}
                    title={item.title}
                    onClick={(e) => this.handleFilterPopover(e, true, item)}
                    onKeyDown={(e) => this.handleFilterPopover(e, true, item, true)}
                >
                    <span className='filter-edit-title'>{item.title}</span>
                    <span
                        tabIndex='0'
                        role='button'
                        aria-label={`Clear ${item.title} Filter`}
                        onClick={(e) => this.handleRemoveFilter(e, item)}
                        onKeyDown={(e) => this.handleRemoveFilter(e, item, true)}
                    >
                        <svg focusable='false' className='pe-icon--remove-sm-18'>
                            <use xlinkHref='#remove-sm-18' />
                        </svg>
                    </span>
                </div>
            );
        });
    };

    selectItem = (parentData, grpCount, selectedItem, filterData) => {
        const _selectedItem = _cloneDeep(selectedItem);
        if (_size(parentData.children) === grpCount) {
            _selectedItem.title = `All "${parentData.name}"`;
        } else {
            _selectedItem.title = `${parentData.name}: ${grpCount} selected`;
        }
        filterData.push(_selectedItem);
        return filterData;
    }

    getFilterData = (tempArr, item, selectedItem, filterData) => {
        let _item = _cloneDeep(item);
        let _selectedItem = _cloneDeep(selectedItem);
        let _filterData = _cloneDeep(filterData);
        tempArr.forEach(ta => {
            if (ta.selectedCount >= 3) {
                [_item] = ta.children;
                _selectedItem = { id: _item.id, type: _item.type, children: ta.children };
                if (ta.selectedCount === ta.totalCount) {
                    _selectedItem.title = `All "${ta.title}"`;
                } else {
                    _selectedItem.title = `${ta.title}: ${ta.selectedCount} selected`;
                }
                _filterData.push(_selectedItem);
            } else {
                _filterData = [..._filterData, ...ta.children];
            }
        });
        return _filterData;
    }

    renderGroupedFilters = () => {
        const { selectedFilters, filterOptions } = this.props;
        let filterData = [];
        const groupedData = _groupBy(selectedFilters, 'type');
        Object.keys(groupedData).forEach(key => {
            const group = groupedData[key];
            const grpCount = _size(group);
            let item = null;
            let selectedItem = null;
            let parentData = null;
            if (grpCount >= 3) {
                let sType = key;
                if (['CHAPTER', 'SECTION', 'TOPIC'].includes(sType)) {
                    sType = key.toLowerCase();
                }
                if (['studentTags', 'knowledgeScore', 'chapter'].includes(sType)) {
                    [item] = group;
                    parentData = getParentGroupFilter(item, filterOptions);
                    selectedItem = { id: item.id, type: item.type, children: group };
                    filterData = this.selectItem(
                        parentData,
                        grpCount,
                        selectedItem,
                        filterData
                    );
                } else {
                    const tempArr = [];
                    const childKey = key.toLowerCase() === 'section' ? 'sections' : 'topics';
                    group.forEach(gp => {
                        parentData = getParentGroupFilter(gp, filterOptions);
                        const currentIdx = tempArr.findIndex(ta => ta.id === parentData.id);
                        if (currentIdx >= 0) {
                            const oldData = tempArr[currentIdx];
                            oldData.selectedCount += 1;
                            oldData.children.push(gp);
                            tempArr.splice(currentIdx, 1, oldData);
                        } else {
                            tempArr.push({
                                id: parentData.id,
                                title: parentData.title,
                                totalCount: _size(parentData[childKey]),
                                selectedCount: 1,
                                children: [gp]
                            });
                        }
                    });

                    filterData = this.getFilterData(tempArr, item, selectedItem, filterData);
                }
            } else {
                filterData = [...filterData, ...group];
            }
        });

        return filterData;
    };

    renderPopover = (popperData) => {
        const { addFilterText, filterOptions, selectedFilters, page } = this.props;
        return (
            <FilterPopover
                addFilterText={addFilterText}
                filterOptions={filterOptions}
                selectedFilters={selectedFilters}
                editFilterItem={this.state.editFilterItem}
                getParentGroup={(item) => getParentGroupFilter(item, filterOptions)}
                onFilterUpdate={filterData => this.handleFilterUpdate(filterData)}
                popperData={popperData}
                page={page}
            />
        );
    };

    renderPrevNextButton = (type = 'next', iconName = 'chevron-next-sm-18') => {
        if (!this.state.showAllFilters) {
            return null;
        }

        return (
            <button
                key={`cfilter-${type}`}
                type='button'
                className={`custom-filter-${type}`}
                data-testid={`${type}-filter`}
                onClick={() => this.handlePrevNext(type)}
            >
                <svg focusable='false' className={`pe-icon--${iconName}`}>
                    <use xlinkHref={`#${iconName}`} />
                </svg>
            </button>
        );
    };

    render() {
        const { addFilterText } = this.props;
        const { showAllFilters } = this.state;
        this.groupedFilters = this.renderGroupedFilters();
        const filtersCount = _size(this.groupedFilters);
        let showMoreCount = 0;
        let trimmedFilters = this.groupedFilters;
        if (!showAllFilters && (filtersCount > 3)) {
            showMoreCount = filtersCount - 3;
            trimmedFilters = this.groupedFilters.slice(0, 3);
        }
        return (
            <div className='custom-filter'>
                {this.renderPrevNextButton('back', 'chevron-back-sm-18')}
                <div
                    className={`custom-filter-scroll-wrapper${!showAllFilters ? '' : ' has-scroll'}`}
                    ref={this.scrollWrapperRef}
                >
                    <div className='custom-filter-scroll' ref={this.scrollRef}>
                        {(filtersCount > 3) && (
                            <button
                                key='cfilter-clear'
                                type='button'
                                className='custom-filter-edit'
                                data-testid='clear-filter'
                                aria-label='Clear all'
                                onClick={(e) => this.handleClearAll(e)}
                            >
                                Clear all
                            </button>
                        )}
                        {!_isEmpty(trimmedFilters) && this.renderSelectedFilters(trimmedFilters)}
                        {(showMoreCount > 0) && (
                            <button
                                key='cfilter-more'
                                type='button'
                                className='custom-filter-more'
                                data-testid='more-filter'
                                aria-label={`+ ${showMoreCount} more`}
                                onClick={(e) => this.handleShowMoreFilters(e, true)}
                            >
                                {`+ ${showMoreCount} more`}
                            </button>
                        )}
                        <button
                            key='cfilter-add'
                            type='button'
                            className='custom-filter-add'
                            data-testid='add-filter'
                            aria-label={addFilterText}
                            onClick={(e) => this.handleFilterPopover(e, true)}
                        >
                            {addFilterText}
                        </button>
                    </div>
                </div>
                {this.renderPrevNextButton()}
                <Overlay
                    show={this.state.showPopover}
                    onHide={() => this.handleFilterPopover(null, false)}
                    rootClose
                    placement='bottom-start'
                    target={this.state.target}
                >
                    {({ ...popoverProps }) => (
                        <Popover
                            {...popoverProps}
                            className='custom-filter-popover-container'
                            style={{ ...popoverProps.style }}
                        >
                            {this.renderPopover(popoverProps.popper)}
                        </Popover>
                    )}
                </Overlay>
            </div>
        );
    }
}

CustomFilter.propTypes = {
    addFilterText: PropTypes.string,
    filterOptions: PropTypes.array.isRequired,
    onFilterUpdate: PropTypes.func.isRequired,
    selectedFilters: PropTypes.array,
    page: PropTypes.string
};

CustomFilter.defaultProps = {
    addFilterText: 'Add Filter',
    selectedFilters: [],
    page: ''
};

export default CustomFilter;
