import axios from 'axios';

class AxiosInstance {
    constructor() {
        AxiosInstance.configureInstance();
    }

    // creating the axios instance
    // base configuration for the custom axios instance
    static axiosBaseConfiguration = {
        timeout: 60000,
        headers: {
            'Content-Type': 'application/json'
        }
    };

    static instance = axios.create(AxiosInstance.axiosBaseConfiguration);

    /**
     * Registers the interceptors for the Axios Instance created.
     */
    static configureInstance = () => {
        AxiosInstance.instance.interceptors.request.use(AxiosInstance.requestInterceptor);
        AxiosInstance.instance.interceptors.response
            .use(AxiosInstance.responseInterceptor, AxiosInstance.errorHandler);
    }

    /**
     * Handles the Error Scenario for a failed network request
     * @param {object} error The error object recieved for the failed request
     */
    static errorHandler = (error) => {
        // perform some logic before rejecting the error response

        return Promise.reject(error);
    };

    /**
     * Request Interceptor before making the network request
     * @param {object} requestConfig The request details for the network request
     */
    static requestInterceptor = (requestConfig) => {
        // perform some logic before initating the network request

        return requestConfig;
    };

    /**
     * Response Interceptor after network request is completed
     * @param {object} response The response details for the completed network request
     */
    static responseInterceptor = (response) => {
        // perform some logic before resolving the response

        return response;
    };

    /**
     * Axios Requet Method to create a Network Request
     * @param {object} requestConfiguration Payload for the request
     */
    call = (requestConfiguration) => {
        const { url, method, data, headers, timeout, isDemo, cancelToken } = requestConfiguration;

        const requestDetails = {
            url,
            method,
            data: isDemo === 'true' ? { query: data, variables: '' } : data,
            headers,
            timeout,
            cancelToken
        };

        return AxiosInstance.instance.request(requestDetails);
    };
}

const axiosInstance = new AxiosInstance();
Object.freeze(axiosInstance);

export default axiosInstance;

// exports for Unit Test purpose ONLY
export const axiosInstanceForUnitTest = new AxiosInstance();
export const unitTestStaticMembers = AxiosInstance;
