import React from 'react';
import PropTypes from 'prop-types';

const Label = (props) => {
    const renderNoAttemptInfo = () => {
        const { noAttemptInfo } = props;
        if (noAttemptInfo.show) {
            return <text {...noAttemptInfo.otherProps}>{noAttemptInfo.text}</text>;
        }

        return null;
    };

    return (
        <g className={`${props.elementProps.className}-wrapper`}>
            {props.isError
                && (
                    <circle className='exclamation-circle' cx={props.x} cy={props.y} r='20'>
                        <text className='exclamation-text' data-testid='exclamation-text'>!</text>
                    </circle>
                )}
            {renderNoAttemptInfo()}
            <text
                {...props.elementProps}
                style={{ textAnchor: props.anchor }}
                data-testid={props.elementProps.className}
            >
                {props.text}
            </text>
        </g>
    );
};

Label.propTypes = {
    elementProps: PropTypes.object,
    noAttemptInfo: PropTypes.object,
    isError: PropTypes.bool,
    x: PropTypes.string,
    y: PropTypes.string,
    anchor: PropTypes.string,
    text: PropTypes.string
};

Label.defaultProps = {
    elementProps: {},
    noAttemptInfo: { text: '', show: false, otherProps: {} },
    isError: false,
    x: '',
    y: '',
    anchor: '',
    text: ''
};
export default Label;
