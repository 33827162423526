import React, { useEffect } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';

const AverageMarker = (props) => {
    useEffect(() => {
        const avgMarkerSuffix = (props.data.averageMarker && props.data.averageMarker.suffix) ? props.data.averageMarker.suffix : '';
        let averagePercentage = Math.round(props.data.aggregateAvgGradePct);
        let avgPercentTxt = `${averagePercentage}${avgMarkerSuffix}`;
        if (props.data.hasMorePercent && (averagePercentage >= 100)) {
            averagePercentage = 100;
            avgPercentTxt = `>=${averagePercentage}${avgMarkerSuffix}`;
        }

        const avgMarkerGroup = d3.select('g.avg-marker-wrapper');
        avgMarkerGroup.append('line')
            .attr('class', 'avg-score-marker')
            .attr('x1', props.scales.xScale(averagePercentage))
            .attr('y1', props.margins.top - 10)
            .attr('x2', props.scales.xScale(averagePercentage))
            .attr('y2', props.chartHeight - props.margins.top - 25);

        const textBorder = avgMarkerGroup.append('path')
            .attr('class', 'avg-score-text-box');

        const textEle = avgMarkerGroup.append('text')
            .attr('class', 'avg-score-text')
            .attr('x', props.scales.xScale(averagePercentage))
            .attr('y', props.margins.top - 15)
            .attr('text-anchor', 'middle')
            .text(avgPercentTxt);

        const rect = textEle.node() && typeof (textEle.node().getBBox) === 'function' ? textEle.node().getBBox() : { height: 0, width: 0, x: 0, y: 0 };
        const xOffset = 10;
        const yOffset = 2;
        const pathinfo = [
            { x: rect.x - xOffset, y: rect.y - yOffset },
            { x: rect.x + xOffset + rect.width, y: rect.y - yOffset },
            { x: rect.x + xOffset + rect.width, y: rect.y + rect.height + yOffset },
            { x: rect.x - xOffset, y: rect.y + rect.height + yOffset },
            { x: rect.x - xOffset, y: rect.y - yOffset },
        ];

        // Specify the function for generating path data
        const d3line = d3.line()
            .x((d) => { return d.x; })
            .y((d) => { return d.y; });
        // Draw the line
        textBorder.attr('d', d3line(pathinfo));

        return () => {
            // Remove existing elements before re-render
            avgMarkerGroup.select('line').remove();
            avgMarkerGroup.select('text').remove();
            avgMarkerGroup.select('path').remove();
        };
    }, [props]);

    return (<g className='avg-marker-wrapper' data-testid='avg-marker-wrapper' />);
};

AverageMarker.propTypes = {
    data: PropTypes.object,
    scales: PropTypes.object,
    margins: PropTypes.object,
    chartHeight: PropTypes.number
};

AverageMarker.defaultProps = {
    data: {},
    scales: {},
    margins: {},
    chartHeight: 0
};
export default AverageMarker;
