const saveDataInLocalStorage = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
};

const getDataFromLocalStorage = (key) => JSON.parse(localStorage.getItem(key));

const deleteDataFromLocalStorage = (key) => localStorage.removeItem(key);

const clearLocalStorage = () => localStorage.clearItem();

export {
    saveDataInLocalStorage,
    getDataFromLocalStorage,
    deleteDataFromLocalStorage,
    clearLocalStorage
};
