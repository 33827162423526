import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import _size from 'lodash/size';
import _result from 'lodash/result';
import { makeAPICall, getDistributionThresholds, getFilterKnowledgeLevel, sendGTM, GAprops } from '../common/utils';
import { studentDetailsQuery, studentTopicsExportSchema, studentDetailsCourseSummaryQuery } from '../common/schemaHelper';
import FilterData from '../generateMockData/mockData/filterData.json';
import { PERF_GROUP } from '../common/constants';

const STORE_RESET = 'studentDetails/STORE_RESET';
const GET_STUDENTDETAILS_DATA = 'studentDetails/GET_STUDENTDETAILS_DATA';
const SELECTED_FILTER_VALUES = 'studentDetails/SELECTED_FILTER_VALUES';
const INITIATE_FILTER_OPTS = 'studentDetails/INITIATE_FILTER_OPTS';
const ERROR_GET_STUDENTDETAILS_DATA = 'studentDetails/ERROR_GET_STUDENTDETAILS_DATA';
const STUDENT_DETAILS_LIST_SORTBY = 'studentDetails/STUDENT_DETAILS_LIST_SORTBY';
const STUDENT_DETAILS_LOADING_STATE = 'studentDetails/STUDENT_DETAILS_LOADING_STATE';
const EXPORT_ERROR_MESSAGE = 'common/EXPORT_ERROR_MESSAGE';
const GET_STUDENTDETAILS_COURSE_SUMMARY_DATA = 'studentDetails/GET_STUDENTDETAILS_COURSE_SUMMARY_DATA';
const NOCOURSE_STUDENTDETAILS = 'studentDetails/NOCOURSE_STUDENTDETAILS';
const ERROR_SEARCH_STUDENTDETAILS_DATA = 'studentDetails/ERROR_SEARCH_STUDENTDETAILS_DATA';
const STUDENT_DETAILS_GRID_LOADING = 'studentDetails/STUDENT_DETAILS_GRID_LOADING';

const initialState = {
    topicInfo: null,
    studentInfo: null,
    isPageLoading: 'true',
    filterOptions: [],
    selectedFilters: [],
    sortBy: [{ id: 'topic', desc: false }],
    pagination: {
        offset: 1,
        limit: 10,
        total: 0
    },
    hasAPIError: false,
    hasSearchAPIError: false,
    noCourseError: false,
    isGridLoading: false
};

const resetStudentDetailsData = () => ({ type: STORE_RESET });

const setStudentDetailsLoadingState = (isLoading) => ({
    type: STUDENT_DETAILS_LOADING_STATE,
    payload: isLoading
});

const setStudentDetailsGridLoading = (isLoading) => ({
    type: STUDENT_DETAILS_GRID_LOADING, payload: isLoading
});

const updateSelectedStuDetailFilters = payload => ({ type: SELECTED_FILTER_VALUES, payload });

const setStudentsTopicsSortBy = (sortArr) => ({
    type: STUDENT_DETAILS_LIST_SORTBY,
    payload: sortArr
});

const initStuDetailFilterOptions = () => {
    const [knowledgeGrp] = FilterData;
    return { type: INITIATE_FILTER_OPTS, payload: [knowledgeGrp] };
};

const formIndividualStudentTopicsData = (topicLists) => {
    const studentTopics = [];
    topicLists.forEach(st => {
        studentTopics.push({
            id: st.learningResource.id,
            topic: st.learningResource.label,
            knowledgeScoreTopic: st.knowledgeInfo.knowledgeLevel,
            correctFirstTry: st.knowledgeInfo.correctOnFirstAttemptTry,
            avgDuration: Math.round(st.knowledgeInfo.timeOnTask),
            studentAttempted: {
                totalAttempts: st.knowledgeInfo.countSubmissions
            }
        });
    });
    return studentTopics;
};

const getStudentDistribution = (distro) => {
    const distroList = distro.learningResourceDistribution.map(d => {
        let rangeKey = 'noAttempts';
        if (d.name === 'Below Average') {
            rangeKey = 'belowAverage';
        } else if (d.name === 'Average') {
            rangeKey = 'average';
        } else if (d.name === 'Above Average') {
            rangeKey = 'aboveAverage';
        }

        return {
            id: PERF_GROUP[rangeKey].id,
            name: PERF_GROUP[rangeKey].title,
            value: d.count,
            type: PERF_GROUP[rangeKey].variant
        };
    });
    return distroList;
};

const getSortObject = (sortArr) => {
    if (!_isEmpty(sortArr)) {
        const [item] = sortArr;
        const sortOrder = item.desc ? 'DESC' : 'ASC';
        return { fieldName: item.id, sortOrder };
    }

    return null;
};

const getFilterObject = (commonData, { selectedFilters }, searchText = null) => {
    const respObj = {
        filter: { lrSearchKeyword: searchText },
        filterApplied: !_isEmpty(searchText)
    };
    if (!_isEmpty(selectedFilters)) {
        // handle knowledge score filters
        const knwItems = selectedFilters.filter(sf => sf.type === 'knowledgeScore');
        if (!_isEmpty(knwItems)) {
            respObj.studentDistroNotLoadedAndFilterApplied = true;
            respObj.filterApplied = true;
            respObj.filter.knowledgeLevel = getFilterKnowledgeLevel(
                knwItems, commonData.thresholdSettingsData
            );
        }
    }

    return respObj;
};

const getStudentTags = (tagList) => {
    const studentTags = [];
    tagList.forEach((item) => {
        studentTags.push(item.studentTagName);
    });
    return studentTags;
};

const getStudentCourseSummaryDetails = (stuId) => {
    return async (dispatch, getState) => {
        try {
            const { commonReducer } = getState();
            const query = studentDetailsCourseSummaryQuery(stuId, commonReducer.configs);
            const postUrl = commonReducer.configs.API_URL;
            const resp = await makeAPICall(postUrl, query, 'POST');
            if (resp.status === 200 && resp.data) {
                const studentDet = {};
                const courseSummaryInfo = _result(resp.data.data, 'courseSummary', {});
                const studentCourseInfo = _result(resp.data.data, 'studentCourseKnowledgeInfo.studentCourseKnowledgeInfoList', []);
                const errors = _result(resp.data.data, 'studentCourseKnowledgeInfo.errors', []);
                studentDet.classKnowledgeScore = courseSummaryInfo.knowledgeInfo.knowledgeLevel;
                if (_size(studentCourseInfo)) {
                    studentDet.knowledgeScore = studentCourseInfo[0].knowledgeInfo.knowledgeLevel;
                    studentDet.firstName = studentCourseInfo[0].student.firstName;
                    studentDet.lastName = studentCourseInfo[0].student.lastName;
                    studentDet.email = studentCourseInfo[0].student.emails;
                    studentDet.tags = getStudentTags(studentCourseInfo[0].student.tags);
                } else {
                    studentDet.knowledgeScore = 0;
                    studentDet.firstName = '';
                    studentDet.lastName = '';
                    studentDet.email = [];
                    studentDet.tags = [];
                }
                studentDet.errors = errors;
                dispatch({
                    type: GET_STUDENTDETAILS_COURSE_SUMMARY_DATA,
                    payload: studentDet
                });
            }
        } catch (e) {
            if (process.env.NODE_ENV === 'development') {
                /* eslint-disable-next-line no-console */
                console.log('Student Details Error:', e);
            }
        }
    };
};

const setTopicsSearchError = (hasError) => {
    return {
        type: ERROR_SEARCH_STUDENTDETAILS_DATA,
        payload: {
            topicInfo: null,
            hasAPIError: hasError,
            hasSearchAPIError: hasError,
            isPageLoading: 'false',
            isGridLoading: false,
            pagination: {
                offset: 1,
                limit: 10,
                total: 0
            }
        }
    };
};

const getStudentDetails = (stuId, searchText, commonData, paginationData = null, GAtype,
    bGridAction = false) => {
    return async (dispatch, getState) => {
        !bGridAction && dispatch(setStudentDetailsLoadingState('true'));
        bGridAction && dispatch(setStudentDetailsGridLoading(true));
        try {
            const { commonReducer, studentDetailsReducer } = getState();
            const { filter, filterApplied } = getFilterObject(
                commonReducer, studentDetailsReducer, searchText
            );
            const pageObj = paginationData || _pick(studentDetailsReducer.pagination, ['offset', 'limit']);
            const options = {
                sortBy: getSortObject(studentDetailsReducer.sortBy),
                filter,
                pagination: pageObj,
                filterApplied
            };
            const distributionThresholds = getDistributionThresholds(commonData);
            const query = studentDetailsQuery(
                stuId, commonData, searchText, options, distributionThresholds
            );
            const postUrl = commonData.configs.API_URL;
            const resp = await makeAPICall(postUrl, query, 'POST');
            if (resp.status === 200 && resp.data) {
                const studentData = {};
                const studentDetInfo = _result(resp.data.data, 'studentLearningResourceKnowledgeInfoList', {});
                if (_isEmpty(studentDetInfo)) {
                    dispatch({ type: NOCOURSE_STUDENTDETAILS, payload: true });
                } else {
                    const studentDet = _result(resp.data.data, 'studentLearningResourceKnowledgeInfoList.studentLearningResourceKnowledgeInfoList', []);
                    studentData.topicDistribution = !_isEmpty(
                        studentDetInfo.learningResourceDistribution
                    )
                        ? getStudentDistribution(studentDetInfo)
                        : studentDetailsReducer.topicInfo.topicDistribution;
                    studentData.topics = formIndividualStudentTopicsData(
                        studentDet
                    );
                    const { pagination } = studentDetInfo;
                    if (GAtype === 'searchTopicByNameStudentDetailView') {
                        sendGTM({
                            eventName: 'searchTopicByNameStudentDetailView',
                            eventDesc: 'Search topic By Name',
                            eventLabel: `{
                                searchStrLength: ${_size(searchText)},
                                searchResultCnt: ${pagination.total}
                            }`,
                            page: GAprops.studentDetailsPage
                        });
                    }
                    dispatch({
                        type: GET_STUDENTDETAILS_DATA,
                        payload: {
                            studentData,
                            pagination
                        }
                    });
                }
            } else {
                dispatch({ type: ERROR_GET_STUDENTDETAILS_DATA, payload: 'true' });
            }
        } catch (e) {
            dispatch(setTopicsSearchError('true'));
        }
    };
};

const setExportErrorMessage = () => ({
    type: EXPORT_ERROR_MESSAGE,
    payload: true
});

const exportStudentTopicsData = (stuId, topicSearchText) => {
    return async (dispatch, getState) => {
        const { commonReducer, studentDetailsReducer } = getState();
        const { configs } = commonReducer;
        try {
            const { filter } = getFilterObject(commonReducer,
                studentDetailsReducer, topicSearchText);
            const options = {
                sortBy: getSortObject(studentDetailsReducer.sortBy),
                filter
            };
            const query = studentTopicsExportSchema(stuId, configs, options);
            const resp = await makeAPICall(configs.API_URL, query, 'POST');
            const windowReference = window.open('', '_self');
            if (resp.status === 200 && resp.data) {
                sendGTM({
                    eventName: 'topicsListExportStudentDetailView',
                    eventDesc: 'Topics list export',
                    eventLabel: `exported
                        ${studentDetailsReducer.pagination.total}
                        rows`,
                    page: GAprops.studentDetailsPage
                });

                const studentExpInfo = _result(resp.data.data, 'exportSingleStudentLearningResourseList', []);
                windowReference.location = studentExpInfo.fileName;
            } else {
                dispatch(setExportErrorMessage());
            }
        } catch (e) {
            dispatch(setExportErrorMessage());
        }
        return null;
    };
};

const studentDetailsReducer = (state = initialState, action) => {
    const { type, payload } = action;
    let nextState;
    switch (type) {
    case GET_STUDENTDETAILS_DATA:
        nextState = { ...state, topicInfo: payload.studentData, pagination: payload.pagination, isPageLoading: 'false', hasAPIError: false, hasSearchAPIError: false, isGridLoading: false };
        return nextState;
    case ERROR_GET_STUDENTDETAILS_DATA:
        nextState = { ...state, hasAPIError: payload, isPageLoading: 'false', isGridLoading: false };
        return nextState;
    case NOCOURSE_STUDENTDETAILS:
        nextState = { ...state, noCourseError: payload, isPageLoading: 'false', isGridLoading: false };
        return nextState;
    case SELECTED_FILTER_VALUES:
        nextState = { ...state, selectedFilters: payload };
        return nextState;
    case INITIATE_FILTER_OPTS:
        nextState = { ...state, filterOptions: payload };
        return nextState;
    case STUDENT_DETAILS_LIST_SORTBY:
        nextState = { ...state, sortBy: payload };
        return nextState;
    case STUDENT_DETAILS_LOADING_STATE:
        nextState = { ...state, isPageLoading: payload };
        return nextState;
    case STUDENT_DETAILS_GRID_LOADING:
        nextState = { ...state, isGridLoading: payload };
        return nextState;
    case GET_STUDENTDETAILS_COURSE_SUMMARY_DATA:
        nextState = { ...state, studentInfo: payload };
        return nextState;
    case STORE_RESET:
        return initialState;
    case ERROR_SEARCH_STUDENTDETAILS_DATA:
        nextState = { ...state, ...payload };
        return nextState;
    default:
        return state;
    }
};

export const actions = {
    resetStudentDetailsData,
    getStudentDetails,
    updateSelectedStuDetailFilters,
    initStuDetailFilterOptions,
    setStudentsTopicsSortBy,
    exportStudentTopicsData,
    getStudentCourseSummaryDetails,
    setStudentDetailsGridLoading
};

export default studentDetailsReducer;
