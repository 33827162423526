import React from 'react';

const Assignments = () => {
    return (
        <div>
            <h3>Assignments Page - Coming Soon!</h3>
        </div>
    );
};

Assignments.propTypes = {};

Assignments.defaultProps = {};

export default Assignments;
