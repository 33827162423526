import { Email } from '@pearson-incubator/o-email-perfui';
import _isEmpty from 'lodash/isEmpty';
import _isNumber from 'lodash/isNumber';
import _size from 'lodash/size';
import _round from 'lodash/round';
import _pick from 'lodash/pick';
import _compact from 'lodash/compact';
import axios from 'axios';

import AxiosInstance from './AxiosInstance';
import { PERF_GROUP } from './constants';
import commonHelper from './commonHelper';

let emailProps = null;
export const apiCaller = AxiosInstance;

export const getUrlParameter = (name) => {
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const isNotNull = val => {
    return (val !== null && val !== 'null');
};

export const isNotUndefined = val => {
    return (val !== undefined && val !== 'undefined');
};

export const isNotNullAndUndefined = val => {
    return (isNotNull(val) && isNotUndefined(val));
};

export const getIsDisplay = (h, m, s) => {
    const hDisplay = h > 0 ? `${h}h ` : '';
    const mDisplay = m > 0 ? `${m}m ` : '';
    let sDisplay = s > 0 ? `${s}s ` : '';
    if (hDisplay === '' && mDisplay === '' && sDisplay === '') {
        sDisplay = '0s';
    }
    return `${hDisplay}${mDisplay}${sDisplay}`;
};

export const secondsToHms = d => {
    if (isNotNullAndUndefined(d) && d !== '' && !isNaN(d) && d > 0) {
        const totSec = Number(d);
        let h = Math.floor(totSec / 3600);
        let m = Math.floor(totSec % 3600 / 60);
        const s = Math.floor(totSec % 3600 % 60);

        if (m > 59) {
            h += 1;
            m = 0;
        }

        return getIsDisplay(h, m, s);
    }

    return '--';
};

function emailNotification() {
    document.body.focus();
    document.body.scrollIntoView();
}

const updateToken = () => {
    const recepients = emailProps.data.lists;
    const recepientsId = (recepients || []).map((itm) => `${itm.studentId}`).toString();

    sendGTM({
        eventName: 'emailSendButtonClicked',
        eventDesc: 'Email send button clicked',
        eventLabel: `no. of recipents: ${_size(recepients)}, studentId: ${recepientsId}`,
        page: emailProps.page || null });
    return Promise.resolve(window.piSession.currentToken());
};

export const renderEmailComponent = (emailObj) => {
    let emailContent = '';
    if (!_isEmpty(emailObj)) {
        emailContent = (
            <Email
                {...emailObj}
                data={emailObj.data}
                modal={emailObj.modal}
                emailNotification={emailObj.emailNotification}
                handler={emailObj.handler}
                triggerEmail={emailObj.triggerEmail}
                cancelBtnText='Cancel'
            />
        );
        emailProps = emailObj;
    }
    return emailContent;
};

export const getEmailProps = (maillers, commonData, page = null) => {
    return {
        emailNotification,
        triggerEmail: updateToken,
        handler: 'compose_email',
        modal: true,
        notification: {
            appType: 'Performance',
            productType: 'XL',
            eventType: 'xl.performance.notification',
            templateLocale: 'EN-US',
            url: commonData.configs && commonData.configs.NOTIFICATIONS_EMAIL_URL,
            apiKey: commonData.configs && commonData.configs.NOTIFICATION_AUTH,
            fromEmailAddress: commonData.configs && commonData.configs.EMAIL_REPLY_ADDRESS,
            instructor: {
                email: commonData.parentAppData && commonData.parentAppData.userEmail,
                name: 'Pearson Education'
            }
        },
        data: {
            title: 'Compose Email',
            description: `Students receive messages individually and cannot see other recipients. Responses will be sent to: ${commonData.parentAppData && commonData.parentAppData.userEmail}`,
            lists: maillers
        },
        page
    };
};

// Public Domain/MIT
export const generateUUID = (performance = null) => {
    let d = new Date().getTime(); // Timestamp
    // Time in microseconds since page-load or 0 if unsupported
    let d2 = (performance && performance.now && (performance.now() * 1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        // random number between 0 and 16
        let r = Math.random() * 16;
        // Use timestamp until depleted
        if (d > 0) {
            // eslint-disable-next-line no-bitwise
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
            // Use microseconds since page-load if supported
        } else {
            // eslint-disable-next-line no-bitwise
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        // eslint-disable-next-line
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
};

const updateTokenState = (correlationId, callback = null) => {
    let cancelTokens = commonHelper.get('cancelTokens');
    if (callback) {
        cancelTokens.push({ [correlationId]: callback });
    } else {
        cancelTokens = cancelTokens.filter(item => !item[correlationId]);
    }
    commonHelper.set('cancelTokens', cancelTokens);
};

export const makeAPICall = async (url, schema, method = 'POST') => {
    const { CancelToken } = axios;
    const correlationId = generateUUID();
    const reqPayLoad = {
        url,
        method,
        headers: {
            'X-Authorization': window.piSession.currentToken(),
            'Content-Type': 'application/json',
            'Correlation-Id': correlationId
        },
        cancelToken: new CancelToken(cancel => updateTokenState(correlationId, cancel))
    };

    if (method === 'PUT' || method === 'POST') {
        reqPayLoad.data = JSON.stringify(schema);
    } else {
        reqPayLoad.url = `${url}?ts=${new Date().getTime()}`;
    }

    const resp = await apiCaller.call(reqPayLoad);
    updateTokenState(correlationId);
    return resp;
};

// eslint-disable-next-line no-useless-escape
export const formatQuotedString = (str) => (_isEmpty(str) ? '' : str.replace(/"/g, '\"\"'));

export const getPerformanceData = (topicsList, settingsData) => {
    const perfData = [];
    const perfValues = {
        aboveAverageCount: 0,
        belowAverageCount: 0,
        averageCount: 0,
        noAttemptsCount: 0
    };
    topicsList.forEach(fd => {
        if (!_isNumber(fd.knowledgeScoreTopic)) {
            perfValues.noAttemptsCount++;
        } else {
            const scoreVal = _round(fd.knowledgeScoreTopic, 1);
            const rangeLvl = getAverageRange(scoreVal, settingsData);
            if (rangeLvl) {
                perfValues[`${rangeLvl}Count`]++;
            }
        }
    });

    Object.keys(PERF_GROUP).forEach((key) => {
        const perfObj = PERF_GROUP[key];
        perfData.push({
            id: perfObj.id,
            name: perfObj.title,
            value: perfValues[`${perfObj.id}Count`],
            type: perfObj.variant
        });
    });

    return { perfData };
};

export const getAverageRange = (value, settingsData) => {
    let rangeValue = null;
    const { AboveAverage, Average, BelowAverage } = settingsData;
    if ((value >= (BelowAverage.minValue))
        && (value < BelowAverage.maxValue + 0.5)) {
        rangeValue = 'belowAverage';
    } else if ((value >= (Average.minValue - 0.5))
        && (value < Average.maxValue + 0.5)) {
        rangeValue = 'average';
    } else if ((value >= (AboveAverage.minValue - 0.5))
        && (value <= AboveAverage.maxValue)) {
        rangeValue = 'aboveAverage';
    }
    return rangeValue;
};

export const getDistributionThresholds = (commonData) => {
    const {
        thresholdSettingsData: { AboveAverage, Average, BelowAverage }
    } = commonData;
    const distributionThresholds = [
        {
            upperLimit: { value: BelowAverage.maxValue + 0.5, inclusive: false },
            lowerLimit: { value: BelowAverage.minValue, inclusive: true },
            name: 'Below Average'
        },
        {
            upperLimit: { value: Average.maxValue + 0.5, inclusive: false },
            lowerLimit: { value: Average.minValue - 0.5, inclusive: true },
            name: 'Average'
        },
        {
            upperLimit: { value: AboveAverage.maxValue, inclusive: true },
            lowerLimit: { value: AboveAverage.minValue - 0.5, inclusive: true },
            name: 'Above Average'
        },
        {
            upperLimit: { value: null, inclusive: true },
            lowerLimit: { value: null, inclusive: true },
            name: 'No Attempts'
        }
    ];
    return distributionThresholds;
};

export const getFilterKnowledgeLevel = (knwItems, thresholdSettingsData) => {
    const { AboveAverage, Average, BelowAverage } = thresholdSettingsData;
    return (knwItems || []).map((ki) => {
        const retObj = {
            upperLimit: { value: null, inclusive: true },
            lowerLimit: { value: null, inclusive: true },
            name: 'No Attempts'
        };
        if (ki.id === 'aboveAverage') {
            retObj.upperLimit.value = AboveAverage.maxValue;
            retObj.lowerLimit.value = AboveAverage.minValue - 0.5;
            retObj.name = 'Above Average';
        } else if (ki.id === 'average') {
            retObj.upperLimit.value = Average.maxValue + 0.5;
            retObj.upperLimit.inclusive = false;
            retObj.lowerLimit.value = Average.minValue - 0.5;
            retObj.name = 'Average';
        } else if (ki.id === 'belowAverage') {
            retObj.upperLimit.value = BelowAverage.maxValue + 0.5;
            retObj.upperLimit.inclusive = false;
            retObj.lowerLimit.value = BelowAverage.minValue;
            retObj.name = 'Below Average';
        }

        return retObj;
    });
};

export const getStyledKnowledgeScore = (knowledgeScore, thresholdSettingsData) => {
    const knwCls = ['styled-knowledge-score'];
    let displayValue = '--';
    if (_isNumber(knowledgeScore)) {
        displayValue = _round(knowledgeScore);
        const rangeLvl = getAverageRange(displayValue, thresholdSettingsData);
        if (rangeLvl === 'belowAverage') {
            knwCls.push('below-average');
        } else if (rangeLvl === 'average') {
            knwCls.push('average');
        } else if (rangeLvl === 'aboveAverage') {
            knwCls.push('above-average');
        }
    }
    return <span className={knwCls.join(' ')}>{displayValue}</span>;
};

export const getParentGroupFilter = (item, filterOptions) => {
    if (!item) {
        return null;
    }

    let group = null;
    let sType = item.type;
    if (['CHAPTER', 'SECTION', 'TOPIC'].includes(sType)) {
        sType = item.type.toLowerCase();
    }
    if (['studentTags', 'knowledgeScore', 'chapter'].includes(sType)) {
        group = filterOptions.find(fo => fo.value === sType);
    } else {
        const chapterGrp = filterOptions.find(fo => fo.value === 'chapter');
        if (sType === 'section') {
            group = chapterGrp.children.find(chapter => {
                return !!chapter.sections.find(cs => cs.id === item.id);
            });
        } else if (sType === 'topic') {
            for (let i = 0; i < _size(chapterGrp.children); i++) {
                group = chapterGrp.children[i].sections.find(cs => {
                    return !!cs.topics.find(ct => ct.id === item.id);
                });
                if (group) {
                    break;
                }
            }
        }
    }

    return group;
};

export const setSelectedFilter = (key, value) => {
    const defaultSelectedFilters = {
        topicsFilter: [],
        studentListFilter: []
    };
    let selectedFilters = sessionStorage.getItem('selectedFilters');
    selectedFilters = !_isEmpty(selectedFilters)
        ? JSON.parse(selectedFilters)
        : defaultSelectedFilters;
    selectedFilters[key] = value;
    sessionStorage.setItem('selectedFilters', JSON.stringify(selectedFilters));
};

export const getSelectedFilterInfo = (filterArr, filterOpts) => {
    let flatArr = [];
    filterOpts.forEach(fo => {
        if (['knowledgeScore', 'studentTags'].includes(fo.value)) {
            if (fo.children) {
                flatArr = [...flatArr, ...fo.children];
            }
        } else {
            fo.children.forEach(chapter => {
                flatArr.push(_pick(chapter, ['id', 'title', 'type']));
                chapter.sections.forEach(section => {
                    flatArr.push(_pick(section, ['id', 'title', 'type']));
                    section.topics.forEach(topic => {
                        flatArr.push(_pick(topic, ['id', 'title', 'type']));
                    });
                });
            });
        }
    });
    return _compact(filterArr.map(fa => flatArr.find(obj => obj.id === fa)));
};

export const renderErrorComp = () => {
    return (
        <div className='ca-error-message'>
            <div className='ca-error-info-icon'>!</div>
            <div className='ca-error-content'>Failed to load.</div>
        </div>
    );
};

export const getPageCount = (pagination) => {
    let pageCount = 0;
    if (!_isEmpty(pagination)) {
        pageCount = Math.ceil(pagination.total / pagination.limit);
    }

    return pageCount;
};

export const GAprops = {
    topicPage: 'Topics',
    studentPage: 'Student list',
    initialLoad: 'initialLoad',
    onSearch: 'onSearch',
    onSearchClear: 'onSearchClear',
    onStudentBarChartFilter: 'onStudentBarChartFilter',
    studentDetailsPage: 'Student detailview'
};

export const renderKnowledgeScoreInfoContent = () => {
    return (
        <span className='infoContent'>
            <b>What is Knowledge Score? </b>
            Using factors like
            correct on first try and correct attempt rate at the
            question level, we are calculating a knowledge
            score to show you how your students are
            understanding the topics.
        </span>
    );
};

export const renderStudentKnowledgeInfoContent = () => {
    return (
        <span className='infoContent'>
            Student Knowledge displays the distribution of
            students’ understanding across a topic.
            Hover over each graph to display the percentage of
            students that fall within below average,
            average, or above average performance for the
            selected course.
        </span>
    );
};

export const sendGTM = (gtmEvents) => {
    try {
        const obj = {
            event: 'customEvent',
            userId: window.piSession.userId(),
            ...gtmEvents
        };
        window.dataLayer.push(obj);
    } catch (err) {
        // No content
    }
};

export const formatCourseStudentId = (config, csId, type) => {
    return `urn:${config.LPID}:${config.LPSID}:${type}/${csId}`.toLowerCase();
};

export const getPiiErrorMsg = (count) => {
    return `Unable to get data for ${count} ${count === 1 ? 'student' : 'students'}`;
};

const utils = {
    apiCaller,
    getUrlParameter,
    secondsToHms,
    isNotNull,
    isNotUndefined,
    isNotNullAndUndefined,
    getIsDisplay,
    generateUUID,
    makeAPICall,
    getEmailProps,
    renderEmailComponent,
    formatQuotedString,
    getPerformanceData,
    getStyledKnowledgeScore,
    getParentGroupFilter,
    setSelectedFilter,
    getAverageRange,
    getSelectedFilterInfo,
    getPageCount,
    renderErrorComp,
    GAprops,
    sendGTM,
    getDistributionThresholds,
    getFilterKnowledgeLevel,
    renderKnowledgeScoreInfoContent,
    renderStudentKnowledgeInfoContent,
    formatCourseStudentId,
    getPiiErrorMsg
};

export default utils;
