import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import _isNumber from 'lodash/isNumber';
import AverageMarker from './AverageMarker';
import Axis from './Axis';
import Bars from './Bars';
import FailedMessage from './FailedMessage';
import Label from './Label';

const ScoreHistogram = (props) => {
    const [state, setState] = useState({
        svgDimensions: null,
        scales: null,
        xAxisProps: null,
        yAxisProps: null,
        xAxisLabelProps: null,
        yAxisLabelProps: null,
        histogramBins: null,
        distributionDataSrted: null,
        densityMultiplier: null
    });

    useEffect(() => {
        calculateChildProps();
    }, [props.data]);

    const calculateChildProps = () => {
        const { data } = props;
        const svgDimensions = { width: props.width, height: props.height };
        const distributionData = [];
        let isDataAvailable = false;
        // istanbul ignore else
        if (data.gradeDistributionData) {
            let gradeStudentCountPct = null;
            data.gradeDistributionData.forEach((d) => {
                for (let i = 0; i < d.gradeStudentCount; i++) {
                    if (data.hasMorePercent) {
                        gradeStudentCountPct = (d.gradeStudentCountPct >= 100)
                            ? 100 : d.gradeStudentCountPct;
                    } else {
                        gradeStudentCountPct = d.gradeStudentCountPct;
                    }
                    // istanbul ignore else
                    if (_isNumber(gradeStudentCountPct)) {
                        distributionData.push(gradeStudentCountPct);
                    }
                }
            });

            isDataAvailable = data.gradeDistributionData && data.gradeDistributionData.length;
        }

        const distributionDataSrted = [...distributionData].sort(
            (a, b) => parseInt(a, 10) - parseInt(b, 10)
        );
        const margins = data.chartMargins
            ? data.chartMargins : { top: 30, right: 50, bottom: 80, left: 80 };
        const xMaxvalue = 100;

        // x-axis scale config
        const xScale = d3.scaleLinear()
            .domain([-5, xMaxvalue])
            .rangeRound([margins.left, svgDimensions.width - margins.right]);

        const histogramBins = d3.histogram()
            .domain(xScale.domain())
            .thresholds(props.threshold)(distributionDataSrted);

        // Setting Y-Axis values to next even number of maximum available student count in data
        let yMaxValue = d3.max(histogramBins, d => d.length);
        yMaxValue += (yMaxValue % 2 === 0) ? 2 : 1;

        // Values for y-axis
        const yTickValues = [0]; // 0 is the default value for an axis
        const yStep = data.hGridCount ? data.hGridCount : 4;
        if (yStep > 3) {
            const stepMutiplier = yMaxValue / (yStep - 1);
            [...Array(yStep - 1)].forEach((x, i) => {
                yTickValues.push(stepMutiplier * (i + 1));
            });
        } else {
            yTickValues.push(Math.round(yMaxValue / 2));
            yTickValues.push(yMaxValue);
        }

        // y-axis scale config
        const yScale = d3.scaleLinear()
            .domain([0, yMaxValue])
            .range([svgDimensions.height - margins.bottom, margins.top]);
        const obj = {
            svgDimensions: {
                width: props.width,
                height: props.height
            },
            scales: {
                xScale,
                yScale
            },
            xAxisProps: {
                orient: 'Bottom',
                scale: xScale,
                hasMorePercent: data.hasMorePercent,
                translate: `translate(0, ${svgDimensions.height - margins.bottom})`,
                tickPadding: (data.tickStyle && data.tickStyle.padding && data.tickStyle.padding.x)
                    ? data.tickStyle.padding.x : 15,
                tickSuffix: (data.tickStyle && data.tickStyle.suffix && data.tickStyle.suffix.x)
                    ? data.tickStyle.suffix.x : '%'
            },
            yAxisProps: {
                orient: 'Left',
                scale: yScale,
                translate: `translate(${margins.left}, 0)`,
                tickValues: yTickValues,
                tickSize: svgDimensions.width - margins.left - margins.right,
                tickPadding: (data.tickStyle && data.tickStyle.padding && data.tickStyle.padding.y)
                    ? data.tickStyle.padding.y : 30,
                hideValues: !isDataAvailable || data.dataLoadFailure
            },
            xAxisLabelProps: {
                text: data.xy && data.xy.xText,
                elementProps: {
                    className: 'x-axis-label',
                    transform: `translate(${svgDimensions.width / 2}, ${svgDimensions.height - margins.top + 10})`
                },
                noAttemptInfo: {
                    text: (
                        <>
                            <tspan x='20' dy='0'>No</tspan>
                            <tspan x='0' dy='15'>attempts</tspan>
                        </>
                    ),
                    show: true,
                    otherProps: {
                        className: 'noattp-info',
                        transform: `translate(60, ${svgDimensions.height - margins.top - 30})`
                    }
                }
            },
            yAxisLabelProps: {
                text: data.xy && data.xy.yText,
                elementProps: {
                    className: 'y-axis-label',
                    transform: 'rotate(-90)',
                    x: (0 - (svgDimensions.height - margins.bottom + margins.top) / 2),
                    y: 10,
                    dy: '1em'
                }
            },
            noDataLabelProps: {
                text: 'There is no data to display',
                elementProps: {
                    className: 'no-data-label',
                    transform: `translate(${svgDimensions.width / 2}, ${(svgDimensions.height - margins.top - 10) / 2})`
                }
            },
            failedLabelProps: {
                className: 'failed-label',
                text: 'Failed to load',
                chartWidth: svgDimensions.width,
                chartHeight: svgDimensions.height,
                chartMargins: margins,
                elementProps: {
                    transform: `translate(${svgDimensions.width / 2}, ${(svgDimensions.height - margins.top) / 2 + 20})`
                }
            },
            avgMarkerProps: {
                data,
                margins,
                chartHeight: svgDimensions.height
            },
            histogramBins
        };
        setState(obj);
    };

    return (
        <div className='o-score-histogram-chart'>
            {state.svgDimensions
                && (
                    <svg width={state.svgDimensions.width} height={state.svgDimensions.height}>
                        <g>
                            <Axis {...state.xAxisProps} />
                            <Axis {...state.yAxisProps} />
                            {!props.data.dataLoadFailure
                            && props.data.averageMarker
                            && props.data.averageMarker.show
                            && props.data.aggregateAvgGradePct
                            && <AverageMarker {...state.avgMarkerProps} scales={state.scales} />}
                            {!props.data.dataLoadFailure
                            && (props.data.gradeDistributionData
                            && props.data.gradeDistributionData.length
                                ? (
                                    <Bars
                                        bins={state.histogramBins}
                                        scales={state.scales}
                                        hasMorePercent={props.data.hasMorePercent}
                                        onBarSelectDeselct={props.data.onBarSelectDeselct}
                                        refreshChart={props.data.refreshChart}
                                        toolTipLabel={props.data.toolTipLabel}
                                    />
                                )
                                : <Label {...state.noDataLabelProps} />
                            )}
                            {props.data.dataLoadFailure
                            && <FailedMessage {...state.failedLabelProps} />}
                            <Label {...state.xAxisLabelProps} />
                            <Label {...state.yAxisLabelProps} />
                        </g>
                    </svg>
                )}
        </div>
    );
};

ScoreHistogram.propTypes = {
    data: PropTypes.object,
    height: PropTypes.number,
    width: PropTypes.number,
    threshold: PropTypes.number,
    hasMorePercent: PropTypes.bool
};

ScoreHistogram.defaultProps = {
    data: {},
    height: 0,
    width: 0,
    threshold: 0,
    hasMorePercent: false
};

export default ScoreHistogram;
