import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar, OverlayTrigger, Tooltip } from 'react-bootstrap';

const CustomProgressbar = (props) => {
    const { hasTooltip, tooltipContent, progressInfo } = props;
    if (!progressInfo) {
        return null;
    }

    if (hasTooltip) {
        const tooltipComp = (
            <Tooltip id={progressInfo.variant} className='progressbar-tooltip'>
                {tooltipContent}
            </Tooltip>
        );

        return (
            <div className='progress'>
                <OverlayTrigger
                    placement='top'
                    trigger={['hover', 'focus']}
                    overlay={tooltipComp}
                >
                    <ProgressBar {...progressInfo} isChild />
                </OverlayTrigger>
            </div>
        );
    }

    return (
        <ProgressBar {...progressInfo} />
    );
};

CustomProgressbar.propTypes = {
    progressInfo: PropTypes.object,
    hasTooltip: PropTypes.bool,
    tooltipContent: PropTypes.node
};

CustomProgressbar.defaultProps = {
    progressInfo: null,
    hasTooltip: false,
    tooltipContent: null
};

export default CustomProgressbar;
